import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import DeleteIcon from "@mui/icons-material/Delete";
import "react-quill/dist/quill.snow.css";
import CancelIcon from '@mui/icons-material/Cancel';
import "./MetaTags.css";

const MetaTags = () => {
  const [title, setTitle] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [metaKeywards, setmetaKeywards] = useState("");
  const [previewMetaKeywards, setPreviewMetaKeywards] = useState("");

  const [metaTitle, setmetaTitle] = useState("");
  const [previewMetaTitle, setPreviewMetaTitle] = useState("");
  const [image, setImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  


  const [previewData, setpreviewData] = useState(false);
  console.log("previewData",previewData);
  const [description, setDescription] = useState("");
  const [previedDescription, setPreviewDescription] = useState("");
  console.log("previedDescription",previedDescription);
  const reactQuillRef = React.useRef(null);
  const reactQuillRef2 = React.useRef(null);
  const [ogDescription, setOgDescription] = useState("");
  // Function to handle changes in the "Title" input field


  const removeImage = ()=>{
    setPreviewImage(null);

  }
  
  const deletePreviewData = ()=>{
    setpreviewData(false)
    setPreviewImage(null);
    setPreviewTitle("");
    setPreviewMetaKeywards("");
    setPreviewMetaTitle("");
    setPreviewDescription("") 
  }
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
    setPreviewTitle(event.target.value)
    setpreviewData(true)
  };
  const handleMetaTitle = (event) => {
    setmetaTitle(event.target.value);
    setPreviewMetaTitle(event.target.value);
    setpreviewData(true)
  };

  const hendelMetaKeywards = (event) => {
    setmetaKeywards(event.target.value);
    setPreviewMetaKeywards(event.target.value)
    setpreviewData(true)
  };
  const containsSpaces = metaKeywards.includes(" ");

  // Function to handle changes in the "Description" rich text editor
  const handleDescriptionChange = (content , delta, source, editor) => {
    console.log("hhhhhhhhh: ", content)
    const cleanContent = stripTags(content);
    setDescription(cleanContent);
    setPreviewDescription(cleanContent)
    // setpreviewData(true)

  };

  console.log("Des: ",description, previedDescription)
  const stripTags = (html)=>{
    const doc = new DOMParser().parseFromString(html,"text/html");
    return doc.body.textContent || " ";
  };
  const handleOgDescriptionChange = (content) => {
    setOgDescription(content);
    setpreviewData(true)
  };
  // Create a reference to the ReactQuill component
  // const reactQuillRef = useRef();

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    // You can perform validation on the selectedImage if needed
    setImage(URL.createObjectURL(selectedImage));
    setPreviewImage(URL.createObjectURL(selectedImage));
  };
const clearSelectedImg=()=>{
  setImage(null)
}


  return (
    <div style={{ backgroundColor: "#F1F2F4", padding: "20px" }}>
      <div
        style={{
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          borderRadius: "10px",
        }}
      >
        <label
          style={{
            padding: "20px 0px",
            fontSize: "17px",
            fontWeight: "700",
            paddingBottom: "10px",
          }}
          htmlFor="title"
        >
          Meta Title
        </label>
        <input
          style={{
            padding: "20px 15px",
            borderRadius: "10px",
            border: "1px solid #898F94",
            fontSize: "14px",
            fontWeight: "400",
          }}
          type="text"
          id="title"
          value={metaTitle}
          onChange={handleMetaTitle}
          placeholder="Enter meta title here"
        />

        <label
          style={{
            padding: "20px 0px",
            fontSize: "17px",
            fontWeight: "700",
            paddingBottom: "10px",
          }}
          htmlFor="title"
        >
          Meta Keywards
        </label>
        <input
          style={{
            padding: "20px 15px",
            borderRadius: "10px",
            border: "1px solid #898F94",
            fontSize: "14px",
            fontWeight: "400",
          }}
          type="text"
          id="title"
          value={metaKeywards}
          onChange={hendelMetaKeywards}
          placeholder="Enter meta keywards here"
        />
        {containsSpaces && (
          <span
            style={{ fontSize: "14px", fontWeight: "400", paddingLeft: "5px" }}
          >
            {" "}
            Use (,) to use multiple keywards
          </span>
        )}

        <br />
        <label
          style={{
            fontSize: "17px",
            fontWeight: "700",
          }}
          htmlFor="description"
        >
          Description:
        </label>
        <ReactQuill
          ref={reactQuillRef}
          value={description}
          onChange={handleDescriptionChange}
          // modules={quillModules}

          className="rich-text-editor-container"
          placeholder="Enter description here"
        />

        <label
          style={{
            padding: "20px 0px",
            fontSize: "17px",
            fontWeight: "700",
            paddingBottom: "10px",
          }}
          htmlFor="title"
        >
          Og Title
        </label>
        <input
          style={{
            padding: "20px 15px",
            borderRadius: "10px",
            border: "1px solid #898F94",
            fontSize: "14px",
            fontWeight: "400",
          }}
          type="text"
          id="title"
          value={title}
          onChange={handleTitleChange}
          placeholder="Enter og title here"
        />

        <label
          style={{
            fontSize: "17px",
            fontWeight: "700",
            marginTop: "20px",
          }}
          htmlFor="description"
        >
          Og Description:
        </label>
        <ReactQuill
          ref={reactQuillRef2}
          value={ogDescription}
          onChange={handleOgDescriptionChange}
          // modules={quillModules}

          className="rich-text-editor-container"
          placeholder="Enter og description here"
        />

        <label
          style={{
            padding: "20px 0px",
            fontSize: "17px",
            fontWeight: "700",
            paddingBottom: "10px",
          }}
          htmlFor="title"
        >
          Og Image
        </label>
        {image ? (
          <div
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
             className="image-container"
              style={{
                border: "1px solid black",
                cursor: "pointer",
                width: "230px",
                height: "230px",
              }}
              // onMouseEnter={handleMouseEnter}
              // onMouseLeave={handleMouseLeave}
            >
              <img
                src={image}
                alt="Uploaded Image"
                style={{
                  width: "200px",
                  height: "200px",
                  cursor: "pointer",
                  position: "relative",
                  objectFit:'contain'
                }}
                onClick={() => {
                  // When clicked, trigger the file input to select an image
                  document.getElementById("imageInput").click();
                }}
              />
              <DeleteIcon onClick={clearSelectedImg} className="dltIcon" />
            </div>
          </div>
        ) : (
          <div
            onClick={() => {
              // When clicked, trigger the file input to select an image
              document.getElementById("imageInput").click();
            }}
            style={{
              border: "1px dashed black",
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            <img
              style={{ height: "170px", width: "200px", margin: "18px" }}
              src="/assets/images/upoadLogo.png"
              alt="Close"
            />
          </div>
        )}

        <input
          type="file"
          id="imageInput"
          style={{ display: "none" }}
          onChange={handleImageChange}
        />
      </div>





     {previewData &&  <div style={{margin:"20px 0px",background:'#fff',padding:'30px',borderRadius:"15px",textAlign:"center"}}>

<div   className="image-container-preview" style={{border:'10px solid balck',width:'400px',height:'300px', position:'relative'}}>

{previewImage? (
     <img
     src={previewImage}
     alt="Uploaded Image"
     style={{
       width: "100%",
       height: "100%",
       cursor: "pointer",
       position: "relative",
       objectFit:'contain',
     
     }}
   
   />

  ):(
    <img
    src="/assets/images/upoadLogo.png"
    alt="Uploaded Image"
    style={{
      width: "300px",
      height: "300px",
      cursor: "pointer",
      position: "relative",
      objectFit:'contain',
     
    }}
  
  />
  )}

  <CancelIcon className="dltIconPreview"  onClick={removeImage} style={{position:'absolute',margin:'auto',top:'10px',right:'10px',cursor:'pointer'}}/>
  <DeleteIcon className="dltIconPreview"  onClick={deletePreviewData} style={{position:'absolute',margin:'auto',top:'10px',left:'10px',cursor:'pointer'}}/>
</div>
  


 
  
  <div style={{display:'flex',justifyContent:"start",alignItems:"center",marginTop:"15px"}}>
  <div>
    {previewMetaTitle?(
      <p className="title" >
        Meta Title: <span className="value">{previewMetaTitle}</span>
      </p>
    ):(
      <p className="title">
        Meta Title : <span className="value">N/A</span>
      </p>
    )}
    </div>


    <div>
    {previewMetaKeywards?(
      <p className="title">
        Meta Keywards : <span className="value">{previewMetaKeywards}</span>
      </p>
    ):(
      <p className="title">
      Meta Keywards: <span className="value">N/A</span>
      </p>
    )}
    </div>
   
    <div>
    {previewTitle?(
      <p className="title" >
        Og Title : <span className="value">{previewTitle}</span>
      </p>
    ):(
      <p className="title">
        Og Title : <span className="value">N/A</span>
      </p>
    )}
    </div>
    
    
    
  



  </div> 
 


<div style={{display:"block",alignItems:"start",textAlign:'start'}}>
  <div> {
   previedDescription ?(<p className="title">
    Description: <span className="value">{previedDescription} </span>
    </p>):(
      <p className="title">
      Description: <span className="value">N/A</span>
      </p>
    )} </div>
    
 
</div>
  
</div>} 

      
    </div>
  );
};

export default MetaTags;
