const products = [{
    image:"/assets/images/al.jpg",
    name:"Almira",
    quantity:"2",
    price:"20000",
    sku:"GDN-001prod",
    id:"1"
},
{
    image:"/assets/images/sof1.jpg",
    name:"Table",
    quantity:"4",
    price:"10000",
    sku:"GDN-002prod",
    id:"2"
},
{
    image:"/assets/images/sof.jpg",
    name:"Chair",
    quantity:"1",
    price:"5000",
    sku:"GDN-003prod",
    id:"3"
},{
    image:"/assets/images/tv.jpg",
    name:"TV Table",
    quantity:"0",
    price:"20000",
    sku:"GDN-004prod",
    id:"4"
},{
    image:"/assets/images/al.jpg",
    name:"Mobile",
    quantity:"8",
    price:"50000",
    sku:"GDN-005prod",
    id:"5"
},
{
    image:"/assets/images/al.jpg",
    name:"Book",
    quantity:"0",
    price:"200",
    sku:"GDN-006prod",
    id:"6"
},{
    image:"/assets/images/al.jpg",
    name:"Pen",
    quantity:"10",
    price:"20",
    sku:"GDN-007prod",
    id:"7"
},{
    image:"/assets/images/al.jpg",
    name:"Key board",
    quantity:"3",
    price:"500",
    sku:"GDN-008prod",
    id:"8"
},{
    image:"/assets/images/al.jpg",
    name:"Mouse",
    quantity:"2",
    price:"100",
    sku:"GDN-009prod",
    id:"9"
},{
    image:"/assets/images/al.jpg",
    name:"Laptop",
    quantity:"4",
    price:"100000",
    sku:"GDN-0010prod"
    ,id:"10"
},{
    image:"/assets/images/al.jpg",
    name:"Bed",
    quantity:"4",
    price:"200000",
    sku:"GDN-0011prod",
    id:"11"
},{
    image:"/assets/images/al.jpg",
    name:"Coffee Table",
    quantity:"1",
    price:"13000",
    sku:"GDN-0012prod",
    id:"12"
},{
    image:"/assets/images/al.jpg",
    name:"Bookshelf",
    quantity:"4",
    price:"7000",
    sku:"GDN-0013prod",
    id:"13"
},{
    image:"/assets/images/al.jpg",
    name:"Wardrobe",
    quantity:"9",
    price:"40000",
    sku:"GDN-0014prod",
    id:"14"
},{
    image:"/assets/images/al.jpg",
    name:"Dresser",
    quantity:"5",
    price:"10000",
    sku:"GDN-0015prod",
    id:"15"
},{
    image:"/assets/images/al.jpg",
    name:"Nightstand",
    quantity:"3",
    price:"1000",
    sku:"GDN-0016prod",
    id:"16"
},{
    image:"/assets/images/al.jpg",
    name:"Sectional Sofa",
    quantity:"0",
    price:"40000",
    sku:"GDN-0017prod",
    id:"17"
},{
    image:"/assets/images/al.jpg",
    name:"Console Table",
    quantity:"3",
    price:"1000",
    sku:"GDN-0017prod",
    id:"18"
},{
    image:"/assets/images/al.jpg",
    name:"Chaise Lounge",
    quantity:"0",
    price:"4000",
    sku:"GDN-0018prod",
    id:"19"
},{
    image:"/assets/images/al.jpg",
    name:"Bar Stool",
    quantity:"0",
    price:"20000",
    sku:"GDN-0019prod",
    id:"20"
},{
    image:"/assets/images/al.jpg",
    name:"TV Stand",
    quantity:"e",
    price:"2000",
    sku:"GDN-0019prod",
    id:"21"
},{
    image:"/assets/images/al.jpg",
    name:"Almira",
    quantity:"0",
    price:"20000",
    sku:"GDN-0020prod",
    id:"22"
}]

export default products;