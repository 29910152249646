

import React from "react";
import { AiOutlineSearch as SearchIcon } from 'react-icons/ai';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import { Menu } from "@mui/material";
 import './filterDesign.css';
import { useState, useEffect } from "react";
export default function PraymentStatusFilter({anchor,setAnchor,vendor,setSelectedVendorFromUser,setVendorSearch, selectedVendorFromUser}) {
  // console.log(vendor);

  const [checked, setChecked] = React.useState(new Array(vendor.length).fill(false));
  // console.log("checked",checked);




  // useEffect(()=>{
  //   setSelectedVendorFromUser(selectedVendor)
 
  // },[selectedVendor])

  useEffect(()=>{
    if(selectedVendorFromUser.length <= 0) {
      setChecked(new Array(vendor.length).fill(false))
    }
  },[selectedVendorFromUser,vendor])

  
  const open = Boolean(anchor);


  

  
  const handleChange1 = (event) => {
    // let arr = new Array(vendor.length).fill(true)
    const isChecked = event?.target?.checked;
    // if(isChecked) {
    //   let arr = new Array(vendor.length).fill(false)
    //   setChecked(arr)
    // }
    const updatedChecked = vendor.map(() => isChecked);
     setChecked(updatedChecked)
    // console.log("updatedChecked",updatedChecked);
    setSelectedVendorFromUser(isChecked ? vendor : []);
    setAnchor(null);
  };
  

 


  const handleChange = (event, index) => {
    const isChecked = event.target.checked;
    const updatedChecked = [...checked];
    updatedChecked[index] = isChecked;
    setChecked(updatedChecked);
  
    const selectedVendorIndex = selectedVendorFromUser.indexOf(vendor[index ]);
  
    if (isChecked && selectedVendorIndex === -1) {
      setSelectedVendorFromUser([...selectedVendorFromUser, vendor[index]]);
    } else if (!isChecked && selectedVendorIndex !== -1) {
      const updatedSelectedVendor = selectedVendorFromUser.filter((item) => item !== vendor[index]);
      setSelectedVendorFromUser(updatedSelectedVendor);
    }
  
    setAnchor(null);
  };

  const handleClose = () => {
    setAnchor(null);
    let arr = new Array(vendor.length).fill(false)
    setChecked(arr);
    setSelectedVendorFromUser([]);
  
    setVendorSearch();
  };


 
  const children = vendor.map((vendor, index) => (
    <div style={{display:'grid',gridTemplateColumns:"10% 90%",columnGap:'20px',alignItems:'center',justifyContent:'start'}}>
 <FormControlLabel
      key={index}
      className={'checkboxItem'}
      // label={vendor}
      control={
        <Checkbox
          checked={checked[index]}
          onChange={(event) => handleChange(event, index)}
        />
      }
    />
<p style={{textAlign:"start",margin:'0px',fontSize:'14px',fontWeight:'400'}}>{vendor}</p>
    </div>
   
  ));

  const handleInputChange = (event) => {

  
    setVendorSearch (event.target.value.toLowerCase());}

 

  return (
    <Menu
    id="lock-menu"
    anchorEl={anchor}
    open={open}
    PaperProps={{
      style: {
        borderRadius:"20px", boxShadow:'5px 10px 8px #888888',
        marginTop: '36px', // Adjust the value as needed
      },
    }}
    anchorPosition={{ top: 50, left: 10 }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}

   
  >

    {/* sraech */}
    
   
    <div style={{  border: '1px solid #f0f0f0'}}>
       <div style={{padding:'7px ',borderRadius: '6px',display:'flex',margin:'15px',border:'1px solid #d9d9d9',justifyContent:'start',alignItems:'center',columnGap:'0px'}}>
      <SearchIcon style={{color:"rgba(0, 0, 0, 0.25)" ,margin: ' 0px 10px',alignItems:"center",fontSize:'20px'}} />
       <input  onChange={handleInputChange} className={'custom-input-diolog'} style={{border:'none',fontSize:'16px',outline:'none',width:"160px"}} type='text'placeholder='Search in filters'></input>
       </div>

      </div>



<div  style={{width:'223px',height:'300px',marginBottom:"100px"}}>

    <div style={{padding:'10px 20px 10px 30px',alignItems:'center',textAlign:'center'}}>
      <FormControlLabel style={{textAlign:'start',width:"170px"}}
        label="Select all"
        control={
          <Checkbox

            checked={checked.every((value) => value)}
            indeterminate={!checked.every((value) => value) && checked.some((value) => value)}
            onChange={handleChange1}
          />
        }
      />
      {children}
     

    </div>
  
  
   
     
    <Button  onClick={handleClose} style={{backgroundColor:'red',color:'white',borer:'none',padding:'2px',marginBottom:'14px',marginLeft:'49px'}}>Clear</Button>

     
    
     

   
  </div>
  </Menu>

  
  );
}
