import React, { useState, useRef } from "react";
import ReactQuill from "react-quill";
import IconButton from "@mui/material/IconButton";
import "react-quill/dist/quill.snow.css";

import "./AddProduct.css";
import DeleteIcon from '@mui/icons-material/Delete';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FilledInput from "@mui/material/FilledInput";
import InputAdornment from "@mui/material/InputAdornment";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";

import { Dialog, DialogTitle, DialogContent, Button } from "@mui/material";
import InventoryLocation from "./InventoryLocation";
import FileAndImageUpload from "./fileOrImageUpload";

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
  position:"relative"
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};
const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
  marginLeft:36
  
};








const CustomFilledInput = styled(FilledInput)({
  "&.MuiFilledInput-root": {
    backgroundColor: "transparent",
    border: "1px solid #898F94",
    borderRadius: "10px",
  },
  "&.MuiFilledInput-underline:before, &.MuiFilledInput-underline:after": {
    border: "none", // Remove the underline border on hover/focus
  },
  "& .MuiInputBase-root.Mui-focused": {
    border: "none",
  },
});

const useStyles = styled((theme) => ({
  focused: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
  },
}));

const RichTextEditor = ({ onFileSelect ,quantity ,setQuantity,Sku,setSku }) => {
  const [title, setTitle] = useState("");

  const [description, setDescription] = useState("");
  const [uploadProduct, setUploadProduct] = useState([]);


  const deletSpecifickImg =(index )=>{
    const updateUploadProd=[...uploadProduct];
  updateUploadProd.splice(index,1);
   
    setUploadProduct(updateUploadProd);
  console.log("updateUploadProd",updateUploadProd);
   
  }


  const thumbs = uploadProduct.map((file,i )=> (
  
    <div className="selectImagesArray" style={thumb} key={i}>
      <div style={thumbInner}>
        <img
        alt='dasd'
          src={file?.preview}
          style={img}
          // Revoke data ur"i after image is loaded
          // onLoad={() => { URL.revokeObjectURL(file?.preview) }}
        />
      </div>

      <DeleteIcon onClick={()=>deletSpecifickImg(i)} className="dlt" style={{position:'absolute',margin:'auto',top:"40%",right:'40%'}}/>
    </div>
  ));
  console.log("thumbs",thumbs);


















  
  const [LinkDialogOpen, setLinkDialogOpen] = useState(false);

  const [isChecked, setIsChecked] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [isChecked3, setIsChecked3] = useState(false);

 
 
  // const [AddCustomerInfoButton, setAddCustomerInfoButton] = useState(true);
  // const [customerInfoFild, setCustomerInfoFild] = useState(false);
  const handleCheckboxChange3 = (event) => {
    setIsChecked3(event.target.checked)
    
  };
 

  // Function to handle changes in the "Title" input field
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };
 

  // Function to handle changes in the "Description" rich text editor
  const handleDescriptionChange = (content) => {
    setDescription(content);
  };

  // Custom image handler for uploading images
  const handleImageUpload = (file) => {
    // const formData = new FormData();
    // formData.append('image', file);
    // Replace 'YOUR_IMAGE_UPLOAD_URL' with the actual API endpoint to handle image uploads
    // axios.post('YOUR_IMAGE_UPLOAD_URL', formData).then((response) => {
    //   const imageUrl = response.data.imageUrl;
    //   const quill = reactQuillRef.current.getEditor();
    //   const range = quill.getSelection();
    //   quill.insertEmbed(range.index, 'image', imageUrl);
    // });
  };

  // Configuration options for the Quill editor
  const quillModules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["link", "image", "video"],
      ],
      handlers: {
        image: () => {
          const input = document.createElement("input");
          input.setAttribute("type", "file");
          input.setAttribute("accept", "image/*");
          input.click();
          input.onchange = async () => {
            const file = input.files[0];
            handleImageUpload(file);
          };
        },
        video: () => {
          const url = prompt("Enter the video URL");
          if (url) {
            const quill = reactQuillRef.current.getEditor();
            const range = quill.getSelection();
            quill.insertEmbed(range.index, "video", url, "user");
          }
        },
      },
    },
  };

  // Create a reference to the ReactQuill component
  const reactQuillRef = useRef();

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    onFileSelect(file); // Pass the selected file to the parent component
  };
  const HendelDialog = () => {
    setLinkDialogOpen(true);
  };

  const handleCloseClickDlt = () => {
    // Close the edit dialog without saving
    setLinkDialogOpen(false);
  };
  

  const [state, setState] = React.useState({
    gilad: true,
  });

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };
  const { gilad } = state;
  


  
  const [TrackQuantity, setTrackQuantity] = React.useState({
    trackQun: true,
  });

  const handleTrackQuentityChange = (event) => {
    setTrackQuantity({
      ...TrackQuantity,
      [event.target.name]: event.target.checked,
    });
  };
  const { trackQun } = TrackQuantity;



  const [stateShipping, setStateShipping] = React.useState({
    shipingCharge: true,
  });
 
  const handleChangeShipping = (event) => {
    setStateShipping({
      ...stateShipping,
      [event.target.name]: event.target.checked,
    });
  };
  const { shipingCharge } = stateShipping;

  
  const defaultValue = 0;
  const defaultWeight = 0.0;

  const shopLocation = [
    { label: "Uttora Branch", year: 1994 },
    { label: "Norddha Branch", year: 1972 },
    { label: "Gulshan 1 Branch", year: 1974 },
    { label: "Gulshan 2 Branch", year: 2008 },
    { label: "Rampura Branch", year: 1957 },
  ];
  const Weight = [
    { label: "LB" },
    { label: "KG" },
    { label: "OZ" },
    { label: "G" },
  ];

  const Zila = [{ label: "zila 1" }, { label: "zila 2" }];
  const Thana = [{ label: "thana 1" }, { label: "thana 2" }];
  const District = [{ label: "district 1" }, { label: "district 2" }];
  const classes = useStyles();
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const handleCheckboxChange2 = (event) => {
    setIsChecked2(event.target.checked);
  };





  const handleQuantityChange = (event) => {
    // Update the quantity state whenever the input value changes
    setQuantity(event.target.value);
  };
  


  const handlesetSkuChange = (event) => {
    // Update the quantity state whenever the input value changes
    setSku(event.target.value);
  };
  
  const [Barcode, setBarcode] = useState(); // Initialize quantity state with a default value

  const handlesetBarcodeChange = (event) => {
    // Update the quantity state whenever the input value changes
    setBarcode(event.target.value);
  };
  
  const [DefWeight, setWeight] = useState(); // Initialize quantity state with a default value

  const handlesetWeightChange = (event) => {
    // Update the quantity state whenever the input value changes
    setWeight(event.target.value);
  };
  const [selectedWeight, setSelectedWeight] = useState(null);
 const handleWeightChange=(even,newValue)=>{
  setSelectedWeight(newValue)

  }

    // Logging the selected option when it changes
    React.useEffect(() => {
      if (selectedWeight?.label === "KG") {

      } else {
       
      }
    }, [selectedWeight]);
    
   
  return (
    <div style={{ backgroundColor: "#F1F2F4", padding: "20px" }}>
      <div
        style={{
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          borderRadius: "10px",
        }}
      >
        <label
          style={{
            padding: "20px 0px",
            fontSize: "17px",
            fontWeight: "700",
            paddingBottom: "10px",
          }}
          htmlFor="title"
        >
          Title
        </label>
        <input
          style={{
            padding: "20px 15px",
            borderRadius: "10px",
            border: "1px solid #898F94",
            fontSize:'14px',
            fontWeight:'400'
          }}
          type="text"
          id="title"
          value={title}
          onChange={handleTitleChange}
          placeholder="Enter title here"
        />
        <br />
        <label style={{
         
            fontSize: "17px",
            fontWeight: "700",
         
          }} htmlFor="description">Description:</label>
        <ReactQuill
          ref={reactQuillRef}
          value={description}
          onChange={handleDescriptionChange}
          // modules={quillModules}

          className="rich-text-editor-container"
          placeholder="Enter description here"
        />
      </div>

      <div
        style={{
          padding: "30px 5px",
          backgroundColor: "white",
          borderRadius: "10px",
          margin: "20px 0px",
        }}
      >
        <label
          style={{ fontSize: "17px", fontWeight: "700", paddingLeft: "15px" }}
          htmlFor="title"
        >
          Media
        </label>
        <div
          style={{
            // position: "relative",
            textAlign: "center",
            border: "1px dashed #898F94",
            height: "200px",
            margin: "20px  15px ",
            borderRadius: "10px",
            justifyContent:'center',
            display:'flex'
          }}
        >
          <div style={{display:"flex",flexDirection:'column',marginTop:'69px'
}}>
          <div style={{display:"flex",justifyContent:'center',alignItems:'center',columnGap:'10px'}}>
          <span>
      
    
          < FileAndImageUpload setUploadProduct={setUploadProduct}/>
          </span>
         
          {/* onClick={handleLinkUploadClick} */}
          <button className="linkUpload" onClick={HendelDialog}>
            Add from <span style={{ fontSize: "17px" }}>URl</span>
          </button>
          </div>
          <span className="message">Accepts images, videos, or 3D models</span>

          </div>

         
          
       
          </div>
          <aside style={thumbsContainer}>
        {thumbs}
      </aside>
          
       
      </div>

      <div
        style={{
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          borderRadius: "10px",
        }}
      >
        <label
          style={{
            paddingTop: "20px",
            paddingBottom: "20px",
            fontSize: "17px",
            fontWeight: "700",
          }}
          htmlFor="title"
        >
          Pricing
        </label>

        <label
          style={{
            marginBottom: "10px",
            marginTop: "10px",
            fontSize: "14px",
            fontWeight: "400",
          }}
          htmlFor="title"
        >
          Price
        </label>

        <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
          <CustomFilledInput
            id="filled-adornment-amount"
            startAdornment={
              <InputAdornment position="start" style={{ paddingTop: "0px" }}>
                $
              </InputAdornment>
            }
            placeholder="0.00"
          />
        </FormControl>

        <FormControlLabel
          control={
            <Checkbox checked={gilad} onChange={handleChange} name="gilad" />
          }
          label ="Charge tax on this product"
        />

        <span
          style={{ margin: "0px 0px 10px 0px", border: "1px solid #898F94 " }}
        >
          {" "}
        </span>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "start",
            columnGap: "10px",
          }}
        >
          <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
            <label
              style={{
                marginBottom: "7px",
                marginTop: "7px",
                fontSize: "14px",
                fontWeight: "400",
              }}
              htmlFor="title"
            >
              Cost per item
            </label>
            <CustomFilledInput
              id="filled-adornment-amount"
              startAdornment={
                <InputAdornment position="start" style={{ paddingTop: "0px" }}>
                  $
                </InputAdornment>
              }
              placeholder="0.00"
              type="number" // Set the input type as "number"
              endAdornment={<InputAdornment position="end">?</InputAdornment>}
              label="Password"
            />
          </FormControl>

          <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
            <label
              style={{
                marginBottom: "7px",
                marginTop: "7px",
                fontSize: "14px",
                fontWeight: "400",
              }}
              htmlFor="title"
            >
              Profit
            </label>
            <CustomFilledInput style={{paddingLeft:'10px'}}
              id="filled-adornment-amount"
              // startAdornment={<InputAdornment position="start"style={{paddingTop:'0px'}}>$</InputAdornment>}
              placeholder="-- --"
              disabled // Add the "disabled" prop to set the input as disabled
            />
          </FormControl>

          <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
            <label
              style={{
                marginBottom: "7px",
                marginTop: "7px",
                fontSize: "14px",
                fontWeight: "400",
              }}
              htmlFor="title"
            >
              Margin
            </label>
            <CustomFilledInput style={{paddingLeft:'10px'}}
              id="filled-adornment-amount"
              // startAdornment={<InputAdornment position="start"style={{paddingTop:'0px'}}>--</InputAdornment>}
              placeholder="-- --"
              disabled // Add the "disabled" prop to set the input as disabled
            />
          </FormControl>
        </div>
      </div>

      <div
        style={{
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          borderRadius: "10px",
          margin: "20px 0px",
        }}
      >
        <label
          style={{
            paddingTop: "20px",
            paddingBottom: "20px",
            fontSize: "17px",
            fontWeight: "700",
          }}
          htmlFor="title"
        >
          Inventory
        </label>

        <FormControlLabel
          control={
            <Checkbox checked={trackQun} onChange={handleTrackQuentityChange} name="trackQun" />
          }
          label="Track quantity"
        />

        {/* <label style={{paddingTop:'20px',paddingBottom:'10px',fontSize:"19px",fontWeight:"700",}} htmlFor="title">Quantity and Stor Location</label>
         */}
        <div className="QuantitySectionMain"
        
        >
           <FormControl className="inputQuantity" variant="outlined">
      <label
        style={{
          paddingTop: '17px',
          paddingBottom: '10px',
          fontSize: '17px',
          fontWeight: '500',
        }}
        htmlFor="title"
      >
        Quantity
      </label>
      <CustomFilledInput style={{paddingLeft:"10px"}}
        id="filled-adornment-amount"
        value={quantity} // Set the value prop to the quantity state variable
        onChange={handleQuantityChange} // Handle the change event to update the quantity state
        type="number" // Set the input type as "number"
        placeholder="0"
      />
    </FormControl>
          <div
            // style={{
            //   width: "100%",
            // }}
            className="inventoryLocation"
          >

            <FormControlLabel   control={<Checkbox checked={isChecked3}  onChange={handleCheckboxChange3} />} label="Enable to add inventory location" />

       
          </div>
          
        </div>
        {isChecked3 && 

             <InventoryLocation controlForm={setIsChecked3}/>
          
          }

        <span
          style={{ margin: "27px 0px 10px 0px", border: "1px solid #898F94 " }}
        >
          {" "}
        </span>

        <div style={{marginBottom:'20px'}}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isChecked}
                onChange={handleCheckboxChange}
                name="gilad"
              />
            }
            label={
              <Typography
                style={{ fontSize: "16px", fontWeight: "550" }}
                variant="subtitle1"
              >
                Continue selling when out of stock
              </Typography>
            }
          />

          {!isChecked && (
            <p
              style={{
                height: !isChecked ? "43px" : "10px",
                padding: "0px 30px",
                margin: "0px",
                fontSize: "14px",
                fontWeight:'400',
                lineHeight: "24px",
              }}
            >
              This won't affect{" "}
              <span style={{ color: "#1565C0" }}>GDN POS</span>. Staff will see
              a warning, but can complete sales when <br /> available inventory
              reaches zero and below.
            </p>
          )}
        </div>
        <span
          style={{ margin: "27px 0px 10px 0px", border: "1px solid #898F94 " }}
        >
          {" "}
        </span>

        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={isChecked2}
                onChange={handleCheckboxChange2}
                name="gilad"
              />
            }
            label={
              <Typography
                style={{ fontSize: "14px", fontWeight: "400" }}
                variant="subtitle1"
              >
                This product has a SKU or barcode
              </Typography>
            }
          />

          {isChecked2 && (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "49% 49%",
                columnGap: "20px",
              }}
            >
              <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                <label
                  style={{
                    marginBottom: "10px",
                    marginTop: "10px",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                  htmlFor="title"
                >
                  SKU (Stock Keeping Unit)
                </label>
                <CustomFilledInput style={{paddingLeft:"10px"}}
                  id="filled-adornment-amount"
                  // startAdornment={<InputAdornment position="start"style={{paddingTop:'0px'}}>$</InputAdornment>}
                  placeholder="Enter here product SKU"

                  value={Sku}
                  onChange={handlesetSkuChange}
                />
              </FormControl>

              <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                <label
                  style={{
                    marginBottom: "10px",
                    marginTop: "10px",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                  htmlFor="title"
                >
                  Barcode (ISBN, UPC, GTIN, etc.)
                </label>
                <CustomFilledInput style={{paddingLeft:"10px"}}
                  id="filled-adornment-amount"
                  // startAdornment={<InputAdornment position="start"style={{paddingTop:'0px'}}>--</InputAdornment>}
                  value={Barcode}
                  onChange={handlesetBarcodeChange}
                  placeholder="Enter here product barcode"
                />
              </FormControl>
            </div>
          )}
        </div>
      </div>

      <div
        style={{
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          borderRadius: "10px",
          margin: "20px 0px",
        }}
      >
        <label
          style={{
            paddingTop: "20px",
            paddingBottom: "20px",
            fontSize: "17px",
            fontWeight: "700",
          }}
          htmlFor="title"
        >
          Shipping
        </label>

        <FormControlLabel
          control={
            <Checkbox checked={shipingCharge} onChange={handleChangeShipping} name="shipingCharge" />
          }
          label="This product requires shipping"  style={{
          
            fontSize: "14px",
            fontWeight: "400",
          }}
        />

        {/* <label style={{paddingTop:'20px',paddingBottom:'10px',fontSize:"19px",fontWeight:"700",}} htmlFor="title">Quantity and Stor Location</label>
         */}
        <div
          style={{
           
            display: "grid",
         
            gridTemplateColumns: "42% 28%",
            alignItems: "center",
            columnGap: "10%",
            justifyContent: "start",
          }}
        >
          <FormControl variant="outlined">
            <label
              style={{
                paddingTop: "17px",
                paddingBottom: "10px",
                fontSize: "14px",
                fontWeight: "400",
              }}
              htmlFor="title"
            >
              Weight
            </label>
            <CustomFilledInput style={{paddingLeft:'10px'}}
              id="filled-adornment-amount"
              value={DefWeight} // Set the default value as a number
              onchange={handlesetWeightChange}
              type="number" // Set the input type as "number"
              placeholder={selectedWeight?.label === "KG"?"kilogram":selectedWeight?.label === "LB"?"Pound":selectedWeight?.label === "OZ"?"Ounce":selectedWeight?.label === "G"?"Gram":"0.00"}
            />
          </FormControl>
          <div style={{marginTop:'58px' }}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={Weight}
              renderInput={(params) => <TextField {...params}  label="KG" />}
              onChange={handleWeightChange}
            />
          </div>
        </div>

        {/* <span
          style={{ margin: "27px 0px 10px 0px", border: "1px solid #898F94 " }}
        >
          {" "}
        </span> */}

      
      </div>

{/* vendor */}
   

      <Dialog open={LinkDialogOpen} onClose={handleCloseClickDlt}>
        <DialogTitle
          style={{
            width: "500px",
            fontSize: "20px",
            fontWeight: "600",
            marginBottom: "10px",
            borderBottom: "1px solid #898F94",
          }}
        >
          Add file from URL
        </DialogTitle>
        <DialogContent>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label
              style={{
                fontSize: "17px",
                fontWeight: "500",
                paddingBottom: "10px",
              }}
              htmlFor="title"
            >
              Image, YouTube, or Vimeo URL
            </label>
            <input
              style={{
                padding: "20px 15px",
                borderRadius: "5px",
                border: "1px solid #898F94",
              }}
              type="text"
              id="title"
              value={title}
              onChange={handleTitleChange}
              placeholder="https://"
            />
          </div>
          <hr style={{ marginTop: "30px" }} />

          <form style={{ display: "flex", justifyContent: "end", gap: "20px" }}>
            <Button
              style={{
                width: "95px",
                fontSize: "16px",
                height: "40px",
                color: "black",
                border: "1px solid #898F94",
                borderRadius: "4px",
                textTransform: "none",
              }}
              onClick={handleCloseClickDlt}
            >
              Cancel
            </Button>
            <Button
              style={{
                width: "95px",
                height: "40px",
                fontSize: "16px",
                color: "#9AA1AA",
                borderRadius: "4px",
                backgroundColor: "#EBECEF",
                textTransform: "none",
              }}
              variant="contained"
              // onClick={() => deletById(selectedRowId)}
            >
              Add file
            </Button>
          </form>
        </DialogContent>
      </Dialog>

     
    </div>
  );
};

export default RichTextEditor;
