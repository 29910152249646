import * as React from "react";
// import Table from '@mui/material/Table';
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReactToPrint from "react-to-print";
import ClearIcon from '@mui/icons-material/Clear';
import { MdHomeFilled } from "react-icons/md";
import "@fortawesome/fontawesome-free/css/all.css";
import SearchBar from "./Search";
import departments from "../../_mockData/departments";
import { useState, useEffect } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdOutlineCloudUpload } from "react-icons/md";
import { Dialog, DialogTitle, DialogContent, Button } from "@mui/material";

import Tooltip from "@mui/material/Tooltip";

import InvoicesComponent from "./invoices";
import { Box, IconButton, Menu, MenuItem, Table, Tabs} from "@mui/material";
import Grid from "@mui/material/Grid";
import CircularProgressWithLabel from "../spinner";
// import CircularProgressWithLabel from "./Components/spinner";
import PropTypes from "prop-types";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { BottomNavigation } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import Checkbox from "@mui/material/Checkbox";

import FilterIcon from "@mui/icons-material/FilterList";

import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import TypeFilter from "./FilterType";


import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import StatusFilter from "./StatusFilter";
import VendorFilter from "./VendorFilter";
import RichTextEditor from "./AddProduct";
import VariationsPerent from "./variationsPerent";
import Timeline from "./ProductOrganization";
import ProductOrganization from "./ProductOrganization";
import MetaTags from "./MetaTags";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  selected: {
    color: "red", // Change the color to your desired color
    border: "1px solid red", // Add the desired border styles
  },
}));

function TabPanel(props) {
  const { children, valueOther, i, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={valueOther !== i}
      id={`simple-tabpanel-two-${i}`}
      aria-labelledby={`simple-tab-two-${i}`}
      {...other}
    >
      {valueOther === i && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function TabPanelComponent(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`custom-tabpanell-${index}`}
      aria-labelledby={`custom-tabb-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanelComponent.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yPropss(index) {
  return {
    id: `custom-tabb-${index}`,
    "aria-controlss": `custom-tabpanell-${index}`,
  };
}

TabPanel.propTypes = {
  children: PropTypes.node,
  i: PropTypes.number.isRequired,
  valueOther: PropTypes.number.isRequired,
};

// ufghweifgwefg
function a11yProps(index) {
  return {
    id: `simple-tab-two-${index}`,
    "aria-controls": `simple-tabpanel-two-${index}`,
  };
}

export default function Products() {
  const classesPagination = useStyles();
  // tooltip
  const [Tooltipvalues, setTooltipValuee] = useState("Click to sort ascending");
  const [Tooltipvalues3, setTooltipValuee3] = useState(
    "Click to sort ascending"
  );
  const [Tooltipvalues1, setTooltipValuee1] = useState(
    "Click to sort ascending"
  );
  const [Tooltipvalues5, setTooltipValuee5] = useState(
    "Click to sort ascending"
  );
  const [orderBy, setOrderBy] = useState("");

  const [Tooltipvalues2, setTooltipValuee2] = useState(
    "Click to sort ascending"
  );
  const [orderBy2, setOrderBy2] = useState("");
  const [anchorEl2, setAnchorEl2] = React.useState(null);

  const [orderBy3, setOrderBy3] = useState("");
  const [anchorEl3, setAnchorEl3] = React.useState(null);
  const [orderBy1, setOrderBy1] = useState("");
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [orderBy5, setOrderBy5] = useState("");

  const [quantity, setQuantity] = useState(); // Initialize quantity state with a default value
  const [Sku, setSku] = useState(); // Initialize quantity state with a default value



  const [selectedStatusFromUser, setselectedStatusFromUser] = React.useState(
    []
  );

  const [addShopLocation, setAddShopLocation] = useState(false);
  const HendelShopLocationDialog = () => {
    setAddShopLocation(true);
  };
  const handleCloseShopLocationDialog = () => {
    // Close the edit dialog without saving
    setAddShopLocation(false);
  };
  const HendelLeaveThisPage = () => {
    setAddShopLocation(false);
    setShowDivs(false);
  };
  const shopLocationData = (event) => {
    setAddShopLocation(event.target.value);
  };
 

  const Status=selectedStatusFromUser.map((item)=>{
    if(item === "Complete"){
      return " Active "
    }
    else if(item === "pending"){
      return " Draft "
    }
    
  
  })
  
  const [selectedVendorFromUser, setSelectedVendorFromUser] = React.useState(
    []
  );

  
  const [selectedTypeFromUser, setSelectedTypeFromUser] = React.useState(
    []
  );



  const navigate = useNavigate();
  const listItem = {
    height: "85px",
    borderBottom: "1px solid rgba(0, 0, 0, 0.17)",
    paddingLeft: "20px",
    color: "rgba(0, 0, 0, 0.76)",
    fontSize: "14px",
    fontWeight: "400",
    // fontFamily: 'Inter'
  };
 

  const formatLabel = (label) => {
    const formattedLabel =
      label.charAt(0).toUpperCase() + label.slice(1).toLowerCase();
    return formattedLabel;
  };

  const Details = [
    {
      title: "Details",
      "Company name": "Let dop business",

      Address: "Myhsteret 16",
      "Postal Code": "8535 OK",
      City: "Amsterdam",
      "Province / State": "Zuid holland",
      Country: "Netherlands",
    },
    {
      title: "Contact Info",
      Email: "test@google.nl",
      Website: "www.google.nl",
      Phone: "+31 020-32647874",
      "Fax machine": "+31 020-5741126",
      Coc: "123345356",
      "Vat number": "142342435356",
      Industry: "Media",
      Sector: "Marketing",
      Size: "25",
    },
  ];
  const Summary = [
    {
      point: 10,
      des: "Deals",
    },
    {
      point: "$25k",
      des: "Purchases",
    },
    {
      point: "$85k",
      des: "Life Time Value (LTV)",
    },
    {
      point: "25 Days",
      des: "Last Contact",
    },
    { point: "90 Days", des: "Last Order" },
    {
      point: "111",
      des: "RFM Score",
    },
  ];
  const [searchIterm, setSearchIterm] = useState();

  const [isClick, setisClick] = useState(false);

  const [selectedRow, setSelectedRow] = useState(null);
  const [ColorIcon, setColorIcon] = useState("#A49E9E");
  const [downColorIcon, downsetColorIcon] = useState("#A49E9E");
  const [bgColorCellTD, setBgColorCellTD] = useState("white");
  const [bgClass, setBgClass] = useState("white");

  const [downColorIcon2, downsetColorIcon2] = useState("#A49E9E");
  const [ColorIcon2, setColorIcon2] = useState("#A49E9E");
  const [bgColorCellTD2, setBgColorCellTD2] = useState("white");
  const [bgClass2, setBgClass2] = useState("white");

  const [downColorIcon3, downsetColorIcon3] = useState("#A49E9E");
  const [ColorIcon3, setColorIcon3] = useState("#A49E9E");
  const [bgColorCellTD3, setBgColorCellTD3] = useState("white");
  const [bgClass3, setBgClass3] = useState("white");

  const [downColorIcon1, downsetColorIcon1] = useState("#A49E9E");
  const [ColorIcon1, setColorIcon1] = useState("#A49E9E");
  const [bgColorCellTD1, setBgColorCellTD1] = useState("white");
  const [bgClass1, setBgClass1] = useState("white");


  const [departmentsData, setData] = useState([]);
  



  const [AllProd, setAllProd] = useState(departments);

  //mahidul
  const [uniqueStatuses, setUniqueStatuses] = useState([]);
  const [uniqueVendor, setuniqueVendor] = useState([]);

  const [uniqueType, setuniqueType] = useState([]);



  const toggleButtons1 = () => {
    if (orderBy1 === "") {
      setBgClass1("testClass1");
      setOrderBy1("asc");
      setTooltipValuee1("Click to sort descending");
      const sortedArr = [...departmentsData].sort((a, b) =>
        a.name.localeCompare(b.name)
      );
  
      setData(sortedArr);

      setColorIcon1("#00A1E4");
      downsetColorIcon1("#A49E9E");
      setBgColorCellTD1("snow");

      setTooltipValuee("Click to sort ascending");
      setTooltipValuee2("Click to sort ascending");
      setTooltipValuee3("Click to sort ascending");
      setTooltipValuee5("Click to sort ascending");

      setBgClass("testClass2");
      setBgClass2("testClass2");
      setBgClass3("testClass2");

      setOrderBy("");
      setOrderBy2("");
      setOrderBy3("");
      setOrderBy5("");

      setColorIcon("#A49E9E");
      setColorIcon2("#A49E9E");
      setColorIcon3("#A49E9E");

      downsetColorIcon("#A49E9E");
      downsetColorIcon2("#A49E9E");
      downsetColorIcon3("#A49E9E");

      setBgColorCellTD("white");
      setBgColorCellTD2("white");
      setBgColorCellTD3("white");
    } else if (orderBy1 === "asc") {
      setBgClass1("testClass1");
      setOrderBy1("dsc");

      setColorIcon1("#A49E9E");
      downsetColorIcon1("#00A1E4");
      setBgColorCellTD1("snow");
      setTooltipValuee1("Click to cancel sorting");
      const sortedArr = [...departmentsData].sort((a, b) =>
        b.name.localeCompare(a.name)
      );
   
      setData(sortedArr);

      setTooltipValuee("Click to sort ascending");
      setTooltipValuee2("Click to sort ascending");
      setTooltipValuee3("Click to sort ascending");
      setTooltipValuee5("Click to sort ascending");

      setBgClass("testClass2");
      setBgClass2("testClass2");
      setBgClass3("testClass2");

      setOrderBy("");
      setOrderBy2("");
      setOrderBy3("");
      setOrderBy5("");

      setColorIcon("#A49E9E");
      setColorIcon2("#A49E9E");
      setColorIcon3("#A49E9E");

      downsetColorIcon("#A49E9E");
      downsetColorIcon2("#A49E9E");
      downsetColorIcon3("#A49E9E");

      setBgColorCellTD("white");
      setBgColorCellTD2("white");
      setBgColorCellTD3("white");
    } else if (orderBy1 === "dsc") {
      setBgClass1("testClass2");
      setOrderBy1("");

      setColorIcon1("#A49E9E");
      downsetColorIcon1("#A49E9E");
      setBgColorCellTD1("white");
      setTooltipValuee1("Click to sort ascending");
        
        setData([...departments]);

      setTooltipValuee("Click to sort ascending");
      setTooltipValuee2("Click to sort ascending");
      setTooltipValuee3("Click to sort ascending");
      setTooltipValuee5("Click to sort ascending");

      setBgClass("testClass2");
      setBgClass2("testClass2");
      setBgClass3("testClass2");

      setOrderBy("");
      setOrderBy2("");
      setOrderBy3("");
      setOrderBy5("");

      setColorIcon("#A49E9E");
      setColorIcon2("#A49E9E");
      setColorIcon3("#A49E9E");

      downsetColorIcon("#A49E9E");
      downsetColorIcon2("#A49E9E");
      downsetColorIcon3("#A49E9E");

      setBgColorCellTD("white");
      setBgColorCellTD2("white");
      setBgColorCellTD3("white");
    }
  };
  const toggleButtonsanouther = () => {
    if (orderBy2 === "") {
      setBgClass2("testClass1");
      setOrderBy2("asc");
      setTooltipValuee2("Click to sort descending");

      setColorIcon2("#00A1E4");
      downsetColorIcon2("#A49E9E");
      setBgColorCellTD2("snow");
      const sortedArr = [...departmentsData].sort((a, b) =>
        a.inventory.localeCompare(b.inventory)
      );
      setData(sortedArr);
      setTooltipValuee("Click to sort ascending");
      setTooltipValuee1("Click to sort ascending");
      setTooltipValuee3("Click to sort ascending");
      setTooltipValuee5("Click to sort ascending");

      setBgClass("testClass2");
      setBgClass1("testClass2");
      setBgClass3("testClass2");

      setOrderBy("");
      setOrderBy1("");
      setOrderBy3("");
      setOrderBy5("");

      setColorIcon("#A49E9E");
      setColorIcon1("#A49E9E");
      setColorIcon3("#A49E9E");

      downsetColorIcon("#A49E9E");
      downsetColorIcon1("#A49E9E");
      downsetColorIcon3("#A49E9E");

      setBgColorCellTD("white");
      setBgColorCellTD1("white");
      setBgColorCellTD3("white");
    } else if (orderBy2 === "asc") {
      setBgClass2("testClass1");
      setOrderBy2("dsc");

      setColorIcon2("#A49E9E");
      downsetColorIcon2("#00A1E4");
      setBgColorCellTD2("snow");
      setTooltipValuee2("Click to cancel sorting");
      const sortedArr = [...departmentsData].sort((a, b) =>
        b.inventory.localeCompare(a.inventory)
      );
      setData(sortedArr);
      setTooltipValuee("Click to sort ascending");
      setTooltipValuee1("Click to sort ascending");
      setTooltipValuee3("Click to sort ascending");
      setTooltipValuee5("Click to sort ascending");

      setBgClass("testClass2");
      setBgClass1("testClass2");
      setBgClass3("testClass2");

      setOrderBy("");
      setOrderBy1("");
      setOrderBy3("");
      setOrderBy5("");

      setColorIcon("#A49E9E");
      setColorIcon1("#A49E9E");
      setColorIcon3("#A49E9E");

      downsetColorIcon("#A49E9E");
      downsetColorIcon1("#A49E9E");
      downsetColorIcon3("#A49E9E");

      setBgColorCellTD("white");
      setBgColorCellTD1("white");
      setBgColorCellTD3("white");
    } else if (orderBy2 === "dsc") {
      setBgClass2("testClass2");
      setOrderBy2("");
      setColorIcon2("#A49E9E");
      downsetColorIcon2("#A49E9E");
      setBgColorCellTD2("white");
      setTooltipValuee2("Click to sort ascending");
      setData([...departments]);

      setTooltipValuee("Click to sort ascending");
      setTooltipValuee1("Click to sort ascending");
      setTooltipValuee3("Click to sort ascending");
      setTooltipValuee5("Click to sort ascending");

      setBgClass("testClass2");
      setBgClass1("testClass2");
      setBgClass3("testClass2");

      setOrderBy("");
      setOrderBy1("");
      setOrderBy3("");
      setOrderBy5("");

      setColorIcon("#A49E9E");
      setColorIcon1("#A49E9E");
      setColorIcon3("#A49E9E");

      downsetColorIcon("#A49E9E");
      downsetColorIcon1("#A49E9E");
      downsetColorIcon3("#A49E9E");

      setBgColorCellTD("white");
      setBgColorCellTD1("white");
      setBgColorCellTD3("white");
    }
  };

  const toggleButtons3 = () => {
    if (orderBy3 === "") {
      setBgClass3("testClass1");
      setOrderBy3("asc");
      setTooltipValuee3("Click to sort descending");

      setColorIcon3("#00A1E4");
      downsetColorIcon3("#A49E9E");
      setBgColorCellTD3("snow");
      const sortedArr = [...departmentsData].sort((a, b) =>
        a.type.localeCompare(b.type)
      );
      setData(sortedArr);

      setTooltipValuee("Click to sort ascending");
      setTooltipValuee1("Click to sort ascending");
      setTooltipValuee2("Click to sort ascending");
      setTooltipValuee5("Click to sort ascending");

      setBgClass("testClass2");
      setBgClass1("testClass2");
      setBgClass2("testClass2");

      setOrderBy("");
      setOrderBy1("");
      setOrderBy2("");
      setOrderBy5("");

      setColorIcon("#A49E9E");
      setColorIcon1("#A49E9E");
      setColorIcon2("#A49E9E");

      downsetColorIcon("#A49E9E");
      downsetColorIcon1("#A49E9E");
      downsetColorIcon2("#A49E9E");

      setBgColorCellTD("white");
      setBgColorCellTD1("white");
      setBgColorCellTD2("white");
    } else if (orderBy3 === "asc") {
      setBgClass3("testClass1");
      setOrderBy3("dsc");

      setColorIcon3("#A49E9E");
      downsetColorIcon3("#00A1E4");
      setBgColorCellTD3("snow");
      setTooltipValuee3("Click to cancel sorting");
      const sortedArr = [...departmentsData].sort((a, b) =>
        b.type.localeCompare(a.type)
      );
      setData(sortedArr);

      setTooltipValuee("Click to sort ascending");
      setTooltipValuee1("Click to sort ascending");
      setTooltipValuee2("Click to sort ascending");
      setTooltipValuee5("Click to sort ascending");

      setBgClass("testClass2");
      setBgClass1("testClass2");
      setBgClass2("testClass2");

      setOrderBy("");
      setOrderBy1("");
      setOrderBy2("");
      setOrderBy5("");

      setColorIcon("#A49E9E");
      setColorIcon1("#A49E9E");
      setColorIcon2("#A49E9E");

      downsetColorIcon("#A49E9E");
      downsetColorIcon1("#A49E9E");
      downsetColorIcon2("#A49E9E");

      setBgColorCellTD("white");
      setBgColorCellTD1("white");
      setBgColorCellTD2("white");
    } else if (orderBy3 === "dsc") {
      setBgClass3("testClass2");
      setOrderBy3("");

      setColorIcon3("#A49E9E");
      downsetColorIcon3("#A49E9E");
      setBgColorCellTD3("white");
      setTooltipValuee3("Click to sort ascending");
      setData([...departments]);
      setTooltipValuee("Click to sort ascending");
      setTooltipValuee1("Click to sort ascending");
      setTooltipValuee2("Click to sort ascending");
      setTooltipValuee5("Click to sort ascending");

      setBgClass("testClass2");
      setBgClass1("testClass2");
      setBgClass2("testClass2");

      setOrderBy("");
      setOrderBy1("");
      setOrderBy2("");
      setOrderBy5("");

      setColorIcon("#A49E9E");
      setColorIcon1("#A49E9E");
      setColorIcon2("#A49E9E");

      downsetColorIcon("#A49E9E");
      downsetColorIcon1("#A49E9E");
      downsetColorIcon2("#A49E9E");

      setBgColorCellTD("white");
      setBgColorCellTD1("white");
      setBgColorCellTD2("white");
    }
  };

  const toggleButtons = () => {
    if (orderBy === "") {
      setBgClass("testClass1");
      setOrderBy("asc");
      setTooltipValuee("Click to sort descending");

      setColorIcon("#00A1E4");
      downsetColorIcon("#A49E9E");
      setBgColorCellTD("snow");

      const sortedArr = [...departmentsData].sort((a, b) =>
        a.vendor.localeCompare(b.vendor)
      );
      setData(sortedArr);

      setTooltipValuee1("Click to sort ascending");
      setTooltipValuee2("Click to sort ascending");
      setTooltipValuee3("Click to sort ascending");
      setTooltipValuee5("Click to sort ascending");

      setBgClass1("testClass2");
      setBgClass2("testClass2");
      setBgClass3("testClass2");

      setOrderBy1("");
      setOrderBy2("");
      setOrderBy3("");
      setOrderBy5("");

      setColorIcon1("#A49E9E");
      setColorIcon2("#A49E9E");
      setColorIcon3("#A49E9E");

      downsetColorIcon1("#A49E9E");
      downsetColorIcon2("#A49E9E");
      downsetColorIcon3("#A49E9E");

      setBgColorCellTD1("white");
      setBgColorCellTD2("white");
      setBgColorCellTD3("white");
    } else if (orderBy === "asc") {
      setBgClass("testClass1");
      setOrderBy("dsc");

      setColorIcon("#A49E9E");
      downsetColorIcon("#00A1E4");
      setBgColorCellTD("snow");
      setTooltipValuee("Click to cancel sorting");
      const sortedArr = [...departmentsData].sort((a, b) =>
        b.vendor.localeCompare(a.vendor)
      );
      setData(sortedArr);

      setTooltipValuee1("Click to sort ascending");
      setTooltipValuee2("Click to sort ascending");
      setTooltipValuee3("Click to sort ascending");
      setTooltipValuee5("Click to sort ascending");

      setBgClass1("testClass2");
      setBgClass2("testClass2");
      setBgClass3("testClass2");

      setOrderBy1("");
      setOrderBy2("");
      setOrderBy3("");
      setOrderBy5("");

      setColorIcon1("#A49E9E");
      setColorIcon2("#A49E9E");
      setColorIcon3("#A49E9E");

      downsetColorIcon1("#A49E9E");
      downsetColorIcon2("#A49E9E");
      downsetColorIcon3("#A49E9E");

      setBgColorCellTD1("white");
      setBgColorCellTD2("white");
      setBgColorCellTD3("white");
    } else if (orderBy === "dsc") {
      setBgClass("testClass2");
      setOrderBy("");

      setColorIcon("#A49E9E");
      downsetColorIcon("#A49E9E");
      setBgColorCellTD("white");
      setTooltipValuee("Click to sort ascending");
      setData([...departments]);

      setTooltipValuee1("Click to sort ascending");
      setTooltipValuee2("Click to sort ascending");
      setTooltipValuee3("Click to sort ascending");
      setTooltipValuee5("Click to sort ascending");

      setBgClass1("testClass2");
      setBgClass2("testClass2");
      setBgClass3("testClass2");

      setOrderBy1("");
      setOrderBy2("");
      setOrderBy3("");
      setOrderBy5("");

      setColorIcon1("#A49E9E");
      setColorIcon2("#A49E9E");
      setColorIcon3("#A49E9E");

      downsetColorIcon1("#A49E9E");
      downsetColorIcon2("#A49E9E");
      downsetColorIcon3("#A49E9E");

      setBgColorCellTD1("white");
      setBgColorCellTD2("white");
      setBgColorCellTD3("white");
    }
  };

  const [valueTwo, setValueTwo] = React.useState(0);
  const handleChange = (event, newValuee) => {
    setValueTwo(newValuee);
  };
  const [values, setValuee] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValuee(newValue);
  };

  const classes = useStyles();
  const [showDivs, setShowDivs] = useState(false);
  const [showDivsTwo, setShowDivsTwo] = useState(false);

  const handleClickdiv = async () => {
    AnouthersetLoading(true);
    setShowDivs(true);
    setShowDivsTwo(false);
    setSelectedRow(null);
    setTimeout(() => {
      AnouthersetLoading(false); // Set loading state to false after 1 seconds
    }, 2000);
  };

  const handleClosediv = () => {
    setShowDivs(false);
  };
  const handleClickdivTwo = () => {
    setLoadingOther(true);
    setShowDivsTwo(true);
    setShowDivs(false);

    setTimeout(() => {
      setLoadingOther(false); // Set loading state to false after 1 seconds
    }, 2000);
  };

  const handleClosedivTwo = () => {
    setShowDivsTwo(false);
    setSelectedRow(null);
  };

  const basicInfo = {
    fontWeight: "700",
    fontSize: "20px",
    
   
  };
 
  const buttonStyle = {
    backgroundColor: "#00A1E4",
    color: "#fff",
    alignItems: "center",
    columnGap: "5px",
    border: "none",
    textTransform: "none",
    width: "140px",
    height: "40px",
    borderRadius: "6px",
    cursor: "pointer",
    fontSize: "20px",
    fontWeight: "400",
    marginLeft: "20px",
  };
  // for box shedw
  const shadowStyle = {
    margin: "10px",
    boxShadow:
      "0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -2px rgba(0, 0, 0, .1)",
    "--tw-shadow-colored":
      "0 4px 6px -1px var(--tw-shadow-color),0 2px 4px -2px var(--tw-shadow-color)",
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    // work
    setShowDivsTwo(false);
    setSelectedRow(null);
    setAnchorEl(event.currentTarget);
    setShowDivs(false);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [anchorElMenue, setAnchorElMenue] = useState(null);
  const handleClickMenue = (event) => {
    setAnchorElMenue(event.currentTarget);
  };
  const handleCloseMenue = () => {
    setAnchorElMenue(null);
  };
  const openIconMenu = Boolean(anchorElMenue);

  const openIcon = Boolean(anchorEl);

  const Invoices = {
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "17px",
    color: "#000000",
  };
  const [vendorSearch, setVendorSearch] = useState();

  const [typeSearch, settypeSearch] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingOther, setLoadingOther] = useState(false);
  const [Anoutherloading, AnouthersetLoading] = useState(false);
  const [clearFilter, setClearFilter] = useState(false);




 
  useEffect(() => {
   
    getAllStatuses();
    getAllVendor(vendorSearch)
    getAllType(typeSearch)

    if(searchIterm) {
      handleInputChange(searchIterm);
      
      if(selectedStatusFromUser?.length > 0 || selectedTypeFromUser.length > 0 || selectedVendorFromUser.length > 0) {
        getFilterData(departmentsData, selectedStatusFromUser , selectedVendorFromUser,selectedTypeFromUser);

      }
      
    } else {
      getFilterData(AllProd, selectedStatusFromUser , selectedVendorFromUser,selectedTypeFromUser);
    }
    
  
  }, [ selectedStatusFromUser,selectedVendorFromUser,vendorSearch,typeSearch,selectedTypeFromUser, searchIterm]);

  useEffect(() => {
    if(!isClick) {
      setSearchIterm("")
    }
  }, [isClick])

  const [anchorEl4, setAnchorEl4] = React.useState(null);

  // checkBox in Table
  const [selectedRows, setSelectedRows] = useState([]);

  const handleRowCheckboxChange = (event, index) => {
    if (event.target.checked) {
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, index]);
    } else {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((rowIndex) => rowIndex !== index)
      );
    }
  };

  const isSelected = (index) => selectedRows.includes(index);

  const deleteItems = (selectedRows) => {
    const updatedItems = [...departmentsData];

    setData(
      updatedItems.filter((item, index) => !selectedRows.includes(index))
    );

    setSelectedRows([]);
  };

  const handleInputChange = (searchIterm) => {
    const filtereProducts = AllProd.filter((Product) =>
      Product.name.toLowerCase().includes (searchIterm)
    );

    // setSearchData(filtereProducts);
    setData(filtereProducts);

    if (searchIterm === "" || searchIterm === undefined) {
      setData(departments);
    }
  };


  const clearUserFilter=()=>{

    setselectedStatusFromUser([]);
  
    setSelectedVendorFromUser([]);
    setVendorSearch();
    setSelectedTypeFromUser([]);
    settypeSearch()

  } ;

   const clearStatusFilter=()=>{

    setselectedStatusFromUser([]);
  

  } ;

   const clearTypeFilter=()=>{
 
    setSelectedTypeFromUser([]);
    settypeSearch()

  };
  
  const clearVendorFilter=()=>{
   
  
    setSelectedVendorFromUser([]);
    setVendorSearch();
  

  }

  const getFilterData = (dataToFilter, data, vendor,type) => {
    
      if ((!data || data?.length === 0) && (!vendor || vendor?.length === 0) && (!type || type?.length === 0)) {
        setData(dataToFilter); // Show all departments when no filters are selected
        
      }
      else if (data && data?.length > 0 && (!vendor || vendor?.length === 0) && (!type || type?.length === 0))  {
        let filteredData;
        if (data?.length === 1) {
          filteredData = dataToFilter.
          filter(
            (item) =>
            item.status.includes(data[0])
          );
        } else if (data?.length > 1) {
          filteredData = dataToFilter.filter(
            (item) =>
              item.status.includes(data[0]) || item.status.includes(data[1])
          );
        }
        setData(filteredData); // Update the data with the filtered departments
      }
      
      
      else if ((!data || data?.length === 0) &&
               (!type || type?.length === 0) && 
               vendor && vendor?.length > 0) {
        // Filter based on vendor only
        if(vendor?.length === 1){
          const filteredData = dataToFilter.filter((item) => {
            return vendor.includes(item.vendor);
          })
          setData(filteredData);
        }
        else if(vendor?.length > 1){
        
          const filteredData = dataToFilter.filter((item) => {
            return vendor.includes(item.vendor) || vendor.includes(item.vendor);
          });
          setData(filteredData);
        }
      } 
      
      else if((!data || data?.length === 0) &&
              (!vendor || vendor?.length === 0)&&
              type && type?.length > 0){

                if(type?.length === 1){
             
                  const filterData = dataToFilter.filter((item)=>{
                    return type.includes(item.type);
                  })
                  setData(filterData);
                }
  
                else if(type?.length > 1){
                
                   const filterData =dataToFilter.filter((item)=>{
                    return type.includes(item.type) || type.includes(item.type);
                   });
                   setData(filterData);
                }
              }
  
              // multiColumn Filter
  
  
  
  
              else if ((data && data?.length > 0) &&
              (type && type?.length > 0) && 
              vendor && vendor?.length > 0) {
       // Filter based on vendor only
       if(data.length && type?.length && vendor?.length === 1){
         const filteredData = dataToFilter.filter((item) => {
           return data.includes(item.status) && type.includes(item.type) && vendor.includes(item.vendor) ;
         })
         setData(filteredData);
       }
       else if(data?.length && type?.length && vendor?.length > 1){
       
         const filteredData = dataToFilter.filter((item) => {
           return (data.includes(item.status) || data.includes(item.status)) && (type.includes(item.type) || type.includes(item.type)) && (vendor.includes(item.vendor) || vendor.includes(item.vendor)) ;
         });
         setData(filteredData);
       }
     }
     
            else if ((data && data?.length > 0) &&
              (!type || type?.length === 0) && 
              vendor && vendor?.length > 0) {
       // Filter based on vendor only
       if(data?.length && vendor?.length === 1){
         const filteredData = dataToFilter.filter((item) => {
           return data.includes(item.status)  && vendor.includes(item.vendor) ;
         })
         setData(filteredData);
       }
       else if(data?.length  && vendor?.length > 1){
       
         const filteredData = dataToFilter.filter((item) => {
           return (data.includes(item.status) || data.includes(item.status))  && (vendor.includes(item.vendor) || vendor.includes(item.vendor)) ;
         });
         setData(filteredData);
       }
     }    
     
     else if ((data && data?.length > 0) &&
              (!vendor || vendor?.length === 0)&&
              type && type?.length > 0 )
              {
       // Filter based on vendor only
       if(data.length && type?.length === 1){
         const filteredData = dataToFilter.filter((item) => {
           return data.includes(item.status)  && type.includes(item.type) ;
         })
         setData(filteredData);
       }
       else if(data?.length  && type?.length > 1){
       
         const filteredData = dataToFilter.filter((item) => {
           return (data.includes(item.status) || data.includes(item.status))  && (type.includes(item.type) || type.includes(item.type)) ;
         });
         setData(filteredData);
       }
     }   
     else if ((!data || data?.length === 0) &&
              (vendor && vendor?.length > 0)&&
              type && type?.length > 0 )
              {
       // Filter based on vendor only
       if(vendor?.length && type?.length === 1){
         const filteredData = dataToFilter.filter((item) => {
           return vendor.includes(item.vendor)  && type.includes(item.type) ;
         })
         setData(filteredData);
       }
       else if(data?.length  && type?.length > 1){
       
         const filteredData = dataToFilter.filter((item) => {
           return (vendor.includes(item.vendor) || vendor.includes(vendor.status))  && (type.includes(item.type) || type.includes(item.type)) ;
         });
         setData(filteredData);
       }
     } 
    // }

   
          
  };


  function getAllStatuses() {
    const statuses = AllProd.map((department) => department.status);
    const uniqueStatusesData = [...new Set(statuses)];
    setUniqueStatuses(uniqueStatusesData);
  }  




   function getAllVendor(vendorSearch) {
   
    if(vendorSearch ){
   
  
      const resVendor = uniqueVendor.filter((res)=>res.toLocaleLowerCase().includes(vendorSearch));
    
      setuniqueVendor(resVendor);

    }
    else{
      const vendor = AllProd.map((department) => department.vendor);
      const uniqueVendorData = [...new Set(vendor)];
      setuniqueVendor(uniqueVendorData);
    }
   
  } 
  
  function getAllType(typeSearch) {
   
    if(typeSearch ){
      // console.log("Search",vendorSearch);
  
      const resType = uniqueType.filter((res)=>res.toLocaleLowerCase().includes(typeSearch));
    
      setuniqueType(resType);

    }
    else{
      const type = AllProd.map((department) => department.type);
      const uniqueTypeData = [...new Set(type)];
      setuniqueType(uniqueTypeData);
    }
   
  }

  const tableRef = React.useRef()

  return (
    <>
      <div className={"webview"} style={{ display: "flex" }}>
        <div
          style={{
            width: showDivs || showDivsTwo ? "58.3%" : "100%",
            transition: "width 0.3s linear ",
          }}
        >
 <BottomNavigation
            style={{
              backgroundColor: "#ffffff",
              // display:showDivs || showDivsTwo ?"flex": "grid",
              display: showDivs || showDivsTwo ? " flex":"grid" ,
              gridTemplateColumns:showDivs || showDivsTwo ? "" :"15% 73% 11%",
              justifyContent:showDivs || showDivsTwo ? "space-between" : "start",
              // ...(showDivs || showDivsTwo ? { justifyContent: 'space-between' } : { gridTemplateColumns: '35% 65%' }),

              alignItems: "center",
              paddingRight: "30px",
              paddingLeft: "26px",
              height:'70px',
              borderBottom: "2px solid #D3CECE",
            }}
          >
            <div
              style={{
                display: "flex",
                paddingBottom:showDivs || showDivsTwo ? "0px" :'1px',
                alignContent: "center",
                columnGap: "25px",

              }}
            >
              <p style={Invoices}>Products</p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  columnGap: "20px",
                }}
              >
                <img src="/assets/images/home.svg" />
                <p style={{ fontSize: "14px", fontWeight: "600" }}>
                  Home <span style={{ color: "#00A1E4" }}> / </span>
                  <span style={{ color: "#00A1E4" }}>Products</span>{" "}
                  <span style={{ color: "#00A1E4" }}> / </span>
                  <span style={{ color: "#00A1E4" }}> All</span>{" "}
                </p>
              </div>
            </div>

          
            {/* <div  style={{display:'flex',alignItems:'center'}}> */}

            <div
            style={{
              display: showDivs || showDivsTwo ? "none" : "block",
              width: "auto",
              // margin: "1% 2%",
              // paddingBottom:'10px'
            }}
          >
            <SearchBar
              setSearchIterm={setSearchIterm}
              searchIterm={searchIterm}
              click={setisClick}
            />

        
          </div>





            <div style={{justifyContent:'end',display:"flex", paddingBottom:showDivs || showDivsTwo ? "0px" :'1px',}}>
             {/* <IconButton aria-label="delete">
                <img src="/assets/images/dwn.svg"></img>
              </IconButton> */}
              <ReactToPrint
              
        trigger={() => <IconButton aria-label="delete">
        <img src="/assets/images/pri.svg"></img>
      </IconButton>}
        content={() => tableRef.current}
      />
              {/* <IconButton aria-label="delete">
                <img src="/assets/images/pri.svg"></img>
              </IconButton> */}

              <Button onClick={handleClickdiv} style={buttonStyle} size="small">
               Add Product
              </Button>
            </div>
            {/* </div> */}
          </BottomNavigation>
          
         

          {selectedRows?.length > 0 && selectedRows?.length === 1 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0px 45px",
                height: "40px",
              }}
            >
              <p
                style={{ fontSize: "15px", fontWeight: "400", color: "black" }}
              >
                Selected {selectedRows?.length} product
              </p>
              <img
                onClick={() => deleteItems(selectedRows)}
                style={{ height: "17px", width: "17px" }}
                src="/assets/images/dlt.svg"
                alt=""
              />
            </div>
          ) : selectedRows?.length > 0 && selectedRows?.length > 1 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0px 45px",
                height: "40px",
              }}
            >
              <p
                style={{ fontSize: "15px", fontWeight: "400", color: "black" }}
              >
                Selected {selectedRows?.length} products
              </p>
              <img
                onClick={() => deleteItems(selectedRows)}
                style={{ height: "17px", width: "17px" }}
                src="/assets/images/dlt.svg"
                alt=""
              />
            </div>
          ) : (
            ""
          )}

         



          {
              
              <div style={{display:'flex',justifyContent:"start",alignItems:"center",marginTop:"20px"}}>
              <div style={{marginLeft:'6px',display:'flex',alignItems:"center" ,gap:"15px"}}>
                 {/* <div style={{display:'grid',gridTemplateColumns:"300px 300px 300px",alignItems:"center"}}> */}
                {selectedStatusFromUser?.length>0 ? (<div style={{border:'1px dashed rgb(211, 206, 206)',height:'40px',borderRadius:"10px",position:'relative'}}>
                  <p style={{fontSize:'14px',fontWeight:'400',padding:'0px',margin:'9px'}}> Product Status is <span style={{fontSize:'15px',fontWeight:'500'}} >{Status.join(" , ")} </span> </p>
                    <span onClick={clearStatusFilter} ><CancelIcon style={{height:'14px',position:'absolute',top:'-6px',right:'-10px'}}/></span>
                  </div>):"" }

                 {selectedTypeFromUser?.length>0 ? (<div style={{border:'1px dashed rgb(211, 206, 206)',height:'40px',borderRadius:"10px",position:'relative'}}>
                  <p style={{fontSize:'14px',fontWeight:'400',padding:'0px',margin:'9px'}}>Product Type is<span style={{fontSize:'15px',fontWeight:'500'}} >{selectedTypeFromUser.join(" , ")} </span> </p>
                  <span onClick={clearTypeFilter} ><CancelIcon style={{height:'14px',position:'absolute',top:'-6px',right:'-10px'}}/></span>
                  </div>):("")} 

                {selectedVendorFromUser?.length>0? (<div style={{border:'1px dashed rgb(211, 206, 206)',height:'40px',borderRadius:"10px",position:'relative'}}>
                  <p style={{fontSize:'14px',fontWeight:'400',padding:'0px',margin:'9px'}}>Product Vendor is<span style={{fontSize:'15px',fontWeight:'500'}} >{selectedVendorFromUser.join(" , ")} </span> </p>
                  <span onClick={clearVendorFilter} ><CancelIcon style={{height:'14px',position:'absolute',top:'-6px',right:'-10px'}}/></span>
                  </div>):("")}  
        

{Status?.length !== 0 && selectedTypeFromUser?.length !== 0 &&selectedVendorFromUser?.length !== 0 ?
  (<div style={{cursor:'pointer'}} onClick={clearUserFilter}>
    
    <p style={{fontSize:'15px',color:"#2463BC",fontWeight:'500',padding:'0px',margin:'9px'}} >Clear all</p>
  </div>):("")}

              </div>
             </div>
           
          }



          {loading ? (
            <CircularProgressWithLabel />
          ) : (
            <>
              <TableContainer
              ref={tableRef}
                component={Paper}
                sx={{
                  my: 1,
                  scrollbarWidth: "thin",
                  "&::-webkit-scrollbar": {
                    width: "0.4em",
                    display: "block",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "#f1f1f1",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#888",
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    background: "#555",
                  },
                }}
                style={{
                  display: "flex",
                  justifyContent: "center",

                  maxHeight: "100vh",
                  // paddingTop:'100px'
                }}
              >
                <Table aria-label="simple table">
                  <TableHead style={{ borderTop: "1px solid #f0f0f0" }}>
                    <TableRow className={"TR"}>
                      <TableCell
                        style={{ color: "#9F9999" }}
                        width={100}
                        height={60}
                        align="center"
                      >
                        <Checkbox
                          indeterminate={
                            selectedRows?.length > 0 &&
                            selectedRows?.length < departmentsData?.length
                          }
                          checked={
                            selectedRows?.length === departmentsData?.length &&
                            departmentsData?.length > 0
                          }
                          onChange={(event) =>
                            event.target.checked
                              ? setSelectedRows(
                                  departmentsData.map((_, index) => index)
                                )
                              : setSelectedRows([])
                          }
                        />
                      </TableCell>

                      <TableCell
                        style={{ color: "#9F9999" }}
                        width={100}
                        height={60}
                        align="left"
                      ></TableCell>

                      <Tooltip title={Tooltipvalues1} arrow placement="top">
                        <TableCell
                          className={`custom-button ${bgClass1}`}
                          style={{
                            padding: "0px",
                            height: "100%",
                          }}
                          width={300}
                          align="left"
                        >
                          <div
                            style={{
                              paddingLeft: "10px",
                              borderLeft: "1px solid #e5e7eb",
                              borderRight: "1px solid #e5e7eb",
                              margin: "5px 0px 5px 0px",
                              display: "flex",
                              // gridTemplateColumns: "85% 15%",
                              cursor: "pointer",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <span
                              onClick={toggleButtons1}
                              style={{ fontSize: "14px", fontWeight: "700" }}
                            >
                              Product
                            </span>
                            <div
                              style={{
                                display: "grid",
                                gridTemplateColumns: "50% 50%",
                                justifyContent: "start",
                                alignItems: "center",
                              }}
                            >
                              <span
                                onClick={toggleButtons1}
                                style={{
                                  paddingRight: "10px",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  gap: "3px",
                                }}
                              >
                                <svg
                                  width="100"
                                  height="100"
                                  viewBox="0 0 1000 1000"
                                  style={{ height: "10px", width: "10px" }}
                                >
                                  <path
                                    style={{ fill: ColorIcon1 }}
                                    d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z"
                                  ></path>
                                </svg>

                                <svg
                                  width="100"
                                  height="100"
                                  viewBox="0 0 1000 1000"
                                  style={{ height: "10px", width: "10px" }}
                                >
                                  <path
                                    style={{ fill: downColorIcon1 }}
                                    d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"
                                  ></path>
                                </svg>
                              </span>
                            </div>
                          </div>
                        </TableCell>
                      </Tooltip>

                      <TableCell
                        className={`custom-button ${bgClass2}`}
                        style={{
                          padding: "0px",
                          height: "100%",
                        }}
                        width={200}
                        align="left"
                      >
                        <div
                          style={{
                            paddingLeft: "10px",
                            borderLeft: "1px solid #e5e7eb",
                            borderRight: "1px solid #e5e7eb",
                            margin: "5px 0px 5px 0px",
                            display: "flex",
                            // gridTemplateColumns: "85% 15%",
                            cursor: "pointer",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <span style={{ fontSize: "14px", fontWeight: "700" }}>
                            Status
                          </span>

                          <svg
                            onClick={(event) =>
                              setAnchorEl1(event.currentTarget)
                            }
                            width="100"
                            height="100"
                            viewBox="0 0 1000 1000"
                            style={{
                              height: "15px",
                              width: "15px",
                              alignItems: "end",
                              marginRight: "10px",
                            }}
                          >
                            <path
                              style={{ fill: "#7C7C7C" }}
                              d="M349 838c0 17.7 14.2 32 31.8 32h262.4c17.6 0 31.8-14.3 31.8-32V642H349v196zm531.1-684H143.9c-24.5 0-39.8 26.7-27.5 48l221.3 376h348.8l221.3-376c12.1-21.3-3.2-48-27.7-48z"
                            ></path>
                          </svg>
                        </div>
                        {/* status Filter */}
                        <StatusFilter
                          anchor={anchorEl1}
                          setAnchor={setAnchorEl1}
                          status={uniqueStatuses}
                          setselectedStatusFromUser={setselectedStatusFromUser}
                          selectedStatusFromUser={selectedStatusFromUser}
                        />
                      </TableCell>

                      <Tooltip title={Tooltipvalues2} arrow placement="top">
                        <TableCell
                          className={`custom-button ${bgClass2}`}
                          style={{
                            padding: "0px",
                            height: "100%",
                          }}
                          width={400}
                          align="left"
                        >
                          <div
                            style={{
                              paddingLeft: "10px",
                              borderLeft: "1px solid #e5e7eb",
                              borderRight: "1px solid #e5e7eb",
                              margin: "5px 0px 5px 0px",
                              display: "flex",
                              // gridTemplateColumns: "85% 15%",
                              cursor: "pointer",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <span
                              onClick={toggleButtonsanouther}
                              style={{ fontSize: "14px", fontWeight: "700" }}
                            >
                              Inventory
                            </span>
                            <div
                              style={{
                                display: "grid",
                                gridTemplateColumns: "50% 50%",
                                justifyContent: "start",
                                alignItems: "center",
                              }}
                            >
                              <span
                                onClick={toggleButtonsanouther}
                                style={{
                                  paddingRight: "10px",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  gap: "3px",
                                }}
                              >
                                <svg
                                  width="100"
                                  height="100"
                                  viewBox="0 0 1000 1000"
                                  style={{ height: "10px", width: "10px" }}
                                >
                                  <path
                                    style={{ fill: ColorIcon2 }}
                                    d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z"
                                  ></path>
                                </svg>

                                <svg
                                  width="100"
                                  height="100"
                                  viewBox="0 0 1000 1000"
                                  style={{ height: "10px", width: "10px" }}
                                >
                                  <path
                                    style={{ fill: downColorIcon2 }}
                                    d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"
                                  ></path>
                                </svg>
                              </span>
                            
                            </div>
                          </div>
                       
                        </TableCell>
                      </Tooltip>

                      <Tooltip title={Tooltipvalues3} arrow placement="top">
                        <TableCell
                          className={`custom-button ${bgClass3}`}
                          style={{
                            padding: "0px",
                            height: "100%",
                          }}
                          width={300}
                          align="left"
                        >
                          <div
                            style={{
                              paddingLeft: "10px",
                              borderLeft: "1px solid #e5e7eb",
                              borderRight: "1px solid #e5e7eb",
                              margin: "5px 0px 5px 0px",
                              display: "flex",
                              // gridTemplateColumns: "85% 15%",
                              cursor: "pointer",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <span
                              onClick={toggleButtons3}
                              style={{ fontSize: "14px", fontWeight: "700" }}
                            >
                              Type
                            </span>
                            <div
                              style={{
                                display: "grid",
                                gridTemplateColumns: "50% 50%",
                                justifyContent: "start",
                                alignItems: "center",
                              }}
                            >
                              <span
                                onClick={toggleButtons3}
                                style={{
                                  paddingRight: "10px",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  gap: "3px",
                                }}
                              >
                                <svg
                                  width="100"
                                  height="100"
                                  viewBox="0 0 1000 1000"
                                  style={{ height: "10px", width: "10px" }}
                                >
                                  <path
                                    style={{ fill: ColorIcon3 }}
                                    d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z"
                                  ></path>
                                </svg>

                                <svg
                                  width="100"
                                  height="100"
                                  viewBox="0 0 1000 1000"
                                  style={{ height: "10px", width: "10px" }}
                                >
                                  <path
                                    style={{ fill: downColorIcon3 }}
                                    d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"
                                  ></path>
                                </svg>
                              </span>
                              <svg
                                onClick={(event) =>
                                  setAnchorEl3(event.currentTarget)
                                }
                                width="100"
                                height="100"
                                viewBox="0 0 1000 1000"
                                style={{
                                  height: "15px",
                                  width: "15px",
                                  alignItems: "end",
                                  marginRight: "10px",
                                }}
                              >
                                <path
                                  style={{ fill: "#7C7C7C" }}
                                  d="M349 838c0 17.7 14.2 32 31.8 32h262.4c17.6 0 31.8-14.3 31.8-32V642H349v196zm531.1-684H143.9c-24.5 0-39.8 26.7-27.5 48l221.3 376h348.8l221.3-376c12.1-21.3-3.2-48-27.7-48z"
                                ></path>
                              </svg>
                            </div>
                          </div>

                          <TypeFilter
                            anchor={anchorEl3}
                            setAnchor={setAnchorEl3}
                            settypeSearch={settypeSearch}
                            type={uniqueType}
                            setSelectedTypeFromUser={setSelectedTypeFromUser}
                            selectedTypeFromUser={selectedTypeFromUser}
                          />
                        </TableCell>
                      </Tooltip>

                      <Tooltip title={Tooltipvalues} arrow placement="top">
                        <TableCell
                          className={`custom-button ${bgClass}`}
                          style={{
                            padding: "0px",
                            height: "100%",
                          }}
                          width={400}
                          align="left"
                        >
                          <div
                            style={{
                              paddingLeft: "10px",
                              borderLeft: "1px solid #e5e7eb",
                              borderRight: "1px solid #e5e7eb",
                              margin: "5px 0px 5px 0px",
                              display: "flex",
                              cursor: "pointer",
                              // gridTemplateColumns: "85% 15%",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <span
                              onClick={toggleButtons}
                              style={{ fontSize: "14px", fontWeight: "700" }}
                            >
                              Vendor
                            </span>
                            <div
                              style={{
                                display: "grid",
                                gridTemplateColumns: "50% 50%",
                                justifyContent: "start",
                                alignItems: "center",
                              }}
                            >
                              <span
                                onClick={toggleButtons}
                                style={{
                                  paddingRight: "10px",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  gap: "3px",
                                }}
                              >
                                <svg
                                  width="100"
                                  height="100"
                                  viewBox="0 0 1000 1000"
                                  style={{ height: "10px", width: "10px" }}
                                >
                                  <path
                                    style={{ fill: ColorIcon }}
                                    d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z"
                                  ></path>
                                </svg>

                                <svg
                                  width="100"
                                  height="100"
                                  viewBox="0 0 1000 1000"
                                  style={{ height: "10px", width: "10px" }}
                                >
                                  <path
                                    style={{ fill: downColorIcon }}
                                    d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"
                                  ></path>
                                </svg>
                              </span>
                              <svg
                                onClick={(event) =>
                                  setAnchorEl4(event.currentTarget)
                                }
                                width="100"
                                height="100"
                                viewBox="0 0 1000 1000"
                                style={{
                                  height: "15px",
                                  width: "15px",
                                  alignItems: "end",
                                  marginRight: "10px",
                                }}
                              >
                                <path
                                  style={{ fill: "#7C7C7C" }}
                                  d="M349 838c0 17.7 14.2 32 31.8 32h262.4c17.6 0 31.8-14.3 31.8-32V642H349v196zm531.1-684H143.9c-24.5 0-39.8 26.7-27.5 48l221.3 376h348.8l221.3-376c12.1-21.3-3.2-48-27.7-48z"
                                ></path>
                              </svg>
                            </div>
                          </div>

                          <VendorFilter
                            anchor={anchorEl4}
                            setAnchor={setAnchorEl4}
                            vendor={uniqueVendor}
                            setSelectedVendorFromUser={setSelectedVendorFromUser}
                            selectedVendorFromUser={selectedVendorFromUser}
                            setVendorSearch={setVendorSearch}
                       
                          />
                        </TableCell>
                      </Tooltip>
                    </TableRow>
                  </TableHead>

                  <TableBody
                    style={{
                      overflowY: "scroll",
                    }}
                  >
                  
                  {
                    departmentsData.map((department, index) => (
                      <TableRow
                        key={index}
                        style={{
                          backgroundColor:
                            selectedRow === index ? "Gainsboro" : "white",
                        }}
                        hover
                        role="checkbox"
                        aria-checked={isSelected(index)}
                        tabIndex={-1}
                        selected={isSelected(index)}

                        // onClick={handleClickdivTwo}
                      >
                        <TableCell
                          style={{
                            alignSelf: "center",
                            padding: "0px",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                          width={100}
                          align="center"
                        >
                          <Checkbox
                            checked={isSelected(index)}
                            onChange={(event) =>
                              handleRowCheckboxChange(event, index)
                            }
                          />
                        </TableCell>

                        <TableCell align="left" style={{ padding: "0px" }}>
                          <IconButton
                            style={{ fontSize: "17px", fontWeight: "2000" }}
                            id="basic-button"
                            aria-controls={openIcon ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={openIcon ? "true" : undefined}
                            onClick={handleClick}
                          >
                            <BsThreeDotsVertical />
                          </IconButton>
                        </TableCell>

                        <TableCell
                          style={{
                            alignSelf: "left",
                            padding: "0px",
                            fontSize: "14px",
                            fontWeight: "400",
                            paddingLeft: "10px",
                            backgroundColor:
                              selectedRow === index
                                ? "Gainsboro"
                                : bgColorCellTD1 === "snow"
                                ? bgColorCellTD1
                                : "white",
                          }}
                          width={300}
                          align="left"
                          key={index}
                          onClick={() => {
                            setSelectedRow(index);
                            handleClickdivTwo();
                          }}
                          // eslint-disable-next-line react/jsx-no-duplicate-props
                          // style={{
                          //   padding: "0px",
                          //   fontSize: "14px",
                          //   fontWeight: "400",
                          //   paddingLeft: "10px",

                          //   backgroundColor:
                          //     selectedRow === index
                          //       ? "Gainsboro"
                          //       : bgColorCellTD1 === "snow"
                          //       ? bgColorCellTD1
                          //       : "white",
                          // }}
                        >
                          {department?.name}
                        </TableCell>

                        <TableCell
                          style={{ paddingLeft: "10px" }}
                          width={200}
                          align="left"
                        >
                          {/* {row.protein} */}
                          {department.status === "Complete" ? (
                            <Chip
                              label="Active"
                              style={{
                                background: "#00A1E4",
                                width: "85px",
                                height: "26px",
                                borderRadius: "5px",
                                color: "white",
                                fontSize: "13px",
                                fontWeight: "500",
                                // marginLeft: "6px",
                              }}
                            />
                          ) : department.status === "pending" ? (
                            <Chip
                              label="Draft"
                              style={{
                                background: "#4D9A19",
                                width: "85px",
                                height: "26px",
                                borderRadius: "5px",
                                color: "white",
                                fontSize: "13px",
                                fontWeight: "500",
                                // marginLeft: "8px",
                              }}
                            />
                          ) : (
                            department.status === "pending Complete" ? (
                              <Chip
                                label="test"
                                style={{
                                  background: "black",
                                  width: "85px",
                                  height: "26px",
                                  borderRadius: "5px",
                                  color: "white",
                                  fontSize: "13px",
                                  fontWeight: "500",
                                  // marginLeft: "8px",
                                }}
                              />
                            ) :("")
                          )}
                        </TableCell>

                        <TableCell
                          width={400}
                          align="left"
                          key={index}
                          onClick={() => {
                            setSelectedRow(index);
                            handleClickdivTwo();
                          }}
                          style={{
                            padding: "0px",
                            fontSize: "14px",
                            fontWeight: "400",
                            paddingLeft: "10px",

                            backgroundColor:
                              selectedRow === index
                                ? "Gainsboro"
                                : bgColorCellTD2 === "snow"
                                ? bgColorCellTD2
                                : "white",
                          }}
                        >
                          {department?.inventory}
                        </TableCell>

                        <TableCell
                          style={{
                            alignSelf: "left",
                            padding: "0px",
                            fontSize: "14px",
                            fontWeight: "400",
                            paddingLeft: "10px",
                            backgroundColor:
                              selectedRow === index
                                ? "Gainsboro"
                                : bgColorCellTD3 === "snow"
                                ? bgColorCellTD3
                                : "white",
                          }}
                          width={300}
                          align="left"
                          key={index}
                          onClick={() => {
                            setSelectedRow(index);
                            handleClickdivTwo();
                            // eslint-disable-next-line react/jsx-no-duplicate-props
                          }}
                          // style={{
                          //   padding: "0px",
                          //   fontSize: "14px",
                          //   fontWeight: "400",
                          //   paddingLeft: "10px",

                          // }}
                        >
                          {department?.type}
                        </TableCell>

                        <TableCell
                          width={400}
                          align="left"
                          key={index}
                          onClick={() => {
                            setSelectedRow(index);
                            handleClickdivTwo();
                          }}
                          style={{
                            padding: "0px",
                            fontSize: "14px",
                            fontWeight: "400",
                            paddingLeft: "10px",

                            backgroundColor:
                              selectedRow === index
                                ? "Gainsboro"
                                : bgColorCellTD === "snow"
                                ? bgColorCellTD
                                : "white",
                          }}
                        >
                          {department?.vendor}
                        </TableCell>
                      </TableRow>
                    ))}

                    
                  </TableBody>
                </Table>
              </TableContainer>

              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openIcon}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "black",
                    cursor: "pointer",
                    // fontFamily: "Inter",
                  }}
                  onClick={handleClose}
                >
                  <img
                    style={{ marginRight: "5px" }}
                    src="/assets/images/pdf_icon.svg"
                    alt="Close"
                  />{" "}
                  View Statement
                </MenuItem>

                <MenuItem
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "black",
                    cursor: "pointer",
                    fontFamily: "Inter",
                  }}
                  onClick={handleClose}
                >
                  <img
                    style={{ marginRight: "5px" }}
                    src="/assets/images/client.svg"
                    alt="Close"
                  />
                  Client Portal
                </MenuItem>

                <MenuItem
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "black",
                    cursor: "pointer",
                    fontFamily: "Inter",
                    borderBottom: "1 solid #e5e7eb!important",
                  }}
                  onClick={handleClose}
                >
                  <img
                    style={{ marginRight: "5px" }}
                    src="/assets/images/sett.svg"
                    alt="Close"
                  />
                  Settings
                </MenuItem>
                <hr color="#e5e7eb" width="80%" />

                <MenuItem
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "black",
                    cursor: "pointer",
                    fontFamily: "Inter",
                  }}
                  onClick={handleClose}
                >
                  <img
                    style={{ marginRight: "5px" }}
                    src="/assets/images/plu.svg"
                    alt="Close"
                  />
                  New Invoice
                </MenuItem>

                <MenuItem
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "black",
                    cursor: "pointer",
                    fontFamily: "Inter",
                  }}
                  onClick={handleClose}
                >
                  <img
                    style={{ marginRight: "5px" }}
                    src="/assets/images/plu.svg"
                    alt="Close"
                  />
                  New Quote
                </MenuItem>
                <MenuItem
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "black",
                    cursor: "pointer",
                    fontFamily: "Inter",
                  }}
                  onClick={handleClose}
                >
                  <img
                    style={{ marginRight: "5px" }}
                    src="/assets/images/plu.svg"
                    alt="Close"
                  />
                  Enter Payment
                </MenuItem>

                <MenuItem
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "black",
                    cursor: "pointer",
                    fontFamily: "Inter",
                  }}
                  onClick={handleClose}
                >
                  <img
                    style={{ marginRight: "5px" }}
                    src="/assets/images/plu.svg"
                    alt="Close"
                  />
                  New Task
                </MenuItem>

                <MenuItem
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "black",
                    cursor: "pointer",
                    fontFamily: "Inter",
                  }}
                  onClick={handleClose}
                >
                  <img
                    style={{ marginRight: "5px" }}
                    src="/assets/images/plu.svg"
                    alt="Close"
                  />
                  New Expense
                </MenuItem>
                <hr color="#e5e7eb" width="80%" />

                <MenuItem
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "black",
                    cursor: "pointer",
                    fontFamily: "Inter",
                  }}
                  onClick={handleClose}
                >
                  <img
                    style={{ marginRight: "5px" }}
                    src="/assets/images/share.svg"
                    alt="Close"
                  />
                  Merge
                </MenuItem>

                <MenuItem
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "black",
                    cursor: "pointer",
                    fontFamily: "Inter",
                  }}
                  onClick={handleClose}
                >
                  <img
                    style={{ marginRight: "5px" }}
                    src="/assets/images/aa.svg"
                    alt="Close"
                  />
                  Archive
                </MenuItem>

                <MenuItem
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "black",
                    cursor: "pointer",
                    fontFamily: "Inter",
                  }}
                  onClick={handleClose}
                >
                  <img
                    style={{ marginRight: "5px" }}
                    src="/assets/images/delete.svg"
                    alt="Close"
                  />{" "}
                  Delete
                </MenuItem>

                <MenuItem
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "black",
                    cursor: "pointer",
                    fontFamily: "Inter",
                  }}
                  onClick={handleClose}
                >
                  <img
                    style={{ marginRight: "5px" }}
                    src="/assets/images/delete.svg"
                    alt="Close"
                  />
                  Purge
                </MenuItem>
              </Menu>

              <Menu
                id="basic-menu-two"
                anchorEl={anchorElMenue}
                open={openIconMenu}
                onClose={handleCloseMenue}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "black",
                    cursor: "pointer",
                    // fontFamily: "Inter",
                  }}
                  onClick={handleCloseMenue}
                >
                  <img
                    style={{ marginRight: "5px" }}
                    src="/assets/images/pdf_icon.svg"
                    alt="Close"
                  />{" "}
                  View Statement
                </MenuItem>

                <MenuItem
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "black",
                    cursor: "pointer",
                    fontFamily: "Inter",
                  }}
                  onClick={handleCloseMenue}
                >
                  <img
                    style={{ marginRight: "5px" }}
                    src="/assets/images/client.svg"
                    alt="Close"
                  />
                  Client Portal
                </MenuItem>

                <MenuItem
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "black",
                    cursor: "pointer",
                    fontFamily: "Inter",
                    borderBottom: "1 solid #e5e7eb!important",
                  }}
                  onClick={handleCloseMenue}
                >
                  <img
                    style={{ marginRight: "5px" }}
                    src="/assets/images/sett.svg"
                    alt="Close"
                  />
                  Settings
                </MenuItem>
                <hr color="#e5e7eb" width="80%" />

                <MenuItem
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "black",
                    cursor: "pointer",
                    fontFamily: "Inter",
                  }}
                  onClick={handleCloseMenue}
                >
                  <img
                    style={{ marginRight: "5px" }}
                    src="/assets/images/plu.svg"
                    alt="Close"
                  />
                  New Invoice
                </MenuItem>

                <MenuItem
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "black",
                    cursor: "pointer",
                    fontFamily: "Inter",
                  }}
                  onClick={handleCloseMenue}
                >
                  <img
                    style={{ marginRight: "5px" }}
                    src="/assets/images/plu.svg"
                    alt="Close"
                  />
                  New Quote
                </MenuItem>
                <MenuItem
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "black",
                    cursor: "pointer",
                    fontFamily: "Inter",
                  }}
                  onClick={handleCloseMenue}
                >
                  <img
                    style={{ marginRight: "5px" }}
                    src="/assets/images/plu.svg"
                    alt="Close"
                  />
                  Enter Payment
                </MenuItem>

                <MenuItem
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "black",
                    cursor: "pointer",
                    fontFamily: "Inter",
                  }}
                  onClick={handleCloseMenue}
                >
                  <img
                    style={{ marginRight: "5px" }}
                    src="/assets/images/plu.svg"
                    alt="Close"
                  />
                  New Task
                </MenuItem>

                <MenuItem
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "black",
                    cursor: "pointer",
                    fontFamily: "Inter",
                  }}
                  onClick={handleCloseMenue}
                >
                  <img
                    style={{ marginRight: "5px" }}
                    src="/assets/images/plu.svg"
                    alt="Close"
                  />
                  New Expense
                </MenuItem>
                <hr color="#e5e7eb" width="80%" />

                <MenuItem
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "black",
                    cursor: "pointer",
                    fontFamily: "Inter",
                  }}
                  onClick={handleCloseMenue}
                >
                  <img
                    style={{ marginRight: "5px" }}
                    src="/assets/images/share.svg"
                    alt="Close"
                  />
                  Merge
                </MenuItem>

                <MenuItem
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "black",
                    cursor: "pointer",
                    fontFamily: "Inter",
                  }}
                  onClick={handleCloseMenue}
                >
                  <img
                    style={{ marginRight: "5px" }}
                    src="/assets/images/aa.svg"
                    alt="Close"
                  />
                  Archive
                </MenuItem>

                <MenuItem
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "black",
                    cursor: "pointer",
                    fontFamily: "Inter",
                  }}
                  onClick={handleCloseMenue}
                >
                  <img
                    style={{ marginRight: "5px" }}
                    src="/assets/images/delete.svg"
                    alt="Close"
                  />{" "}
                  Delete
                </MenuItem>

                <MenuItem
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "black",
                    cursor: "pointer",
                    fontFamily: "Inter",
                  }}
                  onClick={handleCloseMenue}
                >
                  <img
                    style={{ marginRight: "5px" }}
                    src="/assets/images/delete.svg"
                    alt="Close"
                  />
                  Purge
                </MenuItem>
              </Menu>

              <div
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  color: "#000000",

                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Stack style={{ marginRight: "70px", height: "80px" }}>
                  <Pagination
                    count={10}
                    shape="rounded"
                    classes={{ selected: classesPagination.selected }}
                  />
                </Stack>
                {/* initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection */}
              </div>
            </>
          )}
        </div>

        <div
          style={{
            width: showDivs ? "40%" : "0%",
            transition: "width 0.3s linear ",
            boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
            position: "absolute",
            right: "0",
            background: "white",
            minHeight: "100%",
            borderRadius: "2px",
          }}
        >
          <div
            style={{
              transition: "width 0.3s linear ",
              display: showDivs ? "block" : "none",
            }}
          >
           <div style={{display:"flex",justifyContent:"space-between",alignItems:'center',paddingRight:"30px"}}>
           <div
                style={{
                  display: "flex",
                 justifyContent:"start",
                  marginTop: "5px",
                  alignItems:'center',
                  columnGap:'10px'
                }}
              >
                <Button
                  // onClick={handleClosediv}
                  style={{
                    backgroundColor:'#F1F2F4',
                    border:'1px solid rgba(0, 0, 0, 0.18)',
                    width: '43px',
                    height: '43px',
                    borderRadius:'5px',
                    marginLeft:'6px',
                    color:'black',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    columnGap:'7px',
                    cursor:'pointer'
                  }}
                  size="small"
                >
                <ArrowBackIcon onClick={HendelShopLocationDialog}/>
                </Button>
                <p style={basicInfo} >Add product</p>
                 
              </div>

              <button className="saveButton">Save</button>
           </div>
           
            

            
           

            <Box sx={{ width: "100%" }}>
              <Box>
                <Tabs
                  TabIndicatorProps={{ style: { backgroundColor: "white" } }}
                  valueOther={valueTwo}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  style={{
                    marginTop: "15px",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.11)",
                  }}
                >
                  <Tab
                    style={{
                      padding: 0,
                      fontWeight: "600",
                      fontSize: "17px",
                      color: "black",
                      textTransform: "none",
                      borderBottom:
                        valueTwo === 0 ? "2px solid #00A1E4" : "none",
                      color: valueTwo === 0 ? "#00A1E4" : "black",
                      marginLeft: "20px",
                    }}
                    label={formatLabel("Basic")}
                    {...a11yProps(0)}
                  />
                  
                  <Tab
                    style={{
                      padding: 0,
                      marginLeft: "50px",
                      fontWeight: "600",
                      fontSize: "17px",
                      color: "black",
                      textTransform: "none",
                      borderBottom:
                        valueTwo === 1 ? "2px solid #00A1E4" : "none",
                      color: valueTwo === 1 ? "#00A1E4" : "black",
                    }}
                    label={formatLabel("Organization")}
                    {...a11yProps(1)}
                  />

                  <Tab
                    style={{
                      padding: 0,
                      marginLeft: "50px",
                      fontWeight: "600",
                      fontSize: "17px",
                      color: "black",
                      textTransform: "none",
                      borderBottom:
                        valueTwo === 2 ? "2px solid #00A1E4" : "none",
                      color: valueTwo === 2 ? "#00A1E4" : "black",
                    }}
                    label={formatLabel("Variations")}
                    {...a11yProps(2)}
                  /> 
                   <Tab
                    style={{
                      padding: 0,
                      marginLeft: "50px",
                      fontWeight: "600",
                      fontSize: "17px",
                      color: "black",
                      textTransform: "none",
                      borderBottom:
                        valueTwo === 3 ? "2px solid #00A1E4" : "none",
                      color: valueTwo === 3 ? "#00A1E4" : "black",
                    }}
                    label={formatLabel("Tags")}
                    {...a11yProps(3)}
                  /> 
                  
                </Tabs>
              </Box>
              {Anoutherloading ? (
                <CircularProgressWithLabel />
              ) : (
                <>
                  <TabPanel valueOther={valueTwo} i={0}>
                    {/* <div className={"boxTwo"}> */}
                    <div >
                      <div className={classes.root}>
                        <List component="ul" style={{overflowY:"scroll",
            height:"100vh",backgroundColor:"rgb(241, 242, 244)"}}>
                        <RichTextEditor quantity={quantity} setQuantity={setQuantity}  Sku={Sku} setSku={setSku}/>
                        </List>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel
                    // className={"boxTwoTab"}
                    valueOther={valueTwo}
                    i={2}
                    style={{}}
                  >
                    <div className={classes.root}>
                      <List component="ul"  style={{overflowY:"scroll",
            height:"100vh",backgroundColor:"rgb(241, 242, 244)" }}>
                      <VariationsPerent quantity={quantity} Sku={Sku}/>
                      </List>
                    </div>
                  </TabPanel>  
                    <TabPanel
                    // className={"boxTwo"}
                    valueOther={valueTwo}
                    i={1}
                    style={{}}
                  >
                    <div className={classes.root}>
                      <List component="ul"  style={{overflowY:"scroll",
            height:"100vh",backgroundColor:"rgb(241, 242, 244)"}}>
                     <ProductOrganization/>
                      </List>
                    </div>
                  </TabPanel>
                  <TabPanel
                    // className={"boxTwo"}
                    valueOther={valueTwo}
                    i={3}
                    style={{}}
                  >
                    <div className={classes.root}>
                      <List component="ul"  style={{overflowY:"scroll",
            height:"100vh",backgroundColor:"rgb(241, 242, 244)" }}>
                     <MetaTags/>
                      </List>
                    </div>
                  </TabPanel>
                  
                   
                </>
              )}
            </Box>
          </div>
        </div>

        <div
          className="drowTwo"
          style={{
            border: "1px solid rgba(0, 0, 0, 0.22)",
            width: showDivsTwo ? "40%" : "0%",
            transition: "width 0.3s linear ",
            position: "absolute",
            right: "0",
            background: "white",
            minHeight: "100%",
          }}
        >
          <Box
            style={{
              transition: "width 0.3s linear ",
              display: showDivsTwo ? "block" : "none",
            }}
          >
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                columnGap: "33px",
              }}
            >
              <Tabs
                TabIndicatorProps={{
                  style: { backgroundColor: "transparent", display: "none" },
                }}
                value={values}
                onChange={handleTabChange}
                aria-label="scrollable force tabs example"
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
              >
                <Tab
                  style={{
                    color: "#000000",
                    fontSize: "14px",
                    fontWeight: "600",
                    backgroundColor: values === 0 ? "#00A1E4" : "transparent",
                    color: values === 0 ? "white" : "black",
                    textTransform: "none",
                  }}
                  label={formatLabel("Summary")}
                  {...a11yPropss(0)}
                />
                <Tab
                  style={{
                    color: "#000000",
                    fontSize: "14px",
                    fontWeight: "600",
                    backgroundColor: values === 1 ? "#00A1E4" : "transparent",
                    color: values === 1 ? "white" : "black",
                    textTransform: "none",
                  }}
                  label={formatLabel("Invoice")}
                  {...a11yPropss(1)}
                />
                <Tab
                  style={{
                    color: "#000000",
                    fontSize: "14px",
                    fontWeight: "600",
                    backgroundColor: values === 2 ? "#00A1E4" : "transparent",
                    color: values === 2 ? "white" : "black",
                    textTransform: "none",
                  }}
                  label={formatLabel("Details")}
                  {...a11yPropss(2)}
                />
                <Tab
                  style={{
                    color: "#000000",
                    fontSize: "14px",
                    fontWeight: "600",
                    backgroundColor: values === 3 ? "#00A1E4" : "transparent",
                    color: values === 3 ? "white" : "black",
                    textTransform: "none",
                  }}
                  label={formatLabel("Projects")}
                  {...a11yPropss(3)}
                />
                <Tab
                  style={{
                    color: "#000000",
                    fontSize: "14px",
                    fontWeight: "600",
                    backgroundColor: values === 4 ? "#00A1E4" : "transparent",
                    color: values === 4 ? "white" : "black",
                    textTransform: "none",
                  }}
                  label={formatLabel("Tickets")}
                  {...a11yPropss(4)}
                />
              </Tabs>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "20px",
                }}
              >
                <IconButton
                  id="basic-button"
                  aria-controls={openIconMenu ? "basic-menu-two" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openIconMenu ? "true" : undefined}
                  onClick={handleClickMenue}
                >
                  <BsThreeDotsVertical style={{ color: "#000000" }} />
                </IconButton>
                <span
                  style={{ display: "inline-block" }}
                  onClick={handleClosedivTwo}
                >
                  <img src="/assets/images/cross.svg" alt="Close" />
                </span>
                {/* <span
                  icon={<img src="/assets/images/cross.svg"/>}
                  style={{ height: "30px", width: "30px" }}
                  onClick={handleClosedivTwo}
                /> */}
              </div>
            </Box>

            {loadingOther ? (
              <CircularProgressWithLabel />
            ) : (
              <>
                <TabPanelComponent className={"tab"} value={values} index={0}>
                  <span className={"box"}>
                    <div>
                      <div
                        style={{
                          textAlign: "center",
                          boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
                          marginBottom: "10px",
                          height: "100px",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                          marginTop: "5px",
                          marginBottom: "10px",
                        }}
                      >
                        <h2
                          style={{
                            fontWeight: "700",
                            fontSize: "20px",
                          }}
                        >
                          Lets do international Business BV
                        </h2>
                      </div>
                      <div
                        style={{
                          boxShadow: "rgba(0, 0, 0, 0.25) 0px 1px 2px",
                          padding: "0px 20px",
                          margin: "20px 0px",
                        }}
                      >
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "33% 33% 33%",
                            alignItems: "center",
                            justifyContent: "center",
                            columnGap: "3%",
                          }}
                        >
                          {Summary.map((item, index) => (
                            <div
                              key={index}
                              style={{
                                margin: "10px 0px",
                                textAlign: "center",
                                border: "1px solid rgba(0,0,0,.1)",
                                borderRadius: "7px",
                                paddingBottom: "25px",
                                height: "107px",
                              }}
                            >
                              <h2
                                style={{
                                  marginTop: "9px",
                                  marginBottom: "2px",
                                  fontSize: "1.5rem",
                                  fontWeight: "700",
                                  color: "black",
                                }}
                              >
                                {item.point}{" "}
                              </h2>
                              <span
                                style={{ fontSize: "14px", fontWeight: "400" }}
                              >
                                {item.des}
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div
                        style={{
                          boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
                          padding: "30px 20px 40px 20px",
                          display: "grid",
                          gridTemplateColumns: "35% 35%",
                          columnGap: "70px",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <p
                            style={{
                              fontWeight: "400",
                              fontSize: "14px",
                            }}
                          >
                            Net revenue
                            <br />
                            Debt excluded net sales total
                          </p>
                          <span style={{ fontWeight: "700", fontSize: "26px" }}>
                            20,000$
                          </span>
                          <p
                            style={{
                              fontWeight: "400",
                              fontSize: "14px",
                            }}
                          >
                            These figures here are based on net
                            <br />
                            sales total excluded debt
                          </p>
                        </div>
                        <div>
                          <p
                            style={{
                              fontWeight: "400",
                              fontSize: "14px",
                            }}
                          >
                            Gross revenue
                            <br />
                            Debt excluded net sales total
                          </p>
                          <span style={{ fontWeight: "700", fontSize: "26px" }}>
                            20,000$
                          </span>
                          <p
                            style={{
                              fontWeight: "400",
                              fontSize: "14px",
                            }}
                          >
                            These figures here are based on net
                            <br />
                            sales total excluded debt
                          </p>
                        </div>
                      </div>

                      <div
                        style={{
                          boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
                          padding: "30px 20px 40px 20px",
                          marginTop: "20px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            columnGap: "10px",
                            alignItems: "center",
                            paddingBottom: "20px",
                            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                          }}
                        >
                          <MdHomeFilled />
                          <span style={{ fontWeight: "700", fontSize: "14px" }}>
                            Contact Info{" "}
                          </span>
                        </div>

                        <ul>
                          <Grid container spacing={2}>
                            <Grid
                              item
                              xs={4}
                              style={{
                                fontSize: "14px",
                                fontWeight: "400",
                                color: " rgba(0, 0, 0, 0.76)",
                                listStyleType: "none",
                                paddingLeft: "0px",
                              }}
                            >
                              <li style={{ paddingBottom: "10px" }}>
                                Company name :
                              </li>
                              <li style={{ paddingBottom: "10px" }}>
                                Address :
                              </li>
                              <li style={{ paddingBottom: "10px" }}>
                                VAT No :
                              </li>
                              <li style={{ paddingBottom: "10px" }}>
                                Customer Id :
                              </li>
                              <li style={{ paddingBottom: "10px" }}>
                                Phone No :
                              </li>
                            </Grid>
                            <Grid
                              item
                              xs={8}
                              style={{
                                fontSize: "14px",
                                fontWeight: "400",
                                color: " rgba(0, 0, 0, 0.76)",
                                listStyleType: "none",
                              }}
                            >
                              <li style={{ paddingBottom: "10px" }}>
                                Let’s do business international BV
                              </li>
                              <li style={{ paddingBottom: "10px" }}>
                                Let’s do business international BV
                              </li>
                            </Grid>
                          </Grid>
                        </ul>
                      </div>
                      <div
                        style={{
                          boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
                          padding: "30px 20px 40px 20px",
                          marginTop: "20px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            columnGap: "10px",
                            alignItems: "center",
                            paddingBottom: "20px",
                            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                          }}
                        >
                          <MdHomeFilled />
                          <span style={{ fontWeight: "700", fontSize: "14px" }}>
                            Contact Info{" "}
                          </span>
                        </div>

                        <ul>
                          <Grid container spacing={2}>
                            <Grid
                              item
                              xs={4}
                              style={{
                                fontSize: "14px",
                                fontWeight: "400",
                                color: " rgba(0, 0, 0, 0.76)",
                                listStyleType: "none",
                                paddingLeft: "0px",
                              }}
                            >
                              <li style={{ paddingBottom: "10px" }}>
                                Company name :
                              </li>
                              <li style={{ paddingBottom: "10px" }}>
                                Address :
                              </li>
                              <li style={{ paddingBottom: "10px" }}>
                                VAT No :
                              </li>
                              <li style={{ paddingBottom: "10px" }}>
                                Customer Id :
                              </li>
                              <li style={{ paddingBottom: "10px" }}>
                                Phone No :
                              </li>
                            </Grid>
                            <Grid
                              item
                              xs={8}
                              style={{
                                fontSize: "14px",
                                fontWeight: "400",
                                color: " rgba(0, 0, 0, 0.76)",
                                listStyleType: "none",
                              }}
                            >
                              <li style={{ paddingBottom: "10px" }}>
                                Let’s do business international BV
                              </li>
                              <li style={{ paddingBottom: "10px" }}>
                                Let’s do business international BV
                              </li>
                            </Grid>
                          </Grid>
                        </ul>
                      </div>
                    </div>
                  </span>
                </TabPanelComponent>
                <TabPanelComponent className={"tab"} value={values} index={1}>
                  <InvoicesComponent />
                </TabPanelComponent>
                <TabPanelComponent
                  className={"Othertab"}
                  value={values}
                  index={2}
                >
                  <span className={"box"}>
                    {Details.map((item, index) => (
                      <div key={index} style={shadowStyle}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                            columnGap: "2%",
                            marginLeft: "12px",
                            paddingBottom: "10px",
                            borderBottom: "1px solid rgba(0,0,0,.1)",
                          }}
                        >
                          <img src="/assets/images/home.svg" />
                          <p style={{ fontSize: "14px", fontWeight: "700" }}>
                            {item.title}
                          </p>
                        </div>
                        <div>
                          <ul
                            style={{
                              paddingLeft: "42px",
                              marginTop: "0px",
                              paddingBottom: "15px",
                            }}
                          >
                            {Object.entries(item).map(
                              ([key, value]) =>
                                key !== "title" && (
                                  <li
                                    key={key}
                                    style={{
                                      display: "flex",
                                      justifyContent: "start",
                                      alignItems: "center",
                                      columnGap: "15%",
                                      color: "rgba(0,0,0,.76)",
                                      paddingTop: "10px",
                                    }}
                                  >
                                    <div style={{ width: "30%" }}>
                                      <strong
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: "400",
                                        }}
                                      >
                                        {key}
                                      </strong>
                                    </div>
                                    <div>
                                      <span
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: "400",
                                        }}
                                      >
                                        {value}
                                      </span>
                                    </div>
                                  </li>
                                )
                            )}
                          </ul>
                        </div>
                      </div>
                    ))}
                  </span>
                </TabPanelComponent>
                <TabPanelComponent value={values} index={3}>
                  Projects
                </TabPanelComponent>
                <TabPanelComponent value={values} index={4}>
                  Tickets
                </TabPanelComponent>
              </>
            )}
          </Box>
        </div>
      </div>

      <div className={"mobilVied"}>
        {loading ? (
          <CircularProgressWithLabel />
        ) : (
          <TableContainer
            style={{ margin: "150px 0px 90px 0px" }}
            component={Paper}
          >
            <Table>
              <TableBody className={"scroolDemMobile"}>
                {
                departmentsData.map((item, index) => (
                  <TableRow key={index} style={{ border: "none!important" }}>
                    <TableCell
                      align="left"
                      style={{ padding: "0px", paddingLeft: "10px" }}
                    >
                      <IconButton
                        style={{ fontSize: "17px", fontWeight: "2000" }}
                        id="basic-button"
                        aria-controls={openIcon ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={openIcon ? "true" : undefined}
                        onClick={handleClick}
                      >
                        <BsThreeDotsVertical />
                      </IconButton>
                    </TableCell>
                    <TableCell
                      onClick={() => navigate("/company-details")}
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        padding: "10px 0px",
                      }}
                      align="left"
                    >
                      {item.number}
                      <br />
                      <span style={{ fontSize: "13px", fontWeight: "400" }}>
                        {item.name}
                      </span>
                    </TableCell>

                    <TableCell
                      onClick={() => navigate("/company-details")}
                      align="left"
                      style={{ padding: "10px 10px 10px 30px " }}
                    >
                      {item.status === "Complete" ? (
                        <span style={{ color: "#07A934", fontSize: "14px" }}>
                          "Complete"
                        </span>
                      ) : item.status === "Working on" ? (
                        <span style={{ color: "#CE0535", fontSize: "14px" }}>
                          "Working on"
                        </span>
                      ) : item.status === "Backlog" ? (
                        <span style={{ color: "#00B8C4", fontSize: "14px" }}>
                          "Backlog"
                        </span>
                      ) : item.status === "Open" ? (
                        <span style={{ color: "#00B8C4", fontSize: "14px" }}>
                          "Open"
                        </span>
                      ) : (
                        ""
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>



       {/* addShopLocation */}
       <Dialog open={addShopLocation} onClose={handleCloseShopLocationDialog}>
        <div style={{display:"grid",gridTemplateColumns:'90% 10%',columnGap:'10px',alignItems:'center',borderBottom:'1px solid #898F94'}}>
        <DialogTitle
          style={{
            width: "500px",
            fontSize: "17px",
            fontWeight: "700",
      
       
          }}
        >
          Leave page with unsaved changes ?
         
        </DialogTitle>

        <ClearIcon onClick={handleCloseShopLocationDialog}/>

        </div>
     
        
        <DialogContent   style={{
            fontSize: "14px",
            fontWeight: "400",
            marginBottom: "10px",
            borderBottom: "1px solid #898F94",
          }}> Leaving this page will delete all unsaved changes.
         
          

        </DialogContent>
        
        <form style={{ display: "flex", justifyContent: "end", gap: "20px",paddingBottom:'15px',paddingRight:'20px' }}>

<Button
    style={{
      width: "30px",
      height: "30px",
      fontSize: "14px",
      color: "black",
      borderRadius: "5px",
      border:'1px solid black',
      backgroundColor: 'white',
      textTransform: "none",
    }}
    variant="contained"
    onClick={handleCloseShopLocationDialog}
  >
    Stay
  </Button>


  <Button
    style={{
      width: "99px",
      fontSize: "14px",
      height: "30px",
      color: "white",
      backgroundColor: 'red',
      border: "none",
      borderRadius: "5px",
      textTransform: "none",
    }}
    onClick={HendelLeaveThisPage}
  >
    Leave page
  </Button>
 
</form>
      </Dialog>
    </>
  );
}
