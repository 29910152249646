import React, {useCallback, useState, useE, useEffect} from 'react';
import {useDropzone} from 'react-dropzone';




const e = React.createElement

function FileAndImageUpload ({file,setUploadProduct}) {
  // const [files, setFiles] = useState([file]);
  // const onDrop = useCallback(files => setFiles(files), [setFiles]);

  // const {getRootProps, getInputProps} = useDropzone({onDrop});

  // const fileList = files.map(
  //   file => React.createElement('li', {key: file?.name}, `${file?.name} - ${file?.size} bytes`)
  // );
  // // 
  // setUploadProduct(fileList)

  const [files, setFiles] = useState([]);
  const {getRootProps, getInputProps} = useDropzone({
    accept: {
      'image/*': []
    },
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
      setUploadProduct(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })))
    }
  });
  


  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach(file => URL.revokeObjectURL(file.preview));
  }, []);
 
  return (
    // <section className="container">
    //   <div className="dropzone" {...getRootProps()}>
    //     <input {...getInputProps()} />
    //     <label style={{cursor:'pointer',backgroundColor:'#F0F5FD',color:'blue',padding:'10px 20px',borderRadius:'5px',fontSize:'14px',
    // fontWeight:'400',
    // alignItems:'center',textAlign:'center',border:'none'}} >
    //         Select File
    //       </label>
    //   </div>
    //   {/* <aside>
   
    //     <ul>{fileList}</ul>
    //   </aside> */}
    // </section>
    <section className="container">
      <div {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()} />
        <label style={{cursor:'pointer',backgroundColor:'#F0F5FD',color:'blue',padding:'10px 20px',borderRadius:'5px',fontSize:'14px',fontWeight:'400',alignItems:'center',textAlign:'center',border:'none'}} >
            Select File
          </label>
      </div>
    
    </section>
  );
  
}
export default FileAndImageUpload;
