import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import ProductCatagory from './Catagory';

const filter = createFilterOptions();
export default function ProductOrganization() {
  const [Statusvalue, setValue] = React.useState(null);

    const StatusArray = [
        { label: 'Active'},
        { label: 'Draft'},
      
      ];
      const [typeValue, setTypeValue] = React.useState(null);
      const TypeProduct = [
        { prodType: 'type1'},
        { prodType: 'type2'},
      
      ];

  const ProdALLCatagory = [
  { catagory: 'catagoryA', subcategories: ["subA", "subB", "SubC"] },
  { catagory: 'catagoryB', subcategories: ["subD", "subE", "SubF"] },
  { catagory: 'catagoryC', subcategories: ["subG", "subH", "SubI"] },
  { catagory: 'catagoryD', subcategories: ["subJ", "subK", "SubL"] },
  { catagory: 'catagoryE', subcategories: ["subM", "subN", "SubO"] },
  { catagory: 'catagoryF', subcategories: ["subP", "subQ", "SubR"] },
  { catagory: 'catagoryG', subcategories: [] },
  { catagory: 'catagoryH', subcategories: ["subS", "subT", "SubU"] },
  { catagory: 'catagoryJ', subcategories: ["subV", "subW", "SubX"] },
  { catagory: 'catagoryK', subcategories: [] }
];
      
  return (
    <div style={{ backgroundColor: "#F1F2F4", padding: "20px" }}>

<div
        style={{
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          borderRadius: "10px",
        }}
      >
        <label
          style={{
            padding: "20px 0px",
            fontSize: "17px",
            fontWeight: "700",
            paddingBottom: "10px",
          }}
          htmlFor="title"
        >
          Status
        </label>

        {/* <Autocomplete style={{marginTop:"10px"}}
      disablePortal
      options={StatusArray}
      getOptionLabel={(option) => option.label}
      value={selectedStatus}
      onChange={(event, newValue) => {
        setSelectedStatus(newValue);
      }}
      renderInput={(params) => <TextField {...params} label="Active" />}
    /> */}
 <Autocomplete
      value={Statusvalue}
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          setValue({
            label: newValue,
          });
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          setValue({
            label: newValue.inputValue,
          });
        } else {
          setValue(newValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.label);
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            label: `Add "${inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="free-solo-with-text-demo"
      options={StatusArray}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.label;
      }}
      renderOption={(props, option) => <li {...props}>{option.label}</li>}
     
      freeSolo
      renderInput={(params) => (
        <TextField {...params} label="select status" />
      )}
    />

    
       
       
      </div>
      
      
      <div
        style={{
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          borderRadius: "10px",
          marginTop:"20px"
        }}
      >
        <label
          style={{
            padding: "20px 0px",
            fontSize: "17px",
            fontWeight: "700",
            paddingBottom: "10px",
          }}
          htmlFor="title"
        >
          Product Organization
        </label>



        <label
          style={{
            padding: "20px 0px",
            fontSize: "14px",
            fontWeight: "400",
            paddingBottom: "10px",
          }}
          htmlFor="title"
        >
          Product type
        </label>
        <Autocomplete style={{zIndex:'1'}}
      value={typeValue}
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          setTypeValue({
            prodType: newValue,
          });
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          setTypeValue({
            prodType: newValue.inputValue,
          });
        } else {
          setTypeValue(newValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.prodType);
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            prodType: `Add "${inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="free-solo-with-text-demo"
      options={TypeProduct}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.prodType;
      }}
      renderOption={(props, option) => <li {...props}>{option.prodType}</li>}
   
      freeSolo
      renderInput={(params) => (
        <TextField {...params} label="Select type" />
      )}
    />
         <label
          style={{
            padding: "20px 0px",
            fontSize: "14px",
            fontWeight: "400",
            paddingBottom: "10px",
          }}
          htmlFor="title"
        >
          Product category
        </label>

      <ProductCatagory style={{backgroundColor:'red'}}/>


{/* 
        <Autocomplete style={{paddingTop:"5px"}}
      disablePortal
      id="combo-box-demo"
      options={TypeProduct}
      
      renderInput={(params) => <TextField {...params} label="Movie" />}
    /> */}
       
       
      </div>
    </div>
  )
}
