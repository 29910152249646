import React, { useState } from "react";
import Select from "react-select";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import './Catagory.css';
const ProdALLCatagory = [
  {
    catagory: "catagoryA",
    subcategories: [
      {
        subName: "PrentSubA",
        subSubcategories: ["ChildSubA", "ChildSubB", "ChildSubC"],
      },
    ],
  },
  {
    catagory: "catagoryB",
    subcategories: [
      {
        subName: "PrentSubB",
        subSubcategories: ["ChildSubD", "ChildSubE", "ChildSubF"],
      },
    ],
  },
  {
    catagory: "catagoryC",
    subcategories: [
      { subName: "subG", subSubcategories: [] },
      { subName: "subH", subSubcategories: [] },
      { subName: "SubI", subSubcategories: [] },
    ],
  },
  {
    catagory: "catagoryD",
    subcategories: [
      { subName: "subJ", subSubcategories: [] },
      { subName: "subK", subSubcategories: [] },
      { subName: "SubL", subSubcategories: [] },
    ],
  },
  {
    catagory: "catagoryE",
    subcategories: [
      {
        subName: "PrentSubE",
        subSubcategories: ["ChildSubM", "ChildSubN", "ChildSubO"],
      },
    ],
  },
  {
    catagory: "catagoryF",
    subcategories: [
      { subName: "subP", subSubcategories: [] },
      {
        subName: "subQ",
        subSubcategories: ["ChildSubD", "ChildSubE", "ChildSubF"],
      },
      { subName: "SubR", subSubcategories: [] },
    ],
  },
  { catagory: "catagoryG", subcategories: [] },
  {
    catagory: "catagoryH",
    subcategories: [
      { subName: "subJ", subSubcategories: [] },
      { subName: "subK", subSubcategories: [] },
      { subName: "SubL", subSubcategories: [] },
    ],
  },
  ,
  { catagory: "catagoryJ", subcategories: ["subV", "subW", "SubX"] },
  { catagory: "catagoryK", subcategories: [] },
];

const ProductCatagory = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  // console.log("selectedCategory");
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  // console.log("selectedSubcategory", selectedSubcategory);
  const [selectedSubSubcategory, setselectedSubSubcategory] = useState(null);
  // console.log("selectedSubSubcategory", selectedSubSubcategory);
  const [isSubcategorySelected, setIsSubcategorySelected] = useState(false);
  const [showSubSubcategories, setShowSubSubcategories] = useState(false);

  const handleArrowClick = (category) => () => {
    // console.log("category", category);
    if (selectedCategory === null) {
      setSelectedCategory(category);
    } else {
      if (selectedCategory === category) {
        setSelectedCategory(null);
      } else {
        setSelectedCategory(category);
      }

      setIsSubcategorySelected(true);
      setSelectedSubcategory(category?.subcategories?.subName); // Reset selected subcategory when clicking on category
      setShowSubSubcategories(false); // Reset showSubSubcategories when clicking on category
    }
    // console.log("1");
  };

  const handleSubcategoryClick = (subcategory) => () => {
    // console.log("subcategory", subcategory);
    if (selectedSubcategory === null) {
      setSelectedSubcategory(subcategory);
      setShowSubSubcategories(true);
    } else {
      if (selectedSubcategory === subcategory) {
        setSelectedSubcategory(null);
        setShowSubSubcategories(false);
      } else {
        setSelectedSubcategory(subcategory);
        setShowSubSubcategories(true);
      }

      setIsSubcategorySelected(true);
      setSelectedSubcategory(null); // Reset selected subcategory when clicking on category
      setShowSubSubcategories(false); // Reset showSubSubcategories when clicking on subcategory
    }

    setSelectedSubcategory(subcategory);
    setShowSubSubcategories(!showSubSubcategories); // Reset showSubSubcategories when clicking on subcategory
    // console.log("2");
  };

  const handleSubSubcategoryClick = (subSubcategory) => () => {
    setselectedSubSubcategory(subSubcategory);
    setIsSubcategorySelected(false);
    setShowSubSubcategories(false); // Reset showSubSubcategories when clicking on subcategory
  };

  const handleTypeSelect = (selectedOption) => {
    if (selectedOption) {
      const { catagory, subcategories } = selectedOption;
      setSelectedCategory(catagory);
      setSelectedSubcategory(
        subcategories.length > 0 ? subcategories[0] : null
      );
      setIsSubcategorySelected(false);
      setShowSubSubcategories(false); // Reset showSubSubcategories when selecting a new option
    } else {
      setSelectedCategory(null);
      setSelectedSubcategory(null);
      setIsSubcategorySelected(false);
      setShowSubSubcategories(false); // Reset showSubSubcategories when selecting no option
    }
  };

  const selectedValues = selectedCategory
    ? {
        catagory: selectedCategory,
        subcategories: selectedSubcategory
          ? [selectedSubcategory?.subName]
          : [],
        subSubcategoriy: selectedSubSubcategory ? [selectedSubSubcategory] : [],
      }
    : null;

  console.log("selectedValues", selectedValues);
  const customOptionRenderer = ({ data, ...props }) => (
    <div style={{ padding: "20px 30px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          border: "0.6px solid rgb(211, 206, 206)",
          padding: "7px",
          borderRadius: "6px",
        }}
      >
        <p
          style={{ fontSize: "17px", fontWeight: "700", margin: "0px" }}
          onClick={handleArrowClick(data.catagory)}
        >
          {data.catagory}
        </p>
        {data.subcategories.length > 0 && (
          <div onClick={handleArrowClick(data.catagory)}>
            {selectedCategory === data.catagory ? (
              <KeyboardArrowUp />
            ) : (
              <KeyboardArrowDown />
            )}
          </div>
        )}
      </div>

      {selectedCategory === data.catagory && data.subcategories.length > 0 && (
        <div style={{margin:'10px'}}>
          {data.subcategories.map((subcategory, i) => (
            <div key={i}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  border: "0.6px solid rgb(211, 206, 206)",
                  // padding: "5px",
                  borderRadius: "6px",
                  paddingRight:'5px'
                }}
              >
                <p
                  style={{
                    fontSize: "15px",
                    fontWeight: "400",
                    margin: "5px",
                    // border: "0.6px solid rgb(211, 206, 206)",
                    padding: "5px",
                    // borderRadius: "6px",
                  }}
                  onClick={handleSubcategoryClick(subcategory)}
                >
                  {subcategory?.subName}
                </p>
                 <div  onClick={handleSubcategoryClick(subcategory)}>
                 {subcategory.subSubcategories.length > 0 &&
                showSubSubcategories ? (
                  <KeyboardArrowUp />
                ) : (
                  <KeyboardArrowDown />
                )}
                 </div>
               
              </div>

              {showSubSubcategories && selectedSubcategory === subcategory && (
                <div>
                  {console.log("true")}
                  {subcategory.subSubcategories.map((subSubcategory, index) => (
                    <p
                      key={index}
                      style={{
                        fontSize: "13px",
                        fontWeight: "300",
                        margin: "10px",
                        border: "0.6px solid rgb(211, 206, 206)",
                        padding: "7px",
                        borderRadius: "6px",
                      }}
                      onClick={handleSubSubcategoryClick(subSubcategory)}
                    >
                      {subSubcategory}
                    </p>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );

  // const selectedLabel = selectedValues
  
  //  ? `${selectedValues.catagory} - ${
  //       selectedValues.subcategories? selectedValues.subcategories : "Select a subcategory"
  //     } - ${selectedValues.subSubcategoriy ?selectedValues.subSubcategoriy: "Select a subsubcategory"}`
  //   : "";

    // const selectedLabel = selectedValues
    // ? `${selectedValues.catagory} - ${
    //     selectedValues.subcategories ? selectedValues.subcategories[0]?.subName || "Select a subcategory" : "Select a subcategory"
    //   } - ${
    //     selectedValues.subcategories ? selectedValues.subcategories[0]?.subSubcategories[0] || "Select a subsubcategory" : "Select a subsubcategory"
    //   }`
    // : "";
  


    const selectedLabel = selectedValues
  ? `${selectedValues.catagory} - ${
      selectedValues.subcategories && selectedValues.subcategories.length > 0
        ? selectedValues.subcategories 
        : " "
    } - ${
      selectedValues.subSubcategoriy && selectedValues.subSubcategoriy.length > 0
        ? selectedValues.subSubcategoriy
        : ""
    }`
  : "";

    

 
  return (
    <div>
      <Select
        menuIsOpen={isSubcategorySelected}
        onMenuOpen={() => setIsSubcategorySelected(true)}
        onMenuClose={() => setIsSubcategorySelected(false)}
        options={ProdALLCatagory}
        onChange={handleTypeSelect}
        getOptionLabel={(option) => option.catagory}
        components={{ Option: customOptionRenderer }}
        value={selectedValues}
        // styles={customStyles}
      />
      {selectedLabel ? (
        <p style={{ fontSize: "14px", fontWeight: "400" }}>
          Selected subcategories:{" "}
          <span style={{ fontSize: "17px", fontWeight: "700" }}>
            {selectedLabel}
          </span>
        </p>
      ) : (
        ""
      )}
    </div>
  );
};

export default ProductCatagory;
