

import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import { Menu } from "@mui/material";
export default function StatusFilter({anchor,setAnchor,status,setselectedStatusFromUser,selectedStatusFromUser}) {

  const [checked, setChecked] = React.useState([false, false]);
  const [selectedStatus, setSelectedStatus] = React.useState([]);
  // console.log("selectedStatus",selectedStatus)

  useEffect(()=>{
    setselectedStatusFromUser(selectedStatus)
  },[selectedStatus]);
    useEffect(()=>{
      if(selectedStatusFromUser.length<=0){
       setChecked([false, false]);
      }
  
  },[selectedStatusFromUser])
 
  const open = Boolean(anchor);
  // console.log("status",status);

  const handleChange1 = (event) => {
    const isChecked = event.target.checked;
    setChecked([isChecked, isChecked]);
    setSelectedStatus(isChecked ? status : status);
    setAnchor(null);
  };

  const handleChange2 = (event) => {
    const isChecked = event.target.checked;
    setChecked([isChecked, checked[1]]);
    setSelectedStatus(isChecked ? [status[0], ...selectedStatus] : selectedStatus.filter(item => item !== status[0]));
    setAnchor(null);
  };

  const handleChange3 = (event) => {
    const isChecked = event.target.checked;
    setChecked([checked[0], isChecked]);
    setSelectedStatus(isChecked ? [status[1], ...selectedStatus] : selectedStatus.filter(item => item !== status[1]));
    setAnchor(null);
  };

  const handleClose = () => {
    setAnchor(null);
    setChecked([false, false]);
    setSelectedStatus([]);
  };
  const Status=status.map((item)=>{
    if(item === "Complete"){
      return "Active"
    }
    else if(item === "pending"){
      return "Draft"
    }
    
  
  })

 
  const children = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
      <FormControlLabel className={'checkboxItem'}
        label={Status[0]}
        control={<Checkbox checked={checked[0]} onChange={handleChange2} />}
     
      />
      <FormControlLabel className={'checkboxItem'}
        label={Status[1]}
        control={<Checkbox checked={checked[1]} onChange={handleChange3} />}
      />
    </Box>
  );

  return (
    <Menu
    id="lock-menu"
    anchorEl={anchor}
    open={open}
    PaperProps={{
      style: {
        borderRadius:"20px", boxShadow:'5px 10px 8px #888888',
        marginTop: '34px', // Adjust the value as needed
      },
    }}
    anchorPosition={{ top: 50, left: 10 }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}

   
  >

<div  style={{width:'223px',height:'auto'}}>
    <div style={{padding:'30px 20px 10px 30px',alignItems:'center',textAlign:'center'}}>
      <FormControlLabel
        label="Select all Status"
        control={
          <Checkbox
            checked={checked[0] && checked[1]}
            indeterminate={checked[0] !== checked[1]}
            onChange={handleChange1}
          />
        }
      />
      {children}

    </div>
  
  
   
     
      

     
      <Button  onClick={handleClose} style={{backgroundColor:'red',color:'white',borer:'none',padding:'2px',marginBottom:'14px',marginLeft:'49px'}}>Clear</Button>
     

   
  </div>
  </Menu>

  
  );
}
