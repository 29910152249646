const departments = [

  {
    name: "100% EUROPEAN LINEN THROW",
    inventory: "2 in stock for 1 variant",
    type: "Throws",
    vendor: "Linger Home , Inc-Faire",
  
    status: "Complete",
  },
    {
    name: "100% EUROPEAN LINEN THROW",
    inventory: "2 in stock for 1 variant",
    type: "Throws",
    vendor: "Linger Home , Inc-Faire",
  
    status: "pending",
  },  {
    name: "100% EUROPEAN LINEN THROW",
    inventory: "2 in stock for 1 variant",
    type: "Throws",
    vendor: "Linger Home , Inc-Faire",
  
    status: "pending",
  },
  {
    name: "100% ORGANIC KNIT THROW",
    inventory: "3 in stock for 1 variant",
    type: "Throws",
    vendor: "Linger Home , Inc-Faire",
 
    status: "Complete",
  },
  {
    name: "7 OLIV TREE",
    inventory: "1 in stock for 1 variant",
    type: "Decorative Accents",
    vendor: "Replica Plants",
 
    status: "Complete",
  },
  {
    name: "9 FIDDLE LEAF FIG TREE",
    inventory: "1 in stock for 1 variant",
    type: "Decorative Accents",
    vendor: "Replica Plants",
   
    status: "Complete",
  },
  {
    name: "Laura Martinez",
    inventory:"1 in stock for 1 variant",
    type: "Decorative Accents",
    vendor: "Replica Plants",

    status: "Complete",
  },

  {
    name: "Sophia Rodriguez",
    inventory: "1 in stock for 1 variant",
    type: "Decorative Accents",
    vendor: "Replica Plants",

    status: "pending",
  },

  {
    name: "Emma Clark",
    inventory: "1 in stock for 1 variant",
    type: "Decorative Accents",
    vendor: "Replica Plants",
  
    status: "Complete",
  },
  {
    name: "Ava Walker",
    inventory: "1 in stock for 1 variant",
    type: "Decorative Accents",
    vendor: "Replica Plants",

    status: "pending",
  },
  {
    name: "Isabella White",
    inventory: "1 in stock for 1 variant",
    type: "Decorative Accents",
    vendor: "Replica Plants",
 
    status: "pending",
  },
  {
    name: "Stephanie Nguyen",
    inventory: "1 in stock for 1 variant",
    type: "Decorative Accents",
    vendor: "Replica Plants",
   
    status: "pending",
  },
  {
    name: "Andrew Thompson",
    inventory: "Product Development Manager",
    type: "Decorative Accents",
    vendor: "Replica Plants",

    status: "Complete",
  },
  {
    name: "Benjamin Mitchell",
    inventory: "Finance Director",
    type: "Olivia Davis",
    vendor: "Human Resources Manager",
 
    status: "Complete",
  },
  {
    name: "Grace Turner",
    inventory: "Customer Support Specialist",
    type: "Samuel Harris",
    vendor: "Software Developer",

    status: "Complete",
  },

  {
    name: "Qngelica Ramos",
    inventory: "Qhief Executive Officer (CEO)",
    type: "Qngelica Ramos",
    vendor: "Qhief Executive Officer (CEO)",
   
    status: "Complete",
  },
  {
    name: "Rngelica Ramos",
    inventory: "Rhief Executive Officer (CEO)",
    type: "Rngelica Ramos",
    vendor: "Rhief Executive Officer (CEO)",
    
    status: "Complete",
  },
  {
    name: "Sngelica Ramos",
    inventory: "Shief Executive Officer (CEO)",
    type: "Sngelica Ramos",
    vendor: "Shief Executive Officer (CEO)",
  
    status: "Complete",
  },

  {
    name: "Victoria Martin",
    inventory: "Public Relations Manager",
    type: "Daniel Clark",
    vendor: "Business Analyst",

 
    status: "pending",
  },

  {
    name: "Sophie Adams",
    inventory: "Graphic Designer",
    type: "Nathan Walker",
    vendor: "IT Manager",
   
    status: "pending Complete",
  },

  {
    name: "Oliver Smith",
    inventory: "Operations Supervisor",
    type: "Emma Johnson",
    vendor: "Sales Representative",

    status: "Complete",
  },

  {
    name: "William Martinez",
inventory: "Marketing Specialist",
type: "Mia Turner",
vendor: "Research Assistant",
   
    status: "Complete",
  },
  {
    name: "Charlotte Rodriguez",
    inventory: "Project Coordinator",
    type: "James Thompson",
    vendor: "Software Engineer",
 
    status: "Complete",
  },
  {
    name: "Harper Davis",
    inventory: "Business Development Coordinator",
    type: "Alexander Anderson",
    vendor: "Customer Service Manager",

    status: "Complete",
  },
  {
    name: "Lily Wilson",
    inventory: "Marketing Assistant",
    type: "Ethan Turner",
    vendor: "Sales Executive",
  
    status: "Complete",
  },

  {
    name: "Christopher Harris",
    inventory: "Operations Coordinator",
    type: "Abigail Roberts",
    vendor: "Human Resources Assistant",
 
    status: "Complete",
  },
  {
    name: "Sofia Thompson",
    inventory: "Customer Success Specialist",
    type: "Henry Davis",
    vendor: "IT Technician",

    status: "Complete",
  },
  {
    name: "Mila Anderson",
    inventory: "Product Analyst",
    type: "Jack Mitchell",
    vendor: "Finance Manager",

    status: "Complete",
  },

  {
    name: "Evelyn Turner",
    inventory: "Public Relations Coordinator",
    type: "Thomas Adams",
    vendor: "Business Development Specialist",

    status: "Complete",
  },
  {
    name: "Lucas Martinez",
    inventory: "Quality Assurance Analyst",
    type: "Sophia Johnson",
    vendor: "Operations Manager",
 
    status: "Complete",
  },

];

export default departments;