import React, { useState } from "react";
import Tab from "@mui/material/Tab";
import { MdOutlineCancel } from "react-icons/md";
import { MdOutlineCloudUpload } from "react-icons/md";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
} from "@mui/material";
import { Box } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Stack from "@mui/material/Stack";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import Typography from '@mui/material/Typography';
import PropTypes from "prop-types";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TopCustomerList({topCustomerData}) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const formatLabel = (label) => {
    const formattedLabel =
      label.charAt(0).toUpperCase() + label.slice(1).toLowerCase();
    return formattedLabel;
  };


const Data=topCustomerData

  const [rows, setRows] = React.useState(Data);
  console.log("fgr4uhg0",rows);
  const [editedRow, setEditedRow] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [deletDialogOpen, setDeletDialogOpen] = useState(false);
  const handleEditClick = (row) => {
    setEditDialogOpen(true);
    setEditedRow(row);
    console.log("editedRow",editedRow);
  };

  // dlt
   const handleDltClick = (id) => {
    setDeletDialogOpen(true);
    setSelectedRowId(id);
   
  };
  const handleCloseClickDlt = () => {
    // Close the edit dialog without saving
    setDeletDialogOpen(false);
    setSelectedRowId(null);
  };
  const deletById = (id) => {

    const updatedRow = rows.filter((row) => row.id !== id);
    setRows(updatedRow);
    setDeletDialogOpen(false);
  };

  const handleSaveClick = () => {
    // Find the index of the edited row in the rows array
    const editedRowIndex = rows.findIndex((row) => row.id === editedRow.id);
    if (editedRowIndex !== -1) {
      // Create a new array with the updated row
      const updatedRows = [
        ...rows.slice(0, editedRowIndex),
        editedRow,
        ...rows.slice(editedRowIndex + 1),
      ];
      // Update the rows state
      setRows(updatedRows);
    }

    // Close the edit dialog
    setEditDialogOpen(false);
  };

  const handleCloseClick = () => {
    // Close the edit dialog without saving
    setEditDialogOpen(false);
  }; 


  const copyRowById = (id) => {
    const rowToCopy = rows.find((row) => row.id === id);
    if (rowToCopy) {
      const copidRow = { ...rowToCopy };
      const newID = rows.length + 1;
      copidRow.id = newID;
      setRows([...rows, copidRow]);
    }
  };

  // search
  const handleInputChange = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    const filteredRows = rows.filter((row) =>
      row.Name.toLowerCase().includes(searchTerm)
    );
    
  
    setRows(filteredRows);

    if(searchTerm ===''){
      setRows(Data);
   
    }
    
  };

 

 
  

  return (
    <Box sx={{ width: "100%", maxHeight: "88vh" }}>
     


             {/* for mobile */}
{/* 
             <Box className={"mobilVied"}
        style={{
   
          backgroundColor:'#1975D2',
 
       
          height: "56px",
          paddingBottom:"97px",
          paddingTop:'119px',
          position:"fixed",
          width:'100%',
          zIndex:"2",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
          <div style={{ display: "flex",justifyContent:'space-between',margin:'0px 20px 0px 20px'}}>
          <Button
            onClick={handleDrawerClose}
           
          >
            <img style={{width:'14.98px',height:"14.66px"}} src="/assets/images/leftl.png" alt=""/>
        
            <span style={{color:'white',paddingLeft:"15px",fontSize:'15px',fontWeight:"400",textTransform:"none"}}>{selectedTab}</span>
          </Button>
          <Button
         
      
          >
             <span style={{color:'white',paddingLeft:"15px",fontSize:'15px',fontWeight:"400",textTransform:"none"}}>Save</span>
            
          </Button>
        </div>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          style={{width:"100%", backgroundColor:'#1975D2',}}
        >
          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: "#1975D2",
              color: "white" ,
              height: "56px",
              borderBottom: "4px solid white" ,
            }}
            label={formatLabel("Details")}
            {...a11yProps(0)}
          />
       
          
        </Tabs>

      
      </Box> */}



<div className={"mobilVied"}>
<div
        style={{
       
        
          textAlign: "center",
       
          paddingTop:"215px"
        }}
      >
       

{/* search */}
       <TextField
        style={{ backgroundColor: 'white', width: '301px', margin:'30px 0px 0px 0px' }}
        id="search-input"
        label="Type key & Enter"
        type="text"
        autoComplete="off"
      
        onChange={handleInputChange}
        
      />





    
      </div>

 
   

  

      <div className={"Othertab boxTwo"}>
        <TableContainer
          style={{ margin: "20px 0px 150px 0px" }}
          component={Paper}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">Code</TableCell>
                <TableCell align="left" style={{ paddingLeft: "20px" }}>
                  RTL
                </TableCell>

                <TableCell align="right">Status(EN/DS)</TableCell>
                {/* <TableCell align="right">Options</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  className={"tableRrow"}
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.id}
                  </TableCell>
                  <TableCell>{row.Name}</TableCell>
                  <TableCell>{row.Code}</TableCell>
                  {row.RTL === "on" ? (
                    <TableCell
                      style={{ paddingTop: "0px", paddingBottom: "0px" }}
                    >
                      <p
                        style={{
                          backgroundColor: "#4D9A19",
                          color: "white",
                          textAlign: "center",
                          width: "30px",
                          borderRadius: "4px",
                        }}
                      >
                        {" "}
                        {row.RTL}
                      </p>
                    </TableCell>
                  ) : (
                    <TableCell
                      style={{ paddingTop: "0px", paddingBottom: "0px" }}
                    >
                      <p
                        style={{
                          backgroundColor: "#D9D9D9",
                          color: "black",
                          textAlign: "center",
                          width: "30px",
                          borderRadius: "4px",
                        }}
                      >
                        {row.RTL}
                      </p>
                    </TableCell>
                  )}

                  <TableCell align="right">
                    {row.Enabled === "Enabled" ? (
                      <FormControlLabel
                        style={{
                          paddingRight: "10px",
                          margin: "0px",
                        }}
                        sx={{ m: 1, width: "100%" }}
                        control={<Switch defaultChecked />}
                        labelPlacement="start"
                      />
                    ) : (
                      //   <FormControlLabel control={<Switch  />} label="Label" />
                      <FormControlLabel
                        style={{
                          paddingRight: "10px",
                          margin: "0px",
                        }}
                        sx={{ m: 1, width: "100%" }}
                        control={<Switch />}
                        labelPlacement="start"
                      />
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {/* {row.Options} */}

                    <Stack
                      style={{
                        display: "grid",
                        justifyContent: "end",
                        gridTemplateColumns: "23% 25% 25%",
                      }}
                      direction="row"
                      spacing={1}
                    >
                      {row.id === 1 ? (
                        <IconButton
                          color="primary"
                          aria-label="copy"
                          style={{
                            backgroundColor: "transparent",
                            color: "transparent",
                            cursor: "default",
                          }}
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      ) : (
                        <IconButton
                          onClick={() => copyRowById(row.id)}
                          color="primary"
                          aria-label="copy"
                          style={{ backgroundColor: "#BCEBF5" }}
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      )}

                      <IconButton
                        onClick={() => handleEditClick(row)}
                        color="primary"
                        style={{ backgroundColor: "#BCEBF5" }}
                        aria-label="edit"
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDltClick(row.id)}
                        // onClick={() => deletById(row.id)}
                        style={{
                          color: "lightpink",
                          background: "rgba(249, 222, 252, 0.85)",
                        }}
                        aria-label="delete"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <Dialog open={editDialogOpen} onClose={handleCloseClick}>
        <DialogTitle>Edit Row</DialogTitle>
        <DialogContent>
          {editedRow && (
            <form style={{ display: "grid", gap: "20px", margin: "20px" }}>
              <TextField
                label="Name"
                value={editedRow.Name}
                onChange={(e) =>
                  setEditedRow((prevRow) => ({
                    ...prevRow,
                    Name: e.target.value,
                  }))
                }
              />
              <TextField
                label="Number of orders"
                value={editedRow.NumberOfOrders}
                onChange={(e) =>
                  setEditedRow((prevRow) => ({
                    ...prevRow,
                    NumberOfOrders: e.target.value,
                  }))
                }
              />
            

              <Button
                variant="contained"
                color="primary"
                onClick={handleSaveClick}
              >
                Save
              </Button>
              <Button
                style={{ background: "#FFB6C1" }}
                variant="contained"
                onClick={handleCloseClick}
              >
                Cancel
              </Button>
            </form>
          )}
        </DialogContent>
      </Dialog> 

      {/* for DLT */}
       <Dialog open={deletDialogOpen} onClose={handleCloseClickDlt}>
        <DialogTitle>Are you sure to delete this row</DialogTitle>
        <DialogContent>
    
            <form style={{ display: "grid", gap: "20px", margin: "20px" }}>
             
           

              <Button
                variant="contained"
                color="primary"
                
                onClick={() => deletById(selectedRowId)}
              >
                Confirm
              </Button>
              <Button
                style={{ background: "#FFB6C1" }}
                variant="contained"
                onClick={handleCloseClickDlt}
              >
                Cancel
              </Button>
            </form>
          
        </DialogContent>
      </Dialog>
</div>




<div className={"webview"}>
<div
        style={{
          display: "block",
        //   justifyContent: "space-between",
          alignItems: "end",
          margin: "0px 30px",
        //   height: "70px",
        //   marginTop:"30px"
        }}
      >


{/* search */}
<TextField
        style={{ backgroundColor: 'white', width: '301px', margin: '30px 0px' }}
        id="search-input"
        label="Type key & Enter"
        type="text"
        autoComplete="off"
      
        onChange={handleInputChange}
        
      />
      

    
      </div>

      <p
          style={{
            fontSize: "18px",
            fontWeight: "700",
             marginLeft:'20px'
          }}
        >
          Top Customer List
        </p>
      <div className={"Othertab boxTwo"}>
        <TableContainer
          style={{ margin: "20px 0px 150px 0px" }}
          component={Paper}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell align="left">Image</TableCell>
                <TableCell align="center">Name</TableCell>
                <TableCell align="center" >Number of orders </TableCell>
              
                {/* <TableCell align="right">Options</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  className={"tableRrow"}
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                     
                  <TableCell component="th" scope="row">
                    {row.id}
                  </TableCell> 
                  <TableCell component="th" scope="row">
                  {row.image? <img
                                        style={{
                                          width:'32px',
                                          height:'32px',
                                          borderRadius:'100%',
                                          objectFit:'cover'
                                        }}
                                        src={row.image}
                                        alt=""
                                      ></img>: <img
                                      style={{
                                        width:'32px',
                                        height:'32px',
                                        borderRadius:'100%',
                                        objectFit:'cover'
                                      }}
                                      src="/assets/images/defaultUserIcon.jpg"
                                      alt=""
                                    ></img> }
                  </TableCell>

                  <TableCell  align="center">{row.Name}</TableCell>
                  <TableCell align="center">{row.NumberOfOrders}</TableCell>
                 

                
                  {/* <TableCell align="right"> */}
                    {/* {row.Options} */}

                    {/* <Stack
                      style={{
                        display: "grid",
                        justifyContent: "end",
                        gridTemplateColumns: "23% 25% 25%",
                      }}
                      direction="row"
                      spacing={1}
                    >
                      {row.id === 1 ? (
                        <IconButton
                          color="primary"
                          aria-label="copy"
                          style={{
                            backgroundColor: "transparent",
                            color: "transparent",
                            cursor: "default",
                          }}
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      ) : (
                        <IconButton
                          onClick={() => copyRowById(row.id)}
                          color="primary"
                          aria-label="copy"
                          style={{ backgroundColor: "#BCEBF5" }}
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      )}

                      <IconButton
                        onClick={() => handleEditClick(row)}
                        color="primary"
                        style={{ backgroundColor: "#BCEBF5" }}
                        aria-label="edit"
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDltClick(row.id)}
                        // onClick={() => deletById(row.id)}
                        style={{
                          color: "lightpink",
                          background: "rgba(249, 222, 252, 0.85)",
                        }}
                        aria-label="delete"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Stack> */}
                  {/* </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

   

      {/* for DLT */}
       <Dialog open={deletDialogOpen} onClose={handleCloseClickDlt}>
        <DialogTitle>Are you sure to delete this row</DialogTitle>
        <DialogContent>
    
            <form style={{ display: "grid", gap: "20px", margin: "20px" }}>
             
           

              <Button
                variant="contained"
                color="primary"
                
                onClick={() => deletById(selectedRowId)}
              >
                Confirm
              </Button>
              <Button
                style={{ background: "#FFB6C1" }}
                variant="contained"
                onClick={handleCloseClickDlt}
              >
                Cancel
              </Button>
            </form>
          
        </DialogContent>
      </Dialog>
</div>

    </Box>
  );
}
