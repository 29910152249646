import React, { useState, useRef, useEffect } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Variations from "./Variations";
export default function VariationsPerent( quantity) {


    const [isChecked4, setIsChecked4] = useState(false);
    const [isOpen, setisOpen] = useState(false);
  


      const handleCheckboxChange4 = (event) => {
        setIsChecked4(event.target.checked)
        setisOpen(false)
        
      };
      // useEffect(()=>{
      //   console.log("VariationsPerent",quantity);
      // },[quantity])
  return (
    <div
    style={{
      backgroundColor: "white",
      display: "flex",
      flexDirection: "column",
      padding: "20px",
      borderRadius: "10px",
      margin: "20px 0px",
    }}
  >
  

    

  
     
      

        <FormControlLabel   control={<Checkbox checked={isChecked4}  onChange={handleCheckboxChange4} />} label="Enable variations for this product
" />

  
      
   
    <span
      style={{ margin: "7px 0px 10px 0px", border: "1px solid #898F94 " }}
    >
  
    </span>
    {isChecked4 ? 

         <Variations controlForm={setisOpen} Open={isOpen} setisOpen={setisOpen} quantity={quantity}  />:''
      
      }

  


   

    
  </div>
  )
}
