import React, { useState, useRef } from "react";
import { Dialog, DialogTitle, DialogContent, Button } from "@mui/material";
import WestIcon from "@mui/icons-material/West";
import ClearIcon from "@mui/icons-material/Clear";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import style from "./shipping.css";

export default function Shipping ({shippingDialogOpen,setShippingDialogOpen,setShippingInfo}) {
  const [price, setPrice] = useState(0);

  const [name, setName] = useState("");


  const cancalDiscount = () => {
    setPrice(0);
    setName("");
    setShippingDialogOpen(false);
  };

  const handleCloseClickShipping = () => {
    setPrice(0)
    setName("");
    setShippingDialogOpen(false);
  };

  const handleShippingPrice = (event) => {
    setPrice(event.target.value); // Update the selected value
  };
  const handleShippingName = (event) => {
    setName(event.target.value); // Update the selected value
  };


  const handleSubmitClick = () => {
    setShippingInfo({
      price:price,
      name:name

    })
    setShippingDialogOpen(false);
    
  };



  return (
    <div>
      <Dialog
        className={style.addDialog}
        open={shippingDialogOpen}
        onClose={handleCloseClickShipping}
      >
        <DialogTitle
          style={{
            display: "flex",
            // alignItems: "center",
            // columnGap: "180px",
            justifyContent: "space-between",
            padding: "13px",
            fontSize: "17px",
            fontWeight: "600",
            backgroundColor: "#F4F3F4",
            marginBottom: "10px",
            borderBottom: "1px solid #898F94",
          }}
        >
          <span
            style={{
              display: "flex",
              alignItems: "center",
              columnGap: "15px",
            }}
          >
            <WestIcon onClick={handleCloseClickShipping} />
            Add delivery charge
          </span>

          <ClearIcon onClick={handleCloseClickShipping} />
        </DialogTitle>

        <DialogContent style={{ borderBottom: "1px solid #898F94" }}>
            <div style={{display:"flex",justifyContent:'start',alignItems:'center',columnGap:'10px',borderRadius:"10px",backgroundColor:"#FEF1E4",height:"45px",paddingLeft:"20px",margin:"15px 0px 30px 0px"}}>

            <WarningAmberIcon/>

            <span
            style={{
             
              fontSize: "14px",
              fontWeight: "400",
              color: "#3f3f3f",
            }}
          >
            Your customers can see this reasone
          </span>
            </div>
       

          <div
            style={{
              display: "grid",
              justifyContent: "start",
              alignItems: "center",
              columnGap: "20px",
              gridTemplateColumns: "48% 48%",
              marginBottom: "20px",
            }}
          >
           <div>
           <label
            style={{
              fontSize: "14px",
              fontWeight: "400",
              paddingBottom: "10px",
              // marginTop:"40px"
            }}
            htmlFor="title"
          >
            Name
          </label>
          <input
            autoFocus={true}
            style={{
              padding: "19px 15px",
              borderRadius: "12px",
              border: "1px solid #898F94",
              width: "100%",
              marginBottom: "9px",
              fontSize: "14px",
              fontWeight: "400",
            }}
            type="text"
            id="title"
            value={name}
            onChange={handleShippingName}
            placeholder="E.g. Free shipping"
          ></input>
           </div>
            <div>
           <label
            style={{
              fontSize: "14px",
              fontWeight: "400",
              paddingBottom: "10px",
              // marginTop:"40px"
            }}
            htmlFor="title"
          >
            Price
          </label>
          <input
            autoFocus={true}
            style={{
              padding: "19px 15px",
              borderRadius: "12px",
              border: "1px solid #898F94",
              width: "100%",
              marginBottom: "9px",
              fontSize: "14px",
              fontWeight: "400",
            }}
            type="text"
            id="title"
            value={price}
            onChange={handleShippingPrice}
            placeholder="৳ 0.00"
          ></input>
           </div>
            
          </div>
         
         
        </DialogContent>

        <div className="buttons">
          <Button variant="contained" color="error" onClick={cancalDiscount}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmitClick}
          >
            Apply
          </Button>
        </div>
      </Dialog>
    </div>
  );
}
