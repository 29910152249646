import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import './SearchBar.css';

const SearchBar = ({setSearchIterm,searchIterm, click}) => {

  // console.log('filtedWithStatu',filtedWithStatu);
  const [isExpanded, setIsExpanded] = useState(false);
  const searchField = useRef(null)


  const toggleSearchBar = () => {
    setIsExpanded(!isExpanded);
    click(!isExpanded);
    
  };

  // useEffect(() => {
  //   if(!isExpanded) {
  //     setSearchIterm("")
  //   }
  // }, [isExpanded])

  const handleInputChange = (event) => {

  
    setSearchIterm (event.target.value.toLowerCase());
    
    // const filteredRows = rows.filter((row) =>
    //   row.Name.toLowerCase().includes(searchTerm)
    // );
    
  
    // setRows(filteredRows);

    // if(searchTerm ===''){
    //   setRows(Data);
   
    // }
    
  };

  useEffect(() => {
    if(isExpanded){
      searchField.current.focus()
    }
  }, [
    isExpanded
  ])


  return (
    <div className="search-container">
      {/* <input style={{outline:'none'}} */}
      <input 
        ref={searchField}
        type="text"
        className={`search-input ${isExpanded ? 'active' : ''}`}
        placeholder="Searching products..."
        onChange={handleInputChange}
        value={searchIterm}
      />
      <button className={`search-icon ${isExpanded ? 'expanded' : ''}`} onClick={toggleSearchBar}>
        <FontAwesomeIcon style={{height:"21px"}} icon={isExpanded ? faTimes : faSearch} />
      </button>
    </div>
  );
};

export default SearchBar;


