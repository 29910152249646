// import SearchInput from "./Components/test3";


// function App() {
//   return (
//     <div>
//    <h1>Printable Example</h1>
//      <SearchInput/> 
   
//     </div>
//   );
// }
// export default App;







import AddCompany from "./Components/newCompany";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from "./Components/header";
// import Dashboard from "./Components/dashboard"
import SettingsMain from "./Components/settings/Settings"
import NewAppWebsh from "./Components/appwebsh/NewAppWebsh"
import Dashboard from "./dashboard/dashboard";
import Products from "./Components/products/Products";
import CompanyDetailsTab from "./Components/settings/CompanyDetails";
import Orders from "./Components/Orders/Orders";

const App = () => {
  return (
    <Router>
        <Routes>
          <Route  element={<Header />} >
          <Route path="/" index element={<Dashboard />} />
          <Route path="products" element={<Products/>}/>
          <Route path="orders" element={<Orders/>}/>
            <Route path="add-company" element={<AddCompany/>}/>
            <Route path="company-details" element={<CompanyDetailsTab/>}/>
            <Route path="settings" element={<SettingsMain/>}/>
            <Route path="app-websh" element={<NewAppWebsh/>}/>
           
          </Route>
        </Routes>
      
    </Router>
  );
};

export default App;

