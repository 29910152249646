import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import DeleteIcon from '@mui/icons-material/Delete'; 
const filter = createFilterOptions();

export default function InventoryLocation({ controlForm }) {
  const [values, setValues] = React.useState([]); // Use an array for multiple selections

//   const removeLocation = (index) => () => {
//     const newValues = [...values];
//     newValues.splice(index, 1);
//     setValues(newValues);
//   }; 
  const removeLocation =  () => {
    controlForm(false)
  };

  return (
    <div style={{ display: 'grid', gridTemplateColumns: '87% 10%', columnGap: '10px', alignItems: 'center' }}>
      <Autocomplete
        multiple // Enable multiple selections
        value={values}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            // The user typed a new value, create a new option object
            setValues([...values, { title: newValue }]);
          } else if (newValue && newValue.inputValue) {
            // The user selected a new value from the suggested options, create a new option object
            setValues([...values, { title: newValue.inputValue }]);
          } else {
            // The user selected an existing option from the list
            setValues(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const { inputValue } = params;
          const isExisting = options.some((option) => inputValue === option.title);
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              inputValue,
              title: `Add "${inputValue}"`,
            });
          }
          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id="free-solo-with-text-demo"
        options={top100Films}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.title;
        }}
        renderOption={(props, option) => <li {...props}>{option.title}</li>}
        style={{ width: '100%', marginTop: '20px' }}
        freeSolo
        renderInput={(params) => <TextField {...params} label="Add / Select inventory location" />}
      />

        <button  style={{ background: 'none', border: 'none', cursor: 'pointer' }} type="button" onClick={removeLocation}>
          <DeleteIcon />
        </button>
    
    </div>
  );
}

const top100Films = [
  { title: 'The Shawshank Redemption'},
  { title: 'The Godfather'},
  { title: 'The Godfather: Part II'},
  { title: 'The Dark Knight'},
  { title: '12 Angry Men'},
  { title: "Schindler's List"},
  { title: 'Pulp Fiction'},
  // ... rest of the data ...
];
