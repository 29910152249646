import React, { useState, useRef } from "react";
import { FaSearch } from "react-icons/fa";
import "react-quill/dist/quill.snow.css";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import "./AddOrders.css";
import WestIcon from "@mui/icons-material/West";
import ClearIcon from "@mui/icons-material/Clear";
import products from "../../_mockData/products";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {useEffect } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Discount from "./Discount";
import Shipping from "./Shipping";
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import TexCalculation from "./Tex";




const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));






const paymentItems = ['Due on receipt', 'Option 2'];
const AddOrder = () => {
  
  const [title, setTitle] = useState("");
  const [subTotal, setsubTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [texAmount, setTexAmount] = useState(0);
  const [paymentDueLater, setPaymentDueLater] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [paymentItemValue, setPaymentItemValue] = React.useState(paymentItems[0]);

  const [selectedProductIndex, setSelectedProductIndex] = useState([]); // State to keep track of the selected product index
  
  const [allFilterProduct, setAllFilterProduct] = useState([]);


  const [addOrderProduct, setAddOrderProduct] = useState([]);
  console.log("addOrderProduct",addOrderProduct);

  
  const [LinkDialogOpen, setLinkDialogOpen] = useState(false);
  const [discountDialogOpen, setDiscountDialogOpen] = useState(false);
  const [shippingDialogOpen, setShippingDialogOpen] = useState(false);
  const [texDialogOpen, setTexDialogOpen] = useState(false);
 

  
  const [discountInfo, setDiscountInfo] = useState();
  const [shippingInfo, setShippingInfo] = useState();
  const [texInfo, setTexInfo] = useState( );


  const handleTitleChange = (event) => {
    setTitle(event.target.value);

    if (event.target.value) {
      const filterProd = products.filter((prod) =>
        prod?.name?.toLowerCase().includes(event.target?.value?.toLowerCase())
      );
      setAllFilterProduct(filterProd);

      setLinkDialogOpen(true);
    } else {
      setAllFilterProduct(null);

      setLinkDialogOpen(true);
    }
  };
  const handleCloseClickDlt = () => {
    setTitle("");
    setLinkDialogOpen(false);
  };

  const selectId = (id) => {
    if (selectedProductIndex.includes(id)) {
      setSelectedProductIndex(selectedProductIndex.filter((i) => i.id !== id));
    } else {
      setSelectedProductIndex([...selectedProductIndex, id]);
    }
  };
  const cancalOrder = () => {
    setAddOrderProduct([]);
    setSelectedProductIndex([]);
    setLinkDialogOpen(false);
    setTitle("");
  };



  const handleSubmitClick = () => {
    const selectedProduct = selectedProductIndex.map((id) => {
      const tempProd = allFilterProduct.find(product => product.id === id);
      return { ...tempProd, selectedQuantity: "1" ,total:parseInt(tempProd.price) };
    });
  
    setAddOrderProduct([...selectedProduct, ...addOrderProduct]);
    setTitle("");
    setLinkDialogOpen(false);
    setSelectedProductIndex([]);
  };
  



 

  const getSubTotal = () => {
    
    
  
      const subTotalPrice = addOrderProduct.map((prod) => parseFloat(prod.total));
  
    const sum = subTotalPrice.reduce((accumulator,currentValue)=>accumulator + currentValue , 0);
  
    setsubTotal(sum);
    setTotal(sum);
    }
    
    const getTotal =()=>{


       if(discountAmount){
        
        setTotal(prevState => prevState - parseInt(discountAmount))
       }
       if(shippingInfo?.price){
     
        setTotal(prevState => prevState + parseInt(shippingInfo?.price))
       }
       if(texAmount){
        setTotal(prevState => prevState +parseInt(texAmount) )
       }
       
     
      
    }
  


 const getDiscountAmount =()=>{
   if(discountInfo?.disType?.label !=="Amount"){
   
    const TempDiscount= (subTotal / 100)*parseInt(discountInfo?.values);
    setDiscountAmount(TempDiscount);
 
    

   }
   else{
    // const TempDiscount= (subTotal / 100)*parseInt(discountInfo?.values);
    setDiscountAmount(discountInfo?.values);


   }
 }

 const getTexAmount = () => {

  

  if (texInfo?.Type?.label !== "Amount") {
 
  
  
    const tempTex = (subTotal / 100)*parseInt(texInfo?.values);

    setTexAmount(tempTex);
  }
  else if(texInfo?.Type?.label !== "Percentage"){
  
    
    setTexAmount(texInfo?.values);
  }

  
}




//  const getTexAmount = ()=>{
//   if(texInfo?.type?.label !=="Amount"){
//     console.log("if block");
//     const tempTex=(subTotal / 100) * parseInt(texInfo?.values) ;
//     setTexAmount(tempTex);
//   }else{
//     console.log("else block");
//     setTexAmount(texInfo?.type?.label);
//   }

//  }



  const [menuIndex, setMenuIndex] = useState(0);

  const [isEditMode, setIsEditMode] = useState(false);
  
  const handleClickMenue = (event,i ) => {
  
    setIsEditMode(true);
    setMenuIndex(i)
    // setAnchorEl(event.currentTarget);
  };




   const handlediscountDialogOpen = () => {
    // setTitle("");
    setDiscountDialogOpen(true);
  };
  
  
  const handleShippingDialogOpen = () => {
    // setTitle("");
    setShippingDialogOpen(true);
  };

 const handleTexDialogOpen = () => {
    // setTitle("");
    setTexDialogOpen(true);
  };




  

  useEffect(() => {
    getSubTotal()
    getTotal()
    // getDiscountAmount()
    // handleClickMenue( )
    
  }, [addOrderProduct,discountAmount, shippingInfo,texAmount])

  useEffect(() => {
    getDiscountAmount()
    getTexAmount()
    
  }, [discountInfo,texInfo,subTotal,])

 

  
  const handleInputChange = (event,i) => {
    const tempSelectedValue = [...addOrderProduct];
    tempSelectedValue[i].selectedQuantity=parseInt(event?.target?.value); // Convert input value to integer
   


    const totalPrice = tempSelectedValue[i].selectedQuantity * parseInt(tempSelectedValue[i].price);
    tempSelectedValue[i].total = totalPrice;
    setAddOrderProduct(tempSelectedValue);
  };
  const deleteOrderProduct = (index)=>{
    const tempOrderProduct=[...addOrderProduct];
    tempOrderProduct.splice(index,1);
    setAddOrderProduct(tempOrderProduct);


    // addOrderProduct
  }

  const handleCheckboxChange =(event)=>{
    setPaymentDueLater(event.target.checked); 
  }
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
 
  return (
    <div style={{ backgroundColor: "#F1F2F4", padding: "20px" }}>
      <div
        style={{
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          borderRadius: "10px",
        }}
      >
        <label
          style={{
            padding: "20px 0px",
            fontSize: "17px",
            fontWeight: "700",
            paddingBottom: "10px",
          }}
          htmlFor="title"
        >
          Products
        </label>

        <div className="search-containerr">
          <div className="search-iconn">
            <FaSearch />
          </div>
          <input
            type="text"
            placeholder="Search product"
            className="search-inputt"
            value={title}
            onChange={handleTitleChange}
          />
        </div>

        
       {addOrderProduct.length>0   && 
      
       <div>
       <div
         style={{
           display: "grid",
           gridTemplateColumns: " 37% 19% 11% 27% 6%",
           alignItems: "center",
           justifyContent: "start",
           borderBottom:'1px solid rgb(137, 143, 148)'
         }}
       >
         <label
           style={{
             padding: "20px 0px",
             fontSize: "17px",
             fontWeight: "700",
             paddingBottom: "10px",
           }}
           htmlFor="title"
         >
           Product
         </label>
         <label
           style={{
             padding: "20px 0px",
             fontSize: "17px",
             fontWeight: "700",
             paddingBottom: "10px",
             textAlign: "center",
           }}
           htmlFor="title"
         >
           Price
         </label>
         <label
           style={{
             padding: "20px 0px",
             fontSize: "17px",
             fontWeight: "700",
             paddingBottom: "10px",
             textAlign: "center",
           }}
           htmlFor="title"
         >
           Quantity
         </label>

         <label
           style={{
             padding: "20px 0px",
             fontSize: "17px",
             fontWeight: "700",
             paddingBottom: "10px",
             textAlign: "center",
           }}
           htmlFor="title"
         >
           Total
         </label>
       </div>
      {addOrderProduct.map((item,i)=>(
        <div  key={i}  style={{
          display: "grid",
          gridTemplateColumns: " 37% 19% 11% 27% 6%",
          // alignItems: "center",
          justifyContent: "start",
          borderBottom:'1px solid rgb(137, 143, 148)'
        }}>
      <div style={{display:'flex',justifyContent:"start",columnGap:'10px',alignItems:"center",padding:"10px 0px"}}>
        <img  style={{
                      height: "60px",
                      width: "70px",
                      marginRight: "20px",
                    }} src={item?.image}alt="img" />
        <div style={{display:'flex',flexDirection:"column",gap:'10px'}}>
          <span style={{fontWeight:"700",fontSize:"17px",color:'#342DF2'}}>{item?.name}</span>
          <span style={{fontWeight:"400",fontSize:"14px",color:'blacl'}}>{item?.sku}</span>
          {/* <span style={{fontWeight:"400",fontSize:"14px",color:'#342DF2'}}>৳ {item?.price} BDT </span> */}
        </div>
      </div>


      {
  
    <div  style={{ textAlign: "end" }}>
      {isEditMode  ? (
        <input
          type="number"
          className="input"
          value={item?.price}
          onChange={(e) => {
            const tempPrices = [...addOrderProduct];
            tempPrices[i].price = parseInt(e.target.value); // Update the specific price
            const totalPrice = tempPrices[i]?.selectedQuantity * parseInt(tempPrices[i].price);
            tempPrices[i].total = totalPrice;
            setAddOrderProduct(tempPrices);
          }}
          onBlur={() => setIsEditMode(false)} // Reset edit mode
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setIsEditMode(false);
            }
          }}
        />
      ) : (
        <input
          type="number"
          className="input"
          value={item?.price}
          onClick={(e) => handleClickMenue(e,i)} // Enter edit mode for this price
        />
      )}
    </div>

}

      
      <div style={{textAlign: "center",}}>
      <input type="number" className="quantity"   min="1"
            value={item?.selectedQuantity}
            onChange={(e)=>handleInputChange(e,i)} ></input>
      </div> 
      
       <div style={{textAlign:"center",marginTop: "23px"}}>
       <span style={{fontWeight:"700",fontSize:"17px",color:'#342DF2'}}>৳ {item?.total ? item?.total  : 0.00}BDT </span>
      </div>


       <div onClick={()=>deleteOrderProduct(i)} style={{textAlign:"center",marginTop: "23px"}}>
       <DeleteIcon />
      </div>
     
      


      </div>
      ))}
       
      

     </div>
       } 
        
        
      </div>

      <div
        style={{
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          padding: "10px 20px 20px 20px",
          borderRadius: "10px",
          marginTop: "20px",
        }}
      >
        <label
          style={{
            fontSize: "17px",
            fontWeight: "700",
            paddingBottom: "10px",
          }}
          htmlFor="title"
        >
          Payment
        </label>
        <div
        style={{border:"1px solid rgb(137, 143, 148)",borderRadius:"10px"}}>

<div
          style={{
            borderBottom:allFilterProduct.length !== 0 ? "1px solid rgb(137, 143, 148)":"none",
            display: "grid",
            gridTemplateColumns:'27% 48% 25%',
            alignItems: "center",
            // padding:"0px 10px"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "15px 10px",
            }}
          >
            <span className="title">Subtotal</span>
            <span className="des" onClick={handlediscountDialogOpen}>{discountInfo? "Edit discount":"Add discount"}</span>
            <span className="des"onClick={handleShippingDialogOpen} >{shippingInfo?"Edit delivery charge":"Add delivery charge"}  </span>
            <span className="des" onClick={handleTexDialogOpen}>{texAmount?"Edit estimated tax":"Estimated tax"}</span>
            <span className="des">Total</span>
          </div>

          <div   style={{
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          gap:"17px"
          
        }}>
            {
            discountInfo && discountInfo?.reasone !=='' ?(<span style={{fontSize:"14px",fontWeight:"400",color:"#898F94"}}>{discountInfo?.reasone }</span>):(<span style={{fontSize:"14px",fontWeight:"400",color:"#898F94"}}>N/A</span>) 
            }
            
             {
              shippingInfo && shippingInfo?.name ?(<span style={{fontSize:"14px",fontWeight:"400",color:"#898F94"}}>{shippingInfo.name}</span>):(<span style={{fontSize:"14px",fontWeight:"400",color:"#898F94"}}>N/A</span>) 
            } 
            {
             texInfo && texAmount ?(<span style={{fontSize:"14px",fontWeight:"400",color:"#898F94"}}>Charge texes </span>):(<span style={{fontSize:"14px",fontWeight:"400",color:"#898F94"}}>N/A</span>) 
            }

          </div>


          <div
            style={{
              display: "flex",
              alignItems:'end',
              flexDirection: "column",
              padding: "15px 10px",
            }}
          >
            <span className="title">৳ {subTotal?subTotal:"0.00"}</span>
            <span className="subtotall" >৳ {discountAmount ? -discountAmount:"0.00" }</span>
            <span className="subtotall">৳ {shippingInfo?.price? (shippingInfo?.price >= 0 ? '+' : '') + shippingInfo?.price: "0.00"}</span>
            <span className="subtotall">৳ {texAmount ? (texAmount >= 0 ? '+' : '') + texAmount : "0.00"}</span>

            {/* <span className="subtotall" >৳ {texAmount?-texAmount: "0.00"}</span> */}
            <span className="subtotall">৳ {total?total:"0.00"}</span>
          </div>

          {/* <div
            style={{
           
              padding: "0px 10px 0px 10px",
              display:"flex",
              flexDirection:"column",

              alignItems:'end'
              
              
            }}
          >
            <span className="subtotal"></span>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap:"5px"
               
              
                
              }}>
            <span className="subtotall"></span>
            <span className="subtotal"></span>
            
            <span className="subtotal"></span>
            <span className="subtotal"></span>
            </div>
       
          </div> */}
        </div>

       {addOrderProduct.length !== 0 && <FormControlLabel style={{  padding:"0px 10px"}} 
        control={<Checkbox checked={paymentDueLater} onChange={handleCheckboxChange} />} label="Payment due later" />
        
      } 
      {paymentDueLater &&
      <div>
            <Autocomplete style={{margin:"15px 0px 20px 10px"}}
        value={paymentItemValue}
        onChange={(event, newValue) => {
          setPaymentItemValue(newValue);
        }}
       
        id="controllable-states-demo"
        sx={{ width: 300 }}
        options={paymentItems}
       
        renderInput={(params) => <TextField {...params} label="Payment terms" InputLabelProps={{
          style:{
            fontSize:"14px",fontWeight:"400"
          }
        }} />}
      />
      <div style={{display:'flex',flexDirection:"column",gap:"0px",padding:"0px 10px"}}>
      <span style={{fontSize:"17px",fontWeight:"700",color:'black'}}>Payment due when invoice is sent <span style={{fontSize:'14px',fontFamily:"400",color:"#898F94"}}>you will be able to collect payment from the order page .</span></span> <br></br>
      <span style={{fontSize:"14px",fontWeight:"400",color:"#898F94",paddingBottom:'20px'}}>You can also <span style={{fontSize:'17px',fontFamily:"700",color:"#00A1E4",cursor:'pointer'}}>setup automatic payment reminders </span>for customers .</span>
      </div>
    
      </div>}
          </div> 
          {addOrderProduct.length !==0 && !paymentDueLater ?<div style={{display:"flex",alignItems:'center',justifyContent:"end",columnGap:"5px",marginTop:"10px"}}>
            <button style={{cursor:"pointer",height:"30px",fontSize:"14px",fontWeight:"400",width:"115px",border:"1px solid black",outline:"none",borderRadius:"10px",color:"black",}}>Send invoice</button>
            <Button  style={{textTransform:"none",fontSize:"14px",fontWeight:"400",height:"30px",width:"200px",border:"none",outline:"none",borderRadius:"10px",backgroundColor:"#3F3E3F",color:"white"}}
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >Collect payment</Button>
          <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} disableRipple>
          <EditIcon />
          Cash collection
        </MenuItem>
        <MenuItem onClick={handleClose} disableRipple>
          <FileCopyIcon />
          Bikash
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem onClick={handleClose} disableRipple>
          <ArchiveIcon />
          Nogod
        </MenuItem>
        <MenuItem onClick={handleClose} disableRipple>
          <MoreHorizIcon />
          Rocket
        </MenuItem>
      </StyledMenu>
     
          </div>:
          ""}
          {addOrderProduct.length !==0 && paymentDueLater ?
            <div style={{display:"flex",justifyContent:"end",marginTop:"10px"}}>
            <button style={{cursor:"pointer",height:"30px",width:"115px",border:"none",outline:"none",borderRadius:"10px",backgroundColor:"#3F3E3F",color:"white"}}>Create order</button>
          </div>:""
          }
          
    
      </div>

      <Dialog
        className="addDialog"
        open={LinkDialogOpen}
        onClose={handleCloseClickDlt}
      >
        <DialogTitle
          style={{
            display: "flex",
            // alignItems: "center",
            // columnGap: "180px",
            justifyContent: "space-between",
            padding: "13px",
            fontSize: "17px",
            fontWeight: "600",
            backgroundColor: "#F4F3F4",
            marginBottom: "10px",
            borderBottom: "1px solid #898F94",
          }}
        >
          <span
            style={{
              display: "flex",
              alignItems: "center",
              columnGap: "15px",
            }}
          >
            <WestIcon onClick={handleCloseClickDlt} />
            All products
          </span>

          <ClearIcon onClick={handleCloseClickDlt} />
        </DialogTitle>

        <DialogContent>
          <input
            autoFocus={true}
            style={{
              padding: "10px 15px",
              borderRadius: "5px",
              border: "1px solid #898F94",
              width: "100%",
              marginBottom: "10px",
            }}
            type="text"
            id="title"
            value={title}
            onChange={handleTitleChange}
            placeholder=""
          />
          {allFilterProduct?.map((product, i) => (
            <FormGroup>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "15px",
                }}
                key={i}
              >
                {/* Display product details here */}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    // columnGap: "20px",
                  }}
                >
                  <FormControlLabel
                    onClick={() => selectId(product.id)}
                    style={{ width: "30px" }}
                    control={<Checkbox disabled={product.quantity === "0"} />}
                  />

                  <span
                    style={{
                      height: "60px",
                      width: "70px",
                      marginRight: "20px",
                    }}
                  >
                    <img
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                        borderRadius: "10px",
                      }}
                      src={product.image}
                      alt=""
                    />
                  </span>

                  <span
                    style={{
                      fontSize: "17px",
                      fontWeight: "700",
                      color: "blue",
                    }}
                  >
                    {product.name}
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#7C7C7C",
                    }}
                  >
                    {product?.quantity} available
                  </span>
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#7C7C7C",
                    }}
                  >
                    ৳ {product?.price} BDT
                  </span>
                </div>
              </div>
            </FormGroup>
          ))}
        </DialogContent>

        {allFilterProduct && (
          <div className="buttons">
            <Button variant="contained" color="error" onClick={cancalOrder}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmitClick}
            >
              Add
            </Button>
          </div>
        )}
      </Dialog>
      <Discount discountDialogOpen={discountDialogOpen} setDiscountDialogOpen={setDiscountDialogOpen}  setDiscountInfo={setDiscountInfo} /> 
      <Shipping setShippingDialogOpen={setShippingDialogOpen} shippingDialogOpen={shippingDialogOpen}setShippingInfo={setShippingInfo}/>
      <TexCalculation setTexDialogOpen={setTexDialogOpen} texDialogOpen={texDialogOpen} setTexInfo={setTexInfo}/>







     

    </div>
  );
};

export default AddOrder;
