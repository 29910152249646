import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { hover } from "@testing-library/user-event/dist/hover";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const MenuItemX = ({ open, text, menuItemIcon, menuItemTitle, showMenu, route}) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    if(showMenu){
      setAnchorEl(event.currentTarget);
    }
  };


  const handleClose = () => {
    setAnchorEl(null);
  };

  //   const title={    fontsize: '12px',
  //     color: 'rgb(25, 117, 210)',
  //     fontWeight: '600',
  //     textTransform: 'uppercase',
  //     letterSpacing: '0.05em',
  //     line-height: 20px;}

  return (
    <>
      <ListItem
        sx={{ display: "block", maxHeight: "52px" }}
        className="noScroolBar"
      >
        <ListItemButton className="MenuItemTwo"
          id="basic-button"
          aria-controls={openMenu ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openMenu ? "true" : undefined}
          onClick={handleClick}
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
            borderBottom: `1px solid ${text.color}`,
            borderTop: `1px solid ${text.color}`,
          }}
        >
          <ListItemIcon 
            sx={{
              minWidth: 0,
              mr: open ? 3 : "auto",
              justifyContent: "center",
              color: "black",
            }}
          >
            {typeof text.icon === "string" ? (
              <img src={text.icon} alt={text.text} />
            ) : (
              text.icon
            )}
          </ListItemIcon>

          <ListItemText primary={text.text} sx={{ opacity: open ? 1 : 0 }} />
        </ListItemButton>
      </ListItem>
      <Menu
        className="test"
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
     

       <span onClick={handleClose}>
       <span    onClick={ () => navigate(text.route)}
          style={{
            display: "flex",
            columnGap: "20px",
            width: "100%",
            alignItems: "center",
         
          }}
        >
          <img 
            style={{ width: "34px", height: "34px", marginLeft: '6px' }}
            src={text.menuItemIcon} alt=""
          />
          <p
            style={{
              fontFamily: "Inter",
              fontWeight: "400",
              fontSize: "20px",
              color: "#1975D2",
            }}
          >
            {text.menuItemTitle}
          </p>
        </span>
       </span>
      
       

        {text.subMenu.map((sub, index) => (
          <MenuItem className="MenuItem" key={index} onClick={() => {
              navigate(sub.url ?? "no route found"); 
              handleClose();
            }}>
            <img style={{ width: "25px", height: "15px",paddingRight:'5px',width: '30px'}} src={sub.icon} />
            <p
              style={{
                fontFamily: "Helvetica",
                fontWeight: "400",
                fontSize: "16px",
                color: "rgba(0, 0, 0, 0.64)",
                paddingLeft:'10px',
                // marginRight: '27px'
              }}
            >
              {sub.name}
            </p>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default MenuItemX;
