import React, { useState, useRef } from "react";
import { Dialog, DialogTitle, DialogContent, Button } from "@mui/material";
import WestIcon from "@mui/icons-material/West";
import ClearIcon from "@mui/icons-material/Clear";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import style from "./Tex.css";

export default function TexCalculation ({setTexDialogOpen,texDialogOpen,setTexInfo}) {
  const [texType, setTexType] = useState("");
  const [texValues, setTexValues] = useState(0);
  console.log("rdfrfrf",texValues);



  const cancalTex = () => {
    setTexType("");
    setTexValues(0);
    setTexDialogOpen(false);
  };

  const handleCloseClickTex = () => {
    setTexType("");
    setTexValues(0);
    setTexDialogOpen(false);
  };

  const handleTextValue = (event) => {
    setTexValues(event.target.value); // Update the selected value
    console.log(event.target.value)
  };

  const handleTexChange = (_event, newValue) => {
    setTexType(newValue); // Update the selected value
  };

  const handleSubmitClick = () => {
    setTexInfo({
      Type:texType,
      values:texValues,
     

    })
    setTexDialogOpen(false);
    // const selectedProduct = selectedProductIndex.map((id) => {
    //   const tempProd = allFilterProduct.find(product => product.id === id);
    //   return { ...tempProd, selectedQuantity: "1" ,total:parseInt(tempProd.price) };
    // });
  
    // setAddOrderProduct([...selectedProduct, ...addOrderProduct]);
    // setTitle("");
    // setLinkDialogOpen(false);
    // setSelectedProductIndex([]);
  };


  const discountValue = [
    { label: 'Amount' },
    { label: 'Percentage' },
   
  ];
  return (
    <div>
      <Dialog
        className={style.addDialog} 
        open={texDialogOpen}
        onClose={handleCloseClickTex}
      >
        <DialogTitle
          style={{
            display: "flex",
            // alignItems: "center",
            // columnGap: "180px",
            justifyContent: "space-between",
            padding: "13px",
            fontSize: "17px",
            fontWeight: "600",
            backgroundColor: "#F4F3F4",
            marginBottom: "10px",
            borderBottom: "1px solid #898F94",
          }}
        >
          <span
            style={{
              display: "flex",
              alignItems: "center",
              columnGap: "15px",
            }}
          >
            <WestIcon onClick={handleCloseClickTex} />
            Add Tax
          </span>

          <ClearIcon onClick={handleCloseClickTex} />
        </DialogTitle>

        <DialogContent style={{ borderBottom: "1px solid #898F94" }}>
          <div
            style={{
              display: "grid",
              justifyContent: "start",
              alignItems: "center",
              columnGap: "20px",
              gridTemplateColumns: "48% 48%",
              marginBottom: "20px",
            }}
          >
            <Autocomplete
              style={{ paddingTop: "30px" }}
              disablePortal
              id="combo-box-demo"
              options={discountValue}
              value={texType}
              onChange={handleTexChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tax type"
                  InputLabelProps={{
                    style: { fontSize: "14px", fontWeight: "400" },
                  }}
                />
              )}
            />
            <div>
              <label
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  paddingBottom: "10px",
                }}
                htmlFor="title"
              >
                Tax Value
              </label>

              <div
                style={{
                  padding: "0px 15px",
                  borderRadius: "12px",
                  border: "1px solid #898F94",
                  width: "100%",
                  height: "56px",
                  paddingTop: "14px",
                  // marginBottom: "10px",
                }}
              >
                <span>
                  {texType?.label === ""
                    ? ""
                    : texType?.label === "Amount"
                    ? "৳"
                    : texType?.label === "Percentage"
                    ? ""
                    : ""}
                </span>

                <input
                  style={{
                    border: "none",
                    outline: "none",
                    fontSize: "14px",
                    fontWeight: "400",

                    // marginBottom: "10px",
                  }}
                  autoFocus={true}
                  type="number"
                  id="title"
                  value={texValues}
                  onChange={handleTextValue}
                  placeholder="0.00"
                ></input>

                <span>
                  {" "}
                  {texType?.label === ""
                    ? ""
                    : texType?.label === "Amount"
                    ? "BDT"
                    : texType?.label === "Percentage"
                    ? "%"
                    : ""}{" "}
                </span>
              </div>
            </div>
          </div>
         
         
        </DialogContent>

        <div className="buttons">
          <Button variant="contained" color="error" onClick={cancalTex}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmitClick}
          >
            Apply
          </Button>
        </div>
      </Dialog>
    </div>
  );
}
