import React, { useState } from "react";
import { BottomNavigation } from "@mui/material";
import { Box, Button, IconButton, Menu, MenuItem } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import MenuIcon from "@mui/icons-material/Menu";
import { MdAssignmentReturn } from "react-icons/md";
import { MdSdCard } from "react-icons/md";
import { MdAddBox } from "react-icons/md";
import AddIcon from "@mui/icons-material/Add";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Chart from "chart.js/auto";
import { MdArrowUpward } from "react-icons/md";
import { MdArrowDownward } from "react-icons/md";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgressWithLabel from "../Components/spinner";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import DeshBoardMobile from "./deshBoardMobile";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

// chart
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  Title,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import TopCustomerList from "./topCustomerList";
import RecentActivities from "./RecentActivities";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// table
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const BestSellProd = [
  {
    image: "/assets/images/chair.webp",
    ProductName: "ELIMEN WICKER CHAIR",
    NumberofSalesL: "770",
  },
  {
    image: "/assets/images/chair.webp",
    ProductName: "GIFT CARD",
    NumberofSalesL: "80",
  },
  {
    image: "/assets/images/chair.webp",
    ProductName: "MILTON DESK",
    NumberofSalesL: "44",
  },
  {
    image: "/assets/images/chair.webp",
    ProductName: "KEPLING DESK",
    NumberofSalesL: "170",
  },
  {
    image: "/assets/images/chair.webp",
    ProductName: "ELIMEN WICKER CHAIR",
    NumberofSalesL: "760",
  },
  {
    image: "/assets/images/chair.webp",
    ProductName: "ELIMEN WICKER CHAIR",
    NumberofSalesL: "770",
  }
  ,{
    image: "/assets/images/chair.webp",
    ProductName: "ELIMEN WICKER CHAIR",
    NumberofSalesL: "770",
  },
];
const sortedBestSellProd = [...BestSellProd].sort((a, b) =>b.NumberofSalesL - a.NumberofSalesL);





// drowerSideTab
function TabPanelDrower(props) {
  const { children, Sidevalue, i, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={Sidevalue !== i}
      id={`simple-side-tabpanel-${i}`}
      aria-labelledby={`simple-tab-${i}`}
      {...other}
    >
      {Sidevalue === i && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanelDrower.propTypes = {
  children: PropTypes.node,
  i: PropTypes.number.isRequired,
  Sidevalue: PropTypes.number.isRequired,
};

function a11yPropsDrower(i) {

  return {
    id: `simple-tab-${i}`,

    "aria-controls": `simple-side-tabpanel-${i}`,
  };
}
// end

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};




function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const labels = ["", "", "", "", ""];

export const data = {
  labels,
  datasets: [
    {
      label: "none",

      data: [7, 10, 5, 12, 10],
      backgroundColor: [
        "#E0E4FF",
        "#E0E4FF",
        "#E0E4FF",
        "#E0E4FF",
        "#00A1E4",
        "#E0E4FF",
        "#00A1E4",
      ],
      borderWidth: 0,
    },
  ],
};
const options2 = {
  // CategoryScale:false,
  LinearScale: false,
  responsive: true,
  plugins: {
    legend: {
      position: "none",
    },
  },

  scales: {
    y: {
      beginAtZero: true,
      label: false,
      grid: {
        borderColor: "rgba(0, 0, 0, 0)!important", // Set border color to transparent
        drawBorder: false, // Remove the vertical line on the right side
        display: false, // Remove the vertical grid lines
      },
      ticks: {
        display: false, // Hide the left side values
      },
    },
    x: {
      grid: {
        display: false, // Remove the horizontal grid lines
      },
      ticks: {
        display: false, // Hide the left side values
      },
    },
  },
};



const Dashboard = () => {


  
  //  drowerSideTab
  const [tabNumber, setTabNumber] = useState();
  const [Sidevalue, setValueDrower] = React.useState(1);
  
  const handleChangeDrawer = (event, newValue) => {
    setValueDrower(newValue);
    console.log("SidevalueSidevalue",newValue);
  };
  // end
  const [loadingOther, setLoadingOther] = useState(false);


  const dashboard = {
    // fontFamily: "Inter",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "17px",
    color: "#000000",
    textTransform: "none",
  };
  const [showDivs, setShowDivs] = useState(false);
  const className = showDivs ? "blockStyle" : "flexStyle";
  const className2 = showDivs ? "blockS" : "blockSReverse";
  const className3 = showDivs ? "blockSs" : "blockSReversee";

  const handleClick = (tabNumber) => {
  
    setTabNumber(tabNumber)
   
    setShowDivs(true);
    setLoadingOther(true);
    setTimeout(() => {
      setLoadingOther(false); // Set loading state to false after 1 seconds
    }, 2000);
  };

  const handleClose = () => {
    setShowDivs(false);
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const values = [
    { name: "ELIMEN WICKER CHAIR", val: 70.4, color: "#1a90ff" },
    { name: "GIFT CARD", val: 50.4, color: "#FFA353" },

    { name: "MILTON DESK", val: 40.4, color: "#20C997" },
    {
      name: "KEPLING DESK",
      val: 30.4,
      color: "#FF63A5",
    },
    {
      name: "ELIMEN WICKER CHAIR",
      val: 60.4,
      color: "#00A1E4",
    },
  ];
  const topCustomer = [
    { Name: "GDN dev", NumberOfOrders: "38", },

    { Name: "Mahidul hasan", NumberOfOrders: "68", image: "/assets/images/pexels.jpg", },

    { Name: "Ismail hossain", NumberOfOrders: "78", image: "/assets/images/pexels.jpg", },

    { Name: "Jewel Kuri", NumberOfOrders: "108", image: "/assets/images/pexels.jpg", },

    { Name: "Rakibul hasan", NumberOfOrders: "18", image: "/assets/images/pexels.jpg", },

    { Name: "Imran", NumberOfOrders: "28" , image: "/assets/images/download.jpg",},

    { Name: "Mehady hasan robin", NumberOfOrders: "38" , image: "/assets/images/download.jpg",},

    { Name: "Mehady hasan robin", NumberOfOrders: "38", image: "/assets/images/download.jpg", },

    { Name: "Mehady hasan robin", NumberOfOrders: "38", image: "/assets/images/download.jpg", },

    { Name: "Mehady hasan robin", NumberOfOrders: "38", image: "/assets/images/download.jpg", },
    { Name: "Mehady hasan robin", NumberOfOrders: "38", image: "/assets/images/download.jpg", },
    { Name: "Mehady hasan robin", NumberOfOrders: "38" , image: "/assets/images/download.jpg",},
  ];
  const sortedCustomers = [...topCustomer].sort((a, b) =>b.NumberOfOrders - a.NumberOfOrders);
   
 const modifiedCustomers = sortedCustomers.map((customer, index) =>
  ({ ...customer, id: index + 1 }));


   
// Select IDs from 0 to 10
  const customersToRender = modifiedCustomers.slice(0, 10);

  const user = [
    {
      Name: "Keith Jensen ",
      activeStatus:"placed a Order",
      image: "/assets/images/download.jpg",
      time: "5 minutes ago ",
    },
    {
      Name: "Harry Simpson ",
      activeStatus:"add a product to wishlist",
      image: "/assets/images/download.jpg",
      time: "10 minutes ago ",
    },
    {
      Name: "Stephanie Marshall ",
      activeStatus:"give review on a product",
      image: "/assets/images/download.jpg",
      time: "50 minutes ago ",
    },
    {
      Name: "Timothy Moreno ",
      activeStatus:"placed a Order",
      image: "/assets/images/download.jpg",
      time: "56 minutes ago ",
    },
    {
      Name: "Keith Jensen ",
      activeStatus:"requested to Widthdraw",
      image: "/assets/images/download.jpg",
      time: "1 hours ago ",
    },
    {
      Name: "Keith Jensen ",
      activeStatus:"requested to placed a Order",
      image: "/assets/images/download.jpg",
      time: "1:10 hours ago ",
    },
    {
      Name: "Keith Jensen ",
      activeStatus:"add a product to wishlist",
      image: "/assets/images/download.jpg",
      time: "1:29 hours ago ",
    },
    {
      Name: "Keith Jensen ",
      activeStatus:"requested to placed a Order",
      image: "/assets/images/download.jpg",
      time: "2 hours ago ",
    },
  ];

  const sortUserActivity=[...user].sort((a,b)=>{
    const durationA=convertToMilliseconds(a.activeStatus);
    const durationB=convertToMilliseconds(b.activeStatus);
    return durationA - durationB;
  })

  function convertToMilliseconds(activeStatus) {
    const timeParts = activeStatus.split(" ");
    let milliseconds = 0;
  
    for (let i = 0; i < timeParts.length; i += 2) {
      const value = parseInt(timeParts[i]);
      const unit = timeParts[i + 1];
  
      switch (unit) {
        case "hours":
        case "hour":
          milliseconds += value * 3600000; // 1 hour = 3600000 milliseconds
          break;
        case "minutes":
        case "minute":
          milliseconds += value * 60000; // 1 minute = 60000 milliseconds
          break;
        case "seconds":
        case "second":
          milliseconds += value * 1000; // 1 second = 1000 milliseconds
          break;
        default:
          break;
      }
    }
  
    return milliseconds;
  }

  console.log("sortUserActivity",sortUserActivity);

  const modifyUser = sortUserActivity.map((item,indexs)=>({...item, id: indexs + 1}));


 

  const totalDeposit = [
    {
      diposit: "49,398.397 USD",
      withdraw: "",
      balance: "",
      parcent: "3.9",
      levelmonth: " This Month",
      month: "321,2.2 BDT",
      levelweek: "This week",
      week: "321,2.2 BDT",
    },
    {
      diposit: "",
      withdraw: "5939",
      balance: "",
      parcent: "1.9",
      levelmonth: "Open",
      month: "321 Order",
      levelweek: "Unfulfilled",
      week: "32 Order",
    },
    {
      diposit: "",
      withdraw: "",
      balance: "69398",
      parcent: "6.9",
      levelmonth: "Registered",
      month: "1300 People",
      levelweek: "Guest",
      week: "200 People",
    },
  ];

  // const CheckBoxData=[
  const drewerData = [
    {
      title: "Tasks",

      name: "Komende",
      OptionData: [
        { label: "option 1" },
        { label: "option 2" },
        { label: "option3" },
        { label: "option4" },
      ],

      CheckBoxData: [
        {
          lableData: "Te Starten Taak (test data)",
          date: "22march 2023",
        },
        {
          lableData: "Taak (test data)",
          date: "22march 2023",
        },
        {
          lableData: "Taak buiten de tijd (test data)",
          date: "22march 2023",
        },
        {
          lableData: "Te starten taak (test data)",
          date: "22march 2023",
        },
      ],
    },

    {
      title: "Meeting Today",

      name: "Komende 7 dagen",
      OptionData: [
        { label: "option 1" },
        { label: "option 2" },
        { label: "option3" },
        { label: "option4" },
      ],

      CheckBoxData: [
        {
          lableData: "Te Starten Taak (test data)",
          date: "22march 2023",
        },
        {
          lableData: "Taak (test data)",
          date: "22march 2023",
        },
        {
          lableData: "Taak buiten de tijd (test data)",
          date: "22march 2023",
        },
      ],
    },

    {
      title: "Calls today",

      name: "Komende 7 dagen",
      OptionData: [
        { label: "option 1" },
        { label: "option 2" },
        { label: "option3" },
        { label: "option4" },
      ],

      CheckBoxData: [
        {
          lableData: "Te Starten Taak (test data)",
          date: "22march 2023",
        },
        {
          lableData: "Taak (test data)",
          date: "22march 2023",
        },
        {
          lableData: "Taak buiten de tijd (test data)",
          date: "22march 2023",
        },
        {
          lableData: "Te starten taak (test data)",
          date: "22march 2023",
        },
      ],
    },

    {
      title: "Tasks",

      name: "Komende ",
      OptionData: [
        { label: "option 1" },
        { label: "option 2" },
        { label: "option3" },
        { label: "option4" },
      ],

      CheckBoxData: [
        {
          lableData: "Te Starten Taak (test data)",
          date: "22march 2023",
        },
        {
          lableData: "Taak (test data)",
          date: "22march 2023",
        },
      ],
    },
  ];
  const formatLabel = (label) => {
    const formattedLabel =
      label.charAt(0).toUpperCase() + label.slice(1).toLowerCase();
    return formattedLabel;
  };



  const ScroolclassName = showDivs === true ? "TopSealProd BestSellingProduct" : "TopSealProd BestSellingProduct2";


  return (
    <>
      <div
        className={"webview "}
        style={{ display: "flex", maxHeight: "88vh" }}
      >
        <div
          style={{
            width: showDivs ? "58.3%" : "100%",
            transition: "width 0.3s linear ",

            // maxHeight: "100%",
            // overflowY: "scroll"
            // marginBottom:"1000px"
          }}
        >
          <BottomNavigation
            style={{
              backgroundColor: "#ffffff",
              // position:'fixed',
              // width: showDivs  ? "55.4%" : "100%",
              height: "49px",
              transition: "width 0.3s linear ",
              zIndex: "2",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0px 15px 0px 10px",
              boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignContent: "center",
                columnGap: "30px",
              }}
            >
              <p style={dashboard}>Dashboard</p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  columnGap: "5px",
                }}
              >
                {/* <HomeIcon /> */}
                <IconButton aria-label="delete" sx={{ padding: "0px" }}>
                  <img src="/assets/images/homeTwo.svg" alt=""></img>
                </IconButton>
                <p style={{ fontSize: "14px", fontWeight: "600" }}>
                  Home{" "}
                  <span style={{ color: "#00A1E4", padding: "0px 5px" }}>
                    {" "}
                    /{" "}
                  </span>
                  <span style={{ color: "#00A1E4" }}>Dashboard</span>{" "}
                </p>
              </div>
            </div>

            <div>
              <IconButton aria-label="delete">
                <img src="/assets/images/logOut.svg" alt=""></img>
              </IconButton>
              <IconButton aria-label="delete">
                <img src="/assets/images/memory.svg" alt></img>
              </IconButton>

              <Button
                style={{
                  color: "black",
                  fontSize: "14px",
                  fontWeight: "600",
                  textTransform: "none",
                }}
                size="small"
              >
                Reports
              </Button>
              {/* <IconButton
                aria-label="delete"
                sx={{ paddingLeft: "20px" }}
                onClick={handleClick}
              >
                <img
                  style={{ height: "30px" }}
                  src="/assets/images/pls.png"
                  alt=""
                ></img>
              </IconButton> */}
            </div>
          </BottomNavigation>
          <div className={"scroolDemDashboard"}>
            <div
              style={{ margin: "17px 30px 0px 14px", paddingBottom: "200px" }}
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: " 33% 33% 33%",
                  columnGap: "10px",
                  // maxHeight: "100vh",
                }}
              >
                {totalDeposit.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      padding: "30px 10px 0px 15px ",
                      backgroundColor: "#FFFFFF",
                      border: "2px solid rgba(0, 0, 0, 0.15)",
                      borderRadius: "2px",
                      width: "100%",
                    }}
                  >
                    {item.diposit !== "" ? (
                      <>
                        <p
                          style={{
                            fontWeight: "400",
                            fontSize: "15px",
                            color: "#000000",
                            margin: "0px",
                          }}
                        >
                          Total Sale
                        </p>
                        <h2
                          style={{
                            fontWeight: "600",
                            fontSize: "20px",
                            margin: "0px",
                            paddingTop: "10px",
                          }}
                        >
                          {item.diposit} {" "}
                          <span
                            style={{
                              fontWeight: "600",
                              fontSize: "15px",
                              color: "#30DDBE",
                            }}
                          >
                            <MdArrowUpward />
                            {item.parcent}{" "}
                            <span sx={{ paddingTop: "3px" }}>%</span>
                          </span>
                        </h2>
                      </>
                    ) : item.withdraw !== "" ? (
                      <>
                        <p
                          style={{
                            fontWeight: "400",
                            fontSize: "15px",
                            color: "#000000",
                            margin: "0px",
                          }}
                        >
                          Total Orders
                        </p>
                        <h2
                          style={{
                            fontWeight: "600",
                            fontSize: "20px",
                            margin: "0px",
                            paddingTop: "10px",
                          }}
                        >
                          {item.withdraw} {" "}
                          <span
                            style={{
                              fontWeight: "600",
                              fontSize: "15px",
                              color: "#30DDBE",
                            }}
                          >
                            <MdArrowUpward />
                            {item.parcent}{" "}
                            <span sx={{ paddingTop: "3px" }}>%</span>
                          </span>
                        </h2>
                      </>
                    ) : item.balance !== "" ? (
                      <>
                        <p
                          style={{
                            fontWeight: "400",
                            fontSize: "15px",
                            color: "#000000",
                            margin: "0px",
                          }}
                        >
                          Total Customers
                        </p>
                        <h2
                          style={{
                            fontWeight: "600",
                            fontSize: "20px",
                            margin: "0px",
                            paddingTop: "10px",
                          }}
                        >
                          {item.balance} {" "}
                          <span
                            style={{
                              fontWeight: "600",
                              fontSize: "15px",
                              color: "#30DDBE",
                            }}
                          >
                            <MdArrowUpward />
                            {item.parcent}{" "}
                            <span sx={{ paddingTop: "3px" }}>%</span>
                          </span>
                        </h2>
                      </>
                    ) : (
                      ""
                    )}
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "54% 27%",
                        justifyContent: "start",
                        alignItems: "center",
                        columnGap: "10%",
                      }}
                    >
                      <div className={className}>
                        <div className={className2}>
                          <p
                            className={className3}
                            style={{
                              fontWeight: "400",
                              fontSize: "15px",
                              color: "#000000",
                              margin: "0px",
                              paddingBottom: "5px",
                            }}
                          >
                            {item.levelmonth}
                          </p>
                          <h2
                            style={{
                              fontWeight: "600",
                              fontSize: "17px",
                              margin: "0px",
                            }}
                          >
                            {item.month}
                          </h2>
                        </div>

                        <div>
                          <p
                            style={{
                              fontWeight: "400",
                              fontSize: "15px",
                              color: "#000000",
                              margin: "0px",
                              paddingBottom: "5px",
                            }}
                          >
                            {item.levelweek}
                          </p>
                          <h2
                            style={{
                              fontWeight: "600",
                              fontSize: "17px",
                              margin: "0px",
                            }}
                          >
                            {item.week}
                          </h2>
                        </div>
                      </div>

                      <div>
                        <Box
                          sx={{
                            height: "131px",
                            width: "84px",
                            border: "none",

                            overflowY: "hidden",
                          }}
                        >
                          <Bar
                            data={data}
                            options={options2}
                            width={100}
                            maxHeight={100}
                          />
                        </Box>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: " 33% 33% 33%",
                  columnGap: "10px",
                  paddingTop: "20px",
                }}
              >
                <div
                  style={{
                    border: "2px solid rgba(0, 0, 0, 0.15)",
                    borderRadius: "2px",
                    // maxHeight:'600px',
                    // height:'100%',
                    // overflowY:'scroll'
                  }}
                >
                  <div>
                    <p
                      style={{
                        paddingLeft: "17px",
                        fontWeight: "600",
                        fontSize: "15px",
                      }}
                    >
                      Best Selling Products
                    </p>
                    <p
                      style={{
                        paddingLeft: "17px",
                        fontWeight: "400",
                        fontSize: "15px",
                      }}
                    >
                      Unveiling the Hottest Best-Selling Products That
                      Everyone's
                      <span style={{ color: "#1975D2" }}> Talking About !</span>
                    </p>
                    <Box sx={{ width: "100%" }}>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                          value={value}
                          onChange={handleChange}
                          aria-label="basic tabs example"
                          style={{ padding: "0px 10px" }}
                        >
                          <Tab
                            style={{
                              fontWeight: "700",
                              fontSize: "16px",
                              padding: "10px 0px",
                              textTransform: "none",
                            }}
                            label={formatLabel("This year")}
                            {...a11yProps(0)}
                          />
                          <Tab
                            style={{
                              fontWeight: "600",
                              fontSize: "16px",
                              textTransform: "none",
                            }}
                            label={formatLabel("Progress view")}
                            {...a11yProps(1)}
                          />
                        </Tabs>
                      </Box>
                      <TabPanel
                        className={ScroolclassName}
                      
                        value={value}
                        index={0}
                      >
                        <p
                          style={{
                            fontWeight: "400",
                            fontSize: "15px",
                          }}
                        >
                          Top 10 product's Based on Sale
                        </p>
                        <div>
                          <TableContainer component={Paper}>
                            <Table
                              sx={{ minWidth: 390 }}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell>Image</StyledTableCell>
                                  <StyledTableCell align="center">
                                    Product Name
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    Number of Sales
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {sortedBestSellProd.map((row, indexProd) => (
                                  <StyledTableRow key={row.indexProd}>
                                    <StyledTableCell component="th" scope="row">
                                      <img
                                        style={{
                                          width:'32px',
                                          height:'32px',
                                          borderRadius:'100%',
                                          objectFit:'cover'
                                        }}
                                        src={row.image}
                                        alt=""
                                      ></img>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" style={{width:"46%"}}>
                                      {row.ProductName.slice(0, 7) + " ..."}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      {row.NumberofSalesL}
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </TabPanel>
                      <TabPanel
                        className={"TopSealProd"}
                        value={value}
                        index={1}
                      >
                        {values.map((item, i) => (
                          <Box key={i} sx={{ margin: "20px" }}>
                            <Typography
                              variant="subtitle1"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                margin: "0px 20px 10px 5px",
                              }}
                            >
                              <div
                                style={{
                                  fontWeight: "400",
                                  fontSize: "15px",
                                  color: "#000000",
                                }}
                              >
                                {item.name.slice(0,7)+" ..."}
                              </div>
                              <div
                                style={{
                                  fontWeight: "400",
                                  fontSize: "15px",
                                  color: "rgba(0, 0, 0, 0.44)",
                                }}
                              >
                                {" "}
                                {item.val}%
                              </div>
                            </Typography>
                            <LinearProgress
                              sx={{
                                backgroundColor: "#E0E4FF",
                                "& .MuiLinearProgress-bar": {
                                  backgroundColor: item.color,
                                },
                                height: 5,
                              }}
                              value={item.val}
                              variant="determinate"
                            />
                          </Box>
                        ))}
                      </TabPanel>
                    </Box>
                  </div>
                </div>
                <div
               
                  style={{
                    border: "2px solid rgba(0, 0, 0, 0.15)",
                    borderRadius: "2px",
                    // maxHeight:'600px',
                    // height:'100%',
                    
                   
                  }}
                >
                  <div
                    style={{
                      display:showDivs === true ?"block": "flex",
                      justifyContent:showDivs === true ?"start": "space-between",
                      padding: "10px 20px",
                    }}
                  >
                    <div>
                      <h2 style={{ fontWeight: "600", fontSize: "15px" }}>
                        Top Customer List
                      </h2>
                      <p
                        style={{
                          fontWeight: "400",
                          fontSize: "15px",
                          margin: "0px",
                        }}
                      >
                        Top 10 Customer Based on Order
                      </p>
                    </div>

                    <button  onClick={()=>handleClick(0)}
                      style={{
                        display:showDivs === true ?"none": "block",
                        fontWeight: "600",
                        fontSize: "15px",
                        border: "none",
                        height: "30px",
                        width: "82px",
                        marginRight: "0px",
                      }}
                    >
                      View All
                    </button>
                  </div>
                  <>
                    <TableContainer    className={ScroolclassName}
                 
                    component={Paper}>
                      <Table   
                     
                     sx={{ minWidth:showDivs === true ?  480 :390}}
                        aria-label="customized table"
                      >
                        <TableHead>
                          <TableRow>
                          <StyledTableCell style={{width:"20%"}}>Image</StyledTableCell>
                            <StyledTableCell  align="center">
                              Customer Name
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Number of Orders
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody >
                          {customersToRender.map((row, Customeri) => (
                            <StyledTableRow key={Customeri}>

<StyledTableCell component="th" scope="row">
                                  {row.image? <img
                                        style={{
                                          width:'32px',
                                          height:'32px',
                                          borderRadius:'100%',
                                          objectFit:'cover'
                                        }}
                                        src={row.image}
                                        alt=""
                                      ></img>: <img
                                      style={{
                                        width:'32px',
                                        height:'32px',
                                        borderRadius:'100%',
                                        objectFit:'cover'
                                      }}
                                      src="/assets/images/defaultUserIcon.jpg"
                                      alt=""
                                    ></img> }  
                                    
                                    </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                component="th"
                                scope="row"
                              >
                                {row.Name}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {row.NumberOfOrders}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                </div>
                <div
                  style={{
                    border: "2px solid rgba(0, 0, 0, 0.15)",
                    borderRadius: "2px",
                  }}
                >
                  <div
                    style={{
                      display:showDivs === true ?"block": "flex",
                      justifyContent:showDivs === true ?"start": "space-between",
                      margin: "12px 0px 0px 20px",
                    }}
                  >
                    <h2 style={{ fontWeight: "600", fontSize: "15px" }}>
                      Recent Activities{" "}
                    </h2>

                    <button  onClick={()=>handleClick(1)}
                      style={{
                        display:showDivs === true ?"none": "block",
                        fontWeight: "600",
                        fontSize: "15px",
                        border: "none",
                        height: "30px",
                        width: "82px",
                        marginRight: "20px",
                      }}
                    >
                      View All
                    </button>
                  </div>

                  <div style={{ height: "700px", overflow: "hidden" }}>
                    <>
                      {modifyUser.map((item, i) => (
                        <div
                          key={i}
                          style={{ border: "1px solid rgba(0, 0, 0, 0.13)" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "start",
                              alignItems: "center",
                              columnGap: "20px",
                              margin: "14px",
                            }}
                          >
                            {item.image ? (
                              <img
                                style={{
                                  width: "32px",
                                  height: "32px",
                                  borderRadius: "100%",
                                  objectFit: "cover",
                                }}
                                src={item.image}
                                alt=""
                              ></img>
                            ) : (
                              <img src="://static.vecteezy.com/system/resources/previews/008/442/086/original/illustration-of-human-icon-user-symbol-icon-modern-design-on-blank-background-free-vector.jpg" />
                            )}

                            <div>
                              <p
                                style={{
                                  fontWeight: "400",
                                  fontSize: "15px",
                                  margin: "0px",
                                }}
                              >
                                {item.Name} {item.activeStatus}
                              </p>
                              <span
                                style={{
                                  fontWeight: "400",
                                  fontSize: "12px",
                                  color: "rgba(0, 0, 0, 0.51)",
                                  margin: "0px",
                                }}
                              >
                                {item.time}{" "}
                              </span>
                            </div>
                          </div>
                          {/* <hr style={{ color: "rgba(0, 0, 0, 0.13)" }} /> */}
                        </div>
                      ))}
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            width: showDivs ? "42%" : "0%",
            border: "1px solid rgba(0, 0, 0, 0.22)",
            transition: "width 0.3s ease",
            // maxHeight: "100%",
            // overflowY: "scroll"
          }}
        >
          <Box
            sx={{ maxWidth: { xs: 320, sm: 480 }, bgcolor: "background.paper" }}
          >
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
                columnGap: "33px",
                height: "49px",
              }}
            >
              <Tabs
                TabIndicatorProps={{
                  style: { backgroundColor: "transparent", display: "none" },
                }}
                Sidevalue={Sidevalue}
                onChange={handleChangeDrawer}
                aria-label="scrollable force tabs example"
                // variant="scrollable"
                // scrollButtons
                // allowScrollButtonsMobile
              >
                <Tab
                  style={{
                    color: "#000000",
                    fontSize: "14px",
                    fontWeight: "600",
                    backgroundColor:
                      Sidevalue === 0 ? "#00A1E4" : "transparent",
                    color: Sidevalue === 0 ? "white" : "black",
                    textTransform: "none",
                  }}
                  label={formatLabel("Customer List")}
                  {...a11yPropsDrower(0)}
                />
                <Tab
                  style={{
                    color: "#000000",
                    fontSize: "14px",
                    fontWeight: "600",
                    backgroundColor:
                      Sidevalue === 1 ? "#00A1E4" : "transparent",
                    color: Sidevalue === 1 ? "white" : "black",
                    textTransform: "none",
                  }}
                  label={formatLabel("Recent Activities")}
                  {...a11yPropsDrower(1)}
                />
              
              </Tabs>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "20px",
                }}
              >
                <span style={{ display: "inline-block" }} onClick={handleClose}>
                  <img src="/assets/images/cross.svg" alt="Close" />
                </span>
              </div>
            </Box>

            {loadingOther ? (
              <CircularProgressWithLabel />
            ) : (
              <>
                <Box
                  style={{ paddingBottom: "60px" }}
                  className={"tab scroolDemDashboard"}
                >
                  <TabPanelDrower Sidevalue={Sidevalue} i={0}>
                  
                      <TopCustomerList topCustomerData={modifiedCustomers}/>

                  
                  </TabPanelDrower>
                  <TabPanelDrower Sidevalue={Sidevalue} i={1}>
                    <RecentActivities recentActivity={modifyUser}/>
                  </TabPanelDrower>
              
                </Box>
              </>
            )}
          </Box>
        </div>
      </div>

      <div className="mobilVied">
        <DeshBoardMobile />
      </div>
    </>
  );
};

export default Dashboard;
