

import { forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";
export default function RangeEx({setFDAte,setLDate,FDate,LDate,setSelectedDateFromUser}) {


  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <>
    {/* {value} */}
    <svg
      onClick={onClick}
      ref={ref}

      width="100"
      height="100"
      viewBox="0 0 1000 1000"
      style={{
        height: "15px",
        width: "15px",
        alignItems: "end",
        marginRight: "10px",
      }}
    >
      <path
        style={{ fill: "#7C7C7C" }}
        d="M349 838c0 17.7 14.2 32 31.8 32h262.4c17.6 0 31.8-14.3 31.8-32V642H349v196zm531.1-684H143.9c-24.5 0-39.8 26.7-27.5 48l221.3 376h348.8l221.3-376c12.1-21.3-3.2-48-27.7-48z"
      ></path>
    </svg>
    </>
  

   
  ));
  return (
    <DatePicker
      selected={FDate}
      startDate={FDate}
      endDate={LDate}

      onChange={(dates) => {
        const [start, end] = dates;
        setFDAte(start);
        setLDate(end);
        setSelectedDateFromUser([moment(start).format("MMM DD,YYYY") ,end ? moment(end).format("MMM DD,YYYY") : moment(start).format("MMM DD,YYYY")])
        
      }}
      selectsRange={true}
      customInput={<ExampleCustomInput />}

   
    />
  );
}
