import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { BottomNavigation } from "@mui/material";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Button, IconButton } from "@mui/material";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TrelloView from "./TrelloView";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { BsThreeDotsVertical } from "react-icons/bs";

import InvoicesComponent from "../products/invoices";

export default function NewAppWebsh() {
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const Projects = {
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "17px",
    color: "#000000",
  };
  const buttonStyle = {
    alignItems: "center",
    border: "none",
    textTransform: "none",
    width: "40px",
    height: "36px",
    borderRadius: "6px",
    cursor: "pointer",
    fontSize: "20px",
    fontWeight: "400",
  };

  // for box shedw
  const shadowStyle = {
    margin: "10px",
    boxShadow:
      "0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -2px rgba(0, 0, 0, .1)",
    "--tw-shadow-colored":
      "0 4px 6px -1px var(--tw-shadow-color),0 2px 4px -2px var(--tw-shadow-color)",
  };

  const Details = [
    {
      title: "Details",
      "Company name": "Let dop business",

      Address: "Myhsteret 16",
      "Postal Code": "8535 OK",
      City: "Amsterdam",
      "Province / State": "Zuid holland",
      Country: "Netherlands",
    },
    {
      title: "Contact Info",
      Email: "test@google.nl",
      Website: "www.google.nl",
      Phone: "+31 020-32647874",
      "Fax machine": "+31 020-5741126",
      Coc: "123345356",
      "Vat number": "142342435356",
      Industry: "Media",
      Sector: "Marketing",
      Size: "25",
    },
  ];

  const Summary = [
    {
      item: [
        {
          title: "Total",
          value: "0:00:00",
        },
      ],
    },
    {
      item: [
        {
          title: "Budgeted",
          value: "0:00:00",
        },
      ],
    },
    {
      item: [
        {
          title: "Start Date",
          value: "08-14-2021",
        },
        {
          title: "Due Date",
          value: "08-31-2021",
        },
      ],
    },
    {
      item: [
        {
          title: "Billing Type",
          value: "Hourly",
        },
        {
          title: "Rate",
          value: "$50.00",
        },
      ],
    },
    {
      item: [
        {
          title: "Estimated Hors",
          value: "60 Hours",
        },
      ],
    },
    {
      item: [
        {
          title: "Time Spent",
          value: "6 hrs : 45 mins",
        },
      ],
    },
  ];

  function TabPanel(props) {
    const { children, valueOther, i, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={valueOther !== i}
        id={`simple-tabpanel-two-${i}`}
        aria-labelledby={`simple-tab-two-${i}`}
        {...other}
      >
        {valueOther === i && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function TabPanelComponent(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`custom-tabpanell-${index}`}
        aria-labelledby={`custom-tabb-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  TabPanelComponent.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yPropss(index) {
    return {
      id: `custom-tabb-${index}`,
      "aria-controlss": `custom-tabpanell-${index}`,
    };
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    i: PropTypes.number.isRequired,
    valueOther: PropTypes.number.isRequired,
  };

  const [values, setValuee] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValuee(newValue);
  };
  const formatLabel = (label) => {
    const formattedLabel =
      label.charAt(0).toUpperCase() + label.slice(1).toLowerCase();
    return formattedLabel;
  };

  const [anchorElMenue, setAnchorElMenue] = useState(null);
  const openIconMenu = Boolean(anchorElMenue);
  const handleClickMenue = (event) => {
    setAnchorElMenue(event.currentTarget);

  };
  const handleCloseMenue = () => {
    setAnchorElMenue(null);
  };



  return (
    <Box sx={{ display: "flex", overflowX: "scroll" }}>
      <div
        className={"cardScrool"}
        style={{
          width: open ? "58.3%" : "100%",
          transition: "width 0.3s linear",
        }}
      >
        <BottomNavigation
          style={{
            backgroundColor: "#ffffff",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingLeft: "3%",
            borderBottom: "2px solid rgba(0, 0, 0, 0.15)",
            maxHeight: "50px",
          }}
        >
          <div
            style={{
              display: "flex",
              // justifyContent: "start",
              justifyContent: "center",
              alignContent: "center",
              columnGap: "30px",
            }}
          >
            <p style={Projects}>Projects</p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                columnGap: "20px",
              }}
            >
              <img src="/assets/images/home.svg" />
              <p style={{ fontSize: "14px", fontWeight: "600" }}>
                Home <span style={{ color: "#00A1E4" }}> / </span>
                <span style={{ color: "#00A1E4" }}>Projects </span>{" "}
                <span style={{ color: "#00A1E4" }}> / </span>
                <span style={{ color: "#00A1E4" }}> New app websh</span>{" "}
              </p>
            </div>
          </div>

          <div>
            <IconButton aria-label="delete"></IconButton>
            <IconButton aria-label="delete">
              <img src="/assets/images/menuee.svg"></img>
            </IconButton>
            <IconButton aria-label="delete">
              <img src="/assets/images/net.png"></img>
            </IconButton>

            <Button onClick={handleDrawerOpen} style={buttonStyle} size="small">
              <AddIcon
                style={{
                  backgroundColor: "#00AB42",
                  height: "15px",
                  width: "15px",
                  borderRadius: "100%",
                  color: "white",
                }}
                fontSize="small"
              />
            </Button>
          </div>
        </BottomNavigation>
        <div>
          <TrelloView />
        </div>
      </div>

      <div
        style={{
          width: open ? "40%" : "0%",
          transition: "width 0.3s linear ",
          boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
          position: "absolute",
          right: "0",
          background: "white",
          minHeight: "100%",
          borderRadius: "2px",
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            columnGap: "33px",
          }}
        >
          <Tabs
            TabIndicatorProps={{
              style: { backgroundColor: "transparent", display: "none" },
            }}
            value={values}
            onChange={handleTabChange}
            aria-label="scrollable force tabs example"
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
          >
            <Tab
              style={{
                color: "#000000",
                fontSize: "14px",
                fontWeight: "600",
                backgroundColor: values === 0 ? "#00A1E4" : "transparent",
                color: values === 0 ? "white" : "black",
                textTransform: "none",
              }}
              label={formatLabel("Summary")}
              {...a11yPropss(0)}
            />
            <Tab
              style={{
                color: "#000000",
                fontSize: "14px",
                fontWeight: "600",
                backgroundColor: values === 1 ? "#00A1E4" : "transparent",
                color: values === 1 ? "white" : "black",
                textTransform: "none",
              }}
              label={formatLabel("Notes")}
              {...a11yPropss(1)}
            />
            <Tab
              style={{
                color: "#000000",
                fontSize: "14px",
                fontWeight: "600",
                backgroundColor: values === 2 ? "#00A1E4" : "transparent",
                color: values === 2 ? "white" : "black",
                textTransform: "none",
              }}
              label={formatLabel("Documents")}
              {...a11yPropss(2)}
            />
            <Tab
              style={{
                color: "#000000",
                fontSize: "14px",
                fontWeight: "600",
                backgroundColor: values === 3 ? "#00A1E4" : "transparent",
                color: values === 3 ? "white" : "black",
                textTransform: "none",
              }}
              label={formatLabel("Projects")}
              {...a11yPropss(3)}
            />
            <Tab
              style={{
                color: "#000000",
                fontSize: "14px",
                fontWeight: "600",
                backgroundColor: values === 4 ? "#00A1E4" : "transparent",
                color: values === 4 ? "white" : "black",
                textTransform: "none",
              }}
              label={formatLabel("Project activities")}
              {...a11yPropss(4)}
            />
          </Tabs>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "20px",
            }}
          >
            <IconButton
              id="basic-button"
              aria-controls={openIconMenu ? "basic-menu-two" : undefined}
              aria-haspopup="true"
              aria-expanded={openIconMenu ? "true" : undefined}
              onClick={handleClickMenue}
            >
              <BsThreeDotsVertical style={{ color: "#000000" }} />
            </IconButton>
            <span
              style={{ display: "inline-block" }}
              onClick={handleDrawerClose}
            >
              <img src="/assets/images/cross.svg" alt="Close" />
            </span>
          </div>
          <Menu
        id="basic-menu"
        anchorEl={anchorElMenue}
        open={openIconMenu}
        onClose={handleCloseMenue}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleCloseMenue}>Option 1</MenuItem>
        <MenuItem onClick={handleCloseMenue}>Option 2</MenuItem>
        <MenuItem onClick={handleCloseMenue}>Option 3</MenuItem>
      </Menu>
        </Box>

        <>
          <TabPanelComponent className={"tab"} value={values} index={0}>
            <span className={"box"}>
              <div>
                <div
                  style={{
                    paddingLeft: "20px",
                  }}
                >
                  <h2
                    style={{
                      fontWeight: "700",
                      fontSize: "20px",
                    }}
                  >
                    Social Media Planning
                  </h2>
                </div>
                <div
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.25) 0px 1px 2px",
                    padding: "0px 20px",
                  }}
                >
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "48% 48%",
                      alignItems: "center",
                      justifyContent: "center",
                      columnGap: "3%",
                    }}
                  >
                    {Summary.map((item, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          margin: "10px 0px",
                          textAlign: "center",
                          borderRadius: "2px",
                          boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
                          paddingBottom: "25px",
                          height: "107px",
                          padding: "0px 15px",
                        }}
                      >
                        {item.item.map((data, i) =>
                          data.title !== "Total" &&
                          data.title !== "Budgeted" ? (
                            <div style={{}} key={i}>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "700",
                                  color: "black",
                                  margin: "0px",
                                  textAlign: "left",
                                  paddingTop: "24px",
                                }}
                              >
                                {data.title}
                              </p>
                              <h2
                                style={{
                                  textAlign: "start",
                                  fontSize: "16px",
                                  fontWeight: "700",
                                  color: "black",
                                  margin: "0px",
                                }}
                              >
                                {data.value}
                              </h2>
                            </div>
                          ) : (
                            <div key={i}>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "700",
                                  color: "rgba(0, 0, 0, 0.50)",
                                  margin: "0px",
                                  textAlign: "left",
                                  paddingLeft: "63px",
                                  paddingTop: "24px",
                                }}
                              >
                                {data.title}
                              </p>
                              <h2
                                style={{
                                  textAlign: "start",
                                  fontSize: "16px",
                                  fontWeight: "700",
                                  color: "black",
                                  margin: "0px",
                                  paddingLeft: "63px",
                                }}
                              >
                                {data.value}
                              </h2>
                            </div>
                          )
                        )}
                      </div>
                    ))}
                  </div>
                </div>

                <div
                  style={{
                    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                    paddingTop: "20px",
                    marginTop: "30px",
                    paddingBottom: "30px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      columnGap: "10px",
                      alignItems: "center",
                      paddingBottom: "20px",
                      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                      paddingLeft: "14px",
                    }}
                  >
                    <img src="/assets/images/clip.png" alt="" />
                    <span style={{ fontWeight: "700", fontSize: "14px" }}>
                      Project Breif
                    </span>
                  </div>

                  <h3
                    style={{
                      paddingLeft: "40px",
                      fontSize: "16px",
                      fontWeight: "700",
                      color: "rgba(0, 0, 0, 0.50)",
                    }}
                  >
                    Develop a new WordPress theme. replace the current theme.
                  </h3>

                  <ul style={{ paddingLeft: "85px" }}>
                    <Grid
                      item
                      xs={4}
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        color: "rgba(0, 0, 0, 0.50)",
                        // listStyleType: "none",
                        paddingLeft: "0px",
                      }}
                    >
                      <li style={{ paddingBottom: "10px" }}>
                        Morden looking designs with fresh vibrant colours
                      </li>
                      <li style={{ paddingBottom: "10px" }}>
                        Concepts of speed and fast
                      </li>
                      <li style={{ paddingBottom: "10px" }}>
                        Must include our branding colours
                      </li>
                      <li style={{ paddingBottom: "10px" }}>
                        Provide images in jpg and png
                      </li>
                    </Grid>
                  </ul>
                  <h3
                    style={{
                      paddingLeft: "40px",
                      fontSize: "16px",
                      fontWeight: "700",
                      color: "#000",
                    }}
                  >
                    Features Requirements
                  </h3>
                  <ul style={{ paddingLeft: "85px" }}>
                    <Grid
                      item
                      xs={4}
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        color: "rgba(0, 0, 0, 0.50)",
                        // listStyleType: "none",
                        paddingLeft: "0px",
                      }}
                    >
                      <li style={{ paddingBottom: "10px" }}>
                        Account Creation & Management
                      </li>
                    </Grid>
                  </ul>
                </div>
              </div>
            </span>
          </TabPanelComponent>
          <TabPanelComponent className={"tab"} value={values} index={1}>
            <InvoicesComponent />
          </TabPanelComponent>
          <TabPanelComponent className={"Othertab"} value={values} index={2}>
            <span className={"box"}>
              {Details.map((item, index) => (
                <div key={index} style={shadowStyle}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      columnGap: "2%",
                      marginLeft: "12px",
                      paddingBottom: "10px",
                      borderBottom: "1px solid rgba(0,0,0,.1)",
                    }}
                  >
                    <img src="/assets/images/home.svg" />
                    <p style={{ fontSize: "14px", fontWeight: "700" }}>
                      {item.title}
                    </p>
                  </div>
                  <div>
                    <ul
                      style={{
                        paddingLeft: "42px",
                        marginTop: "0px",
                        paddingBottom: "15px",
                      }}
                    >
                      {Object.entries(item).map(
                        ([key, value]) =>
                          key !== "title" && (
                            <li
                              key={key}
                              style={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                                columnGap: "15%",
                                color: "rgba(0,0,0,.76)",
                                paddingTop: "10px",
                              }}
                            >
                              <div style={{ width: "30%" }}>
                                <strong
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                  }}
                                >
                                  {key}
                                </strong>
                              </div>
                              <div>
                                <span
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                  }}
                                >
                                  {value}
                                </span>
                              </div>
                            </li>
                          )
                      )}
                    </ul>
                  </div>
                </div>
              ))}
            </span>
          </TabPanelComponent>
          <TabPanelComponent value={values} index={3}>
            Projects
          </TabPanelComponent>
          <TabPanelComponent value={values} index={4}>
            Tickets
          </TabPanelComponent>
        </>
      </div>
    </Box>
  );
}
