import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Slider from "@mui/material/Slider";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Box,
  Button,
  Paper,
  IconButton,
  Typography,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@mui/material";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { BsThreeDotsVertical } from "react-icons/bs";

const BoardContainer = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  padding: "16px",
});

const ListContainer = styled(Paper)({
  width: "300px",
  marginRight: "16px",
  padding: "16px",
});

const Card = styled(Paper)({
  marginBottom: "8px",
  padding: "8px",
});

const TrelloViewTest = () => {
  const columnName = [
    {
      name: "Open",
      OpenCar: [
        {
          projectName: "hello",
          OngoingName: "Ongoing",
          value: "0",
          subTask: [],
        },
      ],
    },
    {
      name: "Planning",
      OpenCar: [
        {
          projectName: "Analyze social media performance",
          OngoingName: "Ongoing",
          value: "50",
          subTask: [],
        },
      ],
    },
    {
      name: "Research & Analysis",
      OpenCar: [
        {
          subTask: [],
        },
      ],
    },
  ];

  const [columns, setColumns] = useState(columnName);

  const [newColumnName, setNewColumnName] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDialogOpenCard, setIsDialogOpenCard] = useState(false);

  const [deleteColumnIndex, setDeleteColumnIndex] = useState(-1);

  const addColumn = () => {
    if (newColumnName.trim() === "") {
      return;
    }

    const newColumn = newColumnName;

    setColumns([...columns, { name: newColumn, OpenCar: [] }]);
    // columns.push({ name: newColumn });
    setNewColumnName("");
    setIsTextFieldVisible(false);
  };

  const [cards, setCards] = useState(columnName);

  // const [cards,setCards ] = useState(columnName.OpenCar);
  const addCards = (index) => {
    if (
      newProjectName.trim() === "" ||
      newOngoingName.trim() === "" ||
      progressBarRatio.trim() === ""
    ) {
      return;
    }
    const newCardProject = newProjectName;
    const newCardOngoing = newOngoingName;
    const ratioNumber = progressBarRatio;
    const tempColumns = [...columns];

    tempColumns[index].OpenCar = [
      ...tempColumns[index].OpenCar,
      {
        projectName: newCardProject,
        OngoingName: newCardOngoing,
        value: ratioNumber,
      },
    ];
    console.log("temp", tempColumns);
    setColumns(tempColumns);

    console.log("setCards", cards);

    const newProjectNameVisible = [...projectNameVisible];
    const newOngoingNameVisible = [...ongoingNameVisible];
    const newprogressBarRatioVisible = [...progressBarRatioVisible];

    newProjectNameVisible[index] = false;
    newOngoingNameVisible[index] = false;
    newprogressBarRatioVisible[index] = false;

    setProjectNameVisible(newProjectNameVisible);
    setOngoingNameVisible(newOngoingNameVisible);
    setprogressBarRatioVisible(newprogressBarRatioVisible);

    setNewProjectName("");
    setNewOngoingName("");
    setProgressBarRatio("");
  };

  const cancel = () => {
    setNewColumnName("");
    setIsTextFieldVisible(false);
  };
  const cancelInputFild = (index) => {
    const newProjectNameVisible = [...projectNameVisible];
    const newOngoingNameVisible = [...ongoingNameVisible];
    const newprogressBarRatioVisible = [...progressBarRatioVisible];

    newProjectNameVisible[index] = false;
    newOngoingNameVisible[index] = false;
    newprogressBarRatioVisible[index] = false;

    setProjectNameVisible(newProjectNameVisible);
    setOngoingNameVisible(newOngoingNameVisible);
    setprogressBarRatioVisible(newprogressBarRatioVisible);

    setNewProjectName("");
    setNewOngoingName("");
    setProgressBarRatio("");
  };

  const handleInputChange = (event) => {
    setNewColumnName(event.target.value);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setIsDialogOpenCard(false);
    setDeleteColumnIndex(-1);
    setNewColumnName("");
  };

  const handleDeleteColumn = (index) => {
    setDeleteColumnIndex(index);
    setIsDialogOpen(true);
  };
  const [DeleteCardIndex, setDeleteCardIndex] = useState({
    parentIndex: -1,
    childIndex: -1,
  });
  const handleDeleteCard = (parentIndex, childIndex) => {
    setDeleteCardIndex({ parentIndex, childIndex });
    setIsDialogOpenCard(true);
  };

  const confirmDeleteColumn = () => {
    const updatedColumns = [...columns];
    updatedColumns.splice(deleteColumnIndex, 1);
    setColumns(updatedColumns);
    handleDialogClose();
  };

  const confirmDeleteCard = () => {
    console.log(
      "test",
      DeleteCardIndex.parentIndex,
      DeleteCardIndex.childIndex
    );
    const updatedColumns = [...columns];
    updatedColumns[DeleteCardIndex.parentIndex].OpenCar.splice(
      DeleteCardIndex.childIndex,
      1
    );

    setColumns(updatedColumns);
    handleDialogClose();
  };

  //   menue
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElCard, setAnchorElCard] = React.useState(null);
  const openMenue = Boolean(anchorEl);
  const openMenueCard = Boolean(anchorElCard);
  const [menuIndex, setMenuIndex] = useState(0);
  const [cardIndex, setCardIndex] = useState(0);
  const handleClickMenue = (event, index) => {
    setMenuIndex(index);
    setIsEditMode(true);
    setAnchorEl(event.currentTarget);
  };
  const handleClickMenuee = (event, index) => {
    setMenuIndex(index);
    setIsEditMode(true);
    // setAnchorEl(event.currentTarget);
  };
  const handleClickCard = (event, index) => {
    setCardIndex(index);
    console.log("cardIndexDilog", cardIndex);
    setAnchorElCard(event.currentTarget);
  };
  const handleCloseMenue = () => {
    setAnchorEl(null);
  };
  const handleCloseCard = () => {
    setAnchorElCard(null);
  };

  // today

  const [isTextFieldVisible, setIsTextFieldVisible] = useState(false);
  const handleAddColumnClick = () => {
    setIsTextFieldVisible(true);
  };

  // projectName
  const [newProjectName, setNewProjectName] = useState("");
  const [newOngoingName, setNewOngoingName] = useState("");
  const [progressBarRatio, setProgressBarRatio] = useState("");

  const handleProgressChange = (e) => {
    let value = e.target.value;

    // Remove non-numeric characters
    value = value.replace(/[^0-9]/g, "");

    // Check if the value is empty
    if (value === "") {
      setProgressBarRatio("");
    } else {
      // Convert the value to a number
      const numberValue = parseInt(value, 10);

      // Check if the number is within the desired range
      if (numberValue >= 0 && numberValue <= 100) {
        setProgressBarRatio(numberValue.toString());
      }
    }
  };

  // subTask
  
  const [subTaskNameIndex, setsubTaskNameIndex] = useState(false);
  const [SubTaskVisible, setSubTaskVisible] = useState([]);
  const [SubTaskName, setNewSubTaskName] = useState("");
  console.log("SubTaskName", SubTaskName);

  const viewInputFieldSubTask = (parentI, childI) => {
    // console.log("subtaskIndexParent",parentI, "subTchild",childI);
    const newSubTaskVisible = [...SubTaskVisible];
    newSubTaskVisible.fill(false);

   newSubTaskVisible[parentI] = true;

   

    console.log("p",parentI);
    console.log("c",childI);
    setSubTaskVisible(newSubTaskVisible);

    
  };
  const cancelInputFildSubTaskName = (index) => {
    const newSubTaskVisible = [...SubTaskVisible];

    newSubTaskVisible[index] = false;

    setSubTaskVisible(newSubTaskVisible);

    setNewSubTaskName("");
  };

  const addSubTaskName = (index) => {
    if (SubTaskName.trim() === "") {
      return;
    }
    const newSubTaskName = SubTaskName;

    const tempColumns = [...columns];

    tempColumns[index].OpenCar[0].subTask = [
      ...tempColumns[index].OpenCar[0].subTask,
      {
        SubTaskName: newSubTaskName,
      },
    ];

    console.log("temp", tempColumns);
    setColumns(tempColumns);

    console.log("setCards", cards);

    const newSubTaskVisible = [...SubTaskVisible];

    newSubTaskVisible[index] = false;

    setSubTaskVisible(newSubTaskVisible);
    setNewSubTaskName("");
  };

  // end

  const [projectNameVisible, setProjectNameVisible] = useState([]);
  const [ongoingNameVisible, setOngoingNameVisible] = useState([]);
  const [progressBarRatioVisible, setprogressBarRatioVisible] = useState([]);

  const viewInputField = (index) => {
    const newProjectNameVisible = [...projectNameVisible];
    const newOngoingNameVisible = [...ongoingNameVisible];
    const newprogressBarRatioVisible = [...progressBarRatioVisible];

    newProjectNameVisible.fill(false);
    newOngoingNameVisible.fill(false);
    newprogressBarRatioVisible.fill(false);

    newProjectNameVisible[index] = true;
    newOngoingNameVisible[index] = true;
    newprogressBarRatioVisible[index] = true;

    setProjectNameVisible(newProjectNameVisible);
    setOngoingNameVisible(newOngoingNameVisible);
    setprogressBarRatioVisible(newprogressBarRatioVisible);

    console.log("index", index);
  };

  const [isEditMode, setIsEditMode] = useState(false);

  const [projectNameIndex, setprojectNameIndex] = useState(false);
  return (
    <div>
      <BoardContainer
        className={"parent"}
        style={{ border: "none", marginTop: "10px" }}
      >
        {columns.map((column, index) => (
          <ListContainer className={"child"} style={{ width: "300px" }} key={index}>
            <div 
              style={{
                display: "grid",
                gridTemplateColumns: "90% 20%",
                alignItems: "center",
                marginBottom: "10px",
                backgroundColor: "white",
                cursor: "pointer",
              }}
            >
              {isEditMode && index === menuIndex ? (
                <TextField
                  size="small"
                  autoFocus
                  label="Column Name"
                  value={column.name}
                  onChange={(e) => {
                    const tempColumns = [...columns];
                    tempColumns[menuIndex].name = e.target.value;
                    setColumns(tempColumns);
                  }}
                  onBlur={() => setIsEditMode(false)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      // 👇 Get input value
                      setIsEditMode(false);
                    }
                  }}
                  fullWidth
                />
              ) : (
                <Typography
                  onClick={(e) => handleClickMenuee(e, index)}
                  variant="h6"
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    paddingLeft: "10px",
                  }}
                >
                  {column.name}
                </Typography>
              )}

              <div>
                <IconButton
                  style={{ fontSize: "17px", fontWeight: "2000" }}
                  id="basic-button"
                  aria-controls={openMenue ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openMenue ? "true" : undefined}
                  onClick={(e) => handleClickMenue(e, index)}
                >
                  <BsThreeDotsVertical />
                </IconButton>

                <Menu
                  id="demo-positioned-menu"
                  aria-labelledby="demo-positioned-button"
                  anchorEl={anchorEl}
                  open={openMenue}
                  onClose={handleCloseMenue}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <MenuItem onClick={handleCloseMenue}>
                    <Button
                      style={{ textTransform: "none" }}
                      onClick={() => handleDeleteColumn(menuIndex)}
                    >
                      Delete
                    </Button>
                  </MenuItem>
                </Menu>
              </div>
            </div>
            <div className={"cardScrool"} 
             sx={{
              my: 1,
              scrollbarWidth: "thin",
              "&::-webkit-scrollbar": {
                width: "0.4rem",
                display: "block",
              },
              "&::-webkit-scrollbar-track": {
                background: "#f1f1f1",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#888",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#555",
              },
            }}
              style={{
                // overflowY: "scroll",
                overflowX: "hidden",
                height: "97vh",
                paddingBottom: "100px",
              }}
             
            >
              {column.OpenCar && column.OpenCar.length !== 0 ? (
                column.OpenCar.map((item, i) => (
                  <>
                    <Card className={"cardChild"} key={i}>
                      <div
                        style={{
                          borderBottom: " 2px dashed rgba(217, 217, 217, 0.50)",
                          height: SubTaskVisible ? "170px" : "230px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "start",
                              alignItems: "center",
                              columnGap: "8px",
                            }}
                          >
                            <img src="/assets/images/tickRound.png" alt="" />
                            <p
                              style={{
                                padding: "0px",
                                margin: "0px",
                                fontWeight: "600",
                                fontSize: "15px",
                                fontFamily: "Inter, sans-serif",
                              }}
                            >
                              {projectNameIndex.parentIndex === index &&
                              projectNameIndex.childIndex === i ? (
                                <TextField
                                  size="small"
                                  autoFocus
                                  label="Project Name"
                                  value={item.projectName}
                                  onChange={(e) => {
                                    const tempprojectName = [...columns];
                                    tempprojectName[
                                      projectNameIndex.parentIndex
                                    ].OpenCar[
                                      projectNameIndex.childIndex
                                    ].projectName = e.target.value;
                                    setColumns(tempprojectName);
                                  }}
                                  onBlur={() =>
                                    setprojectNameIndex({
                                      parentIndex: -1,
                                      childIndex: -1,
                                    })
                                  }
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      // 👇 Get input value
                                      setprojectNameIndex({
                                        parentIndex: -1,
                                        childIndex: -1,
                                      });
                                    }
                                  }}
                                  fullWidth
                                />
                              ) : (
                                <Typography
                                  onClick={(e) =>
                                    setprojectNameIndex({
                                      parentIndex: index,
                                      childIndex: i,
                                    })
                                  }
                                  variant="h6"
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "600",
                                    paddingLeft: "10px",
                                    width: "162px",
                                  }}
                                >
                                  {item.projectName}
                                </Typography>
                              )}
                              {/* projectN */}
                            </p>
                          </div>
                          <div>
                            <IconButton
                              style={{ fontSize: "17px", fontWeight: "2000" }}
                              id="basic-button"
                              aria-controls={
                                openMenueCard ? "basic-menu" : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={openMenueCard ? "true" : undefined}
                              onClick={(e) => handleClickCard(e, index)}
                            >
                              <BsThreeDotsVertical />
                            </IconButton>

                            <Menu
                              id="demo-positioned-menu"
                              aria-labelledby="demo-positioned-button"
                              anchorEl={anchorElCard}
                              open={openMenueCard}
                              onClose={handleCloseCard}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                            >
                              <MenuItem onClick={handleCloseCard}>
                                <Button
                                  style={{ textTransform: "none" }}
                                  // workCardDlt
                                  onClick={() => handleDeleteCard(cardIndex, i)}
                                >
                                  Delete
                                </Button>
                              </MenuItem>
                            </Menu>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                            columnGap: "15px",
                            margin: "30px 0px 15px 0px",
                          }}
                        >
                          <img src="/assets/images/uLogo.png" alt="" />
                          <p
                            style={{
                              fontSize: "15px",
                              fontFamily: "Inter, sans-serif",
                              fontWeight: "600",
                              margin: "0px",
                              padding: "0px",
                            }}
                          >
                            {item.OngoingName}
                          </p>
                        </div>
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "70% 22%",
                            columnGap: "12%",
                            alignItems: "center",
                            margin: "0px 10px",
                          }}
                        >
                          <Box width="100%">
                            <Slider
                              disabled={item.value === "0" ? true : false}
                              // color={item.value === 0? 'transparent' : '#00AB42'}
                              defaultValue={item.value}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              color="primary"
                            />
                          </Box>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "start",
                              alignItems: "center",
                              columnGap: "10px",
                            }}
                          >
                            <img src="/assets/images/msgg.png" alt="" />
                            <img src="/assets/images/fileAttach.png" alt="" />
                          </div>
                        </div>
                      </div>

                      {item.subTask && item.subTask.length !== 0
                        ? item.subTask.map((sub, taskIndex) => (

                          subTaskNameIndex.parentIndex === index && subTaskNameIndex.childIndex === i && subTaskNameIndex.childIndex2=== taskIndex?(
                          // subTaskNameIndex.childIndex2=== taskIndex?(
                            <TextField key={taskIndex}
                            size="small"
                            autoFocus
                            label="Subtask Name"
                            value={sub.SubTaskName}
                            onChange={(e) => {
                              const tempSubTaskName = [...columns];
                              tempSubTaskName[subTaskNameIndex.parentIndex].OpenCar[
                                subTaskNameIndex.childIndex
                              ].subTask[subTaskNameIndex.childIndex2].SubTaskName = e.target.value;
                            
                              setNewSubTaskName(tempSubTaskName);
                            }}
                            onBlur={() =>
                              setsubTaskNameIndex({
                                parentIndex: -1,
                                childIndex: -1,
                                childIndex2: -1,
                              })
                            }
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                // 👇 Get input value
                                setsubTaskNameIndex({
                                  parentIndex: -1,
                                  childIndex: -1,
                                  childIndex2: -1,
                                });
                              }
                            }}
                            fullWidth
                          /> 
                          ):(

                            <Typography
                            onClick={(e) =>
                              
                              setsubTaskNameIndex({
                                parentIndex: index,
                                childIndex: i,
                                childIndex2: taskIndex,
                              })
                            
                            }
                            variant="h6"
                            style={{
                              fontSize: "16px",
                              fontWeight: "600",
                              paddingLeft: "10px",
                              width: "162px",
                            }}
                          >
                            {sub?.SubTaskName}
                          </Typography>
                          )
                          
                          ))
                        : ""}

                      <div style={{ textAlign: "center", marginTop: "10px" }}>
                        <button
                          style={{
                            fontSize: "15px",
                            fontWeight: "600",
                            color: "#00A1E4",
                            height: "21px",
                            widows: "69px",
                            border: "none",
                          }}
                          onClick={() => viewInputFieldSubTask(index ,i )}
                        >
                          <span>+ </span>
                          Subtask
                        </button>
                      </div>

                      {SubTaskVisible[index] && (
                        <div style={{ padding: "20px 15px 0px 15px" }}>
                          <div style={{ display: "block" }}>
                            <TextField
                              size="small"
                              autoFocus
                              label="Sub Task Name"
                              value={SubTaskName}
                              style={{ backgroundColor: "white" }}
                              onChange={(e) =>
                                setNewSubTaskName(e.target.value)
                              }
                              // onBlur={() => setProjectNameVisible(false)}
                              fullWidth
                            />
                          </div>

                          <div
                            style={{
                              textAlign: "start",
                              paddingBottom: "20px",
                            }}
                          >
                            <Button
                              variant="contained"
                              style={{ marginTop: "10px", cursor: "pointer" }}
                              onClick={() => addSubTaskName(index)}
                            >
                              Add
                            </Button>
                            <Button
                              style={{
                                marginTop: "10px",
                                marginLeft: "10px",
                                cursor: "pointer",
                              }}
                              onClick={() => cancelInputFildSubTaskName(index)}
                            >
                              <ClearIcon />
                            </Button>
                          </div>
                        </div>
                      )}
                    </Card>
                  </>
                ))
              ) : (
                <></>
              )}
              <div
                style={{
                  textAlign: "center",
                  marginTop: "30px",
                  width: "300px",
                }}
              >
                <button
                  style={{
                    fontSize: "17px",
                    fontWeight: "600",
                    color: "#rgba(0, 0, 0, 0.50)",
                    height: "25px",
                    widows: "87px",
                    border: "none",
                  }}
                  onClick={() => viewInputField(index)}
                >
                  <span>+ </span>
                  Add Task
                </button>
              </div>

              {projectNameVisible[index] &&
                ongoingNameVisible[index] &&
                progressBarRatioVisible[index] && (
                  <div style={{ padding: "20px 15px" }}>
                    <div style={{ display: "block" }}>
                      <TextField
                        size="small"
                        autoFocus
                        label="Project Name"
                        value={newProjectName}
                        style={{
                          marginBottom: "20px",
                          backgroundColor: "white",
                        }}
                        onChange={(e) => setNewProjectName(e.target.value)}
                        // onBlur={() => setProjectNameVisible(false)}
                        fullWidth
                      />
                      <TextField
                        size="small"
                        autoFocus
                        label="Ongoing Name"
                        style={{
                          backgroundColor: "white",
                          marginBottom: "20px",
                        }}
                        value={newOngoingName}
                        onChange={(e) => setNewOngoingName(e.target.value)}
                        // onBlur={() => setOngoingNameVisible(false)}
                        fullWidth
                      />
                      <TextField
                        size="small"
                        autoFocus
                        label="progress bar ratio"
                        type="number"
                        style={{ backgroundColor: "white" }}
                        value={progressBarRatio}
                        // onChange={(e) => setProgressBarRatio(e.target.value )}
                        onChange={handleProgressChange}
                        // onBlur={() => setOngoingNameVisible(false)}
                        fullWidth
                        inputProps={{
                          min: 0,
                          max: 100,
                        }}
                      />
                    </div>

                    <div
                      style={{
                        textAlign: "start",
                        paddingBottom: "20px",
                      }}
                    >
                      <Button
                        variant="contained"
                        style={{ marginTop: "10px", cursor: "pointer" }}
                        onClick={() => addCards(index)}
                      >
                        Add
                      </Button>
                      <Button
                        style={{
                          marginTop: "10px",
                          marginLeft: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => cancelInputFild(index)}
                      >
                        <ClearIcon />
                      </Button>
                    </div>
                  </div>
                )}
            </div>
          </ListContainer>
        ))}
        <ListContainer
         
          style={{
            textAlign: "center",
            backgroundColor: "white",
            marginTop: "32px",
          }}
        >
          <div
            style={{
              margin: "20px 0px",
              backgroundColor: isTextFieldVisible ? "#ECECEC" : "white",
              width: "300px",
            }}
          >
            <Button
              style={{ marginTop: "20px" }}
              variant="contained"
              onClick={handleAddColumnClick}
            >
              Add Column
            </Button>

            {isTextFieldVisible && (
              <div
                style={{ width: "100%", marginTop: "20px", display: "block" }}
              >
                <TextField
                  style={{ backgroundColor: "transparent" }}
                  label="Add another list"
                  type="search"
                  variant="filled"
                  value={newColumnName}
                  onChange={handleInputChange}
                />
                <div
                  style={{
                    textAlign: "start",
                    marginLeft: "41px",
                    paddingBottom: "20px",
                  }}
                >
                  <Button
                    variant="contained"
                    style={{ marginTop: "10px", cursor: "pointer" }}
                    onClick={addColumn}
                  >
                    Add
                  </Button>
                  <Button
                    style={{
                      marginTop: "10px",
                      marginLeft: "10px",
                      cursor: "pointer",
                    }}
                    onClick={cancel}
                  >
                    <ClearIcon />
                  </Button>
                </div>
              </div>
            )}
          </div>

          <Dialog open={isDialogOpen} onClose={handleDialogClose}>
            <>
              <DialogTitle>Confirm Deletion</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to delete this column?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDialogClose}>Cancel</Button>
                <Button onClick={confirmDeleteColumn} color="error">
                  Confirm
                </Button>
              </DialogActions>
            </>
          </Dialog>
          <Dialog open={isDialogOpenCard} onClose={handleDialogClose}>
            <>
              <DialogTitle>Confirm Deletion</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to delete this card?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDialogClose}>Cancel</Button>
                <Button
                  onClick={() => confirmDeleteCard(DeleteCardIndex)}
                  color="error"
                >
                  Confirm
                </Button>
              </DialogActions>
            </>
          </Dialog>
        </ListContainer>
      </BoardContainer>
    </div>
  );
};

export default TrelloViewTest;
