const orders = [
    {
        order:'1232',
        date:"jun 27,2023",
        customer:"Erica Viktrup",
        channel:"",
        total:"$3,346.43",
        PaymentStatus:"paid",
        fulfillmentStatus:"unFulfilled",
        items:"3 item",
        deliveryStatus:"In Transit",
        deliveryMethod:"Pickup",
        itemProd:[
            {
              img:"/assets/images/sof1.jpg",
              name:"Table",
              Squ:"GDN-Prod#1",
              quantity:"x 2"  
            },{
              img:"/assets/images/sof.jpg",
              name:"Chair",
              Squ:"GDN-Prod#2",
              quantity:"x 1"   
            },
            {
              img:"/assets/images/tv.jpg",
              name:"TV Table",
              Squ:"GDN-Prod#3",
              quantity:"x 3"   
            },
            


        ]
        

    }, 
    {
        order:'1231',
        date:"jun 27,2023",
        customer:"Erica Viktrup",
        channel:"",
        total:"$3,346.43",
        PaymentStatus:"Partially refunded",
        fulfillmentStatus:"fulfilled",
        items:"2 item",
        deliveryStatus:"In Transit",
        deliveryMethod:"Local Delivery",
        itemProd:[
            
            {
              img:"/assets/images/tv.jpg",
              name:"TV Table",
              Squ:"GDN-Prod#3",
              quantity:"x 3"   
            },
            {
              img:"/assets/images/al.jpg",
              name:"Almira",
              Squ:"GDN-Prod#4",
              quantity:"x 1"  
            },


        ]
        

    },

      {
        order:'1230',
        date:"jun 27,2023",
        customer:"Erica Viktrup",
        channel:"",
        total:"$3,346.43",
        PaymentStatus:"	Refunded",
        fulfillmentStatus:"unFulfilled",
        items:"4 item",
        deliveryStatus:"In Transit",
        deliveryMethod:"Local Delivery",
        itemProd:[
            {
              img:"/assets/images/sof1.jpg",
              name:"Table",
              Squ:"GDN-Prod#1",
              quantity:"x 2"  
            },{
              img:"/assets/images/sof.jpg",
              name:"Chair",
              Squ:"GDN-Prod#2",
              quantity:"x 1"   
            },
            {
              img:"/assets/images/tv.jpg",
              name:"TV Table",
              Squ:"GDN-Prod#3",
              quantity:"x 3"   
            },
            {
              img:"/assets/images/al.jpg",
              name:"Almira",
              Squ:"GDN-Prod#4",
              quantity:"x 1"  
            },


        ]
        

    },

    {
        order: "1229",
        date: "Jul 12, 2023",
        customer: "Michael Anderson",
        channel: "Online Store",
        total: "$789.99",
        PaymentStatus: "Refunded",
        fulfillmentStatus: "unFulfilled",
        items: "2 items",
        deliveryStatus: "Out for Delivery",
        deliveryMethod: "White Glove Delivery",
        itemProd:[
         
            {
              img:"/assets/images/tv.jpg",
              name:"TV Table",
              Squ:"GDN-Prod#3",
              quantity:"x 2"   
            },
            {
              img:"/assets/images/al.jpg",
              name:"Almira",
              Squ:"GDN-Prod#4",
              quantity:"x 1"   
            },

        ]
    },
    {
        order: "1228",
        date: "Aug 5, 2023",
        customer: "Sophia Martinez",
        channel: "Point to Sale",
        total: "$1,234.56",
        PaymentStatus: "paid",
        fulfillmentStatus: "fulfilled",
        items: "3 items",
        deliveryStatus: "Delayed",
        deliveryMethod: "Local pickup",

        itemProd:[
            {
              img:"/assets/images/sof1.jpg",
              name:"Table",
              Squ:"GDN-Prod#1",
              quantity:"x 2"   
            },
            {
              img:"/assets/images/tv.jpg",
              name:"TV Table",
              Squ:"GDN-Prod#3",
              quantity:"x 1"   
            },
            {
              img:"/assets/images/al.jpg",
              name:"Almira",
              Squ:"GDN-Prod#4",
              quantity:"x 3"   
            },

        ]
    },
    {
        order: "1227",
        date: "Sep 18, 2023",
        customer: "Daniel White",
        channel: "Point to Sale",
        total: "$567.89",
        PaymentStatus: "Partially refunded",
        fulfillmentStatus: "fulfilled",
        items: "1 item",
        deliveryStatus: "Delivered",
        deliveryMethod: "Custom",
        itemProd:[
            {
              img:"/assets/images/sof1.jpg",
              name:"Table",
              Squ:"GDN-Prod#1",
              quantity:"x 2"   
            }
        ]
    },
    {
        order: "1226",
        date: "Oct 8, 2023",
        customer: "Olivia Johnson",
        channel: "Online Store",
        total: "$2,345.67",
        PaymentStatus: "paid",
        fulfillmentStatus: "unFulfilled",
        items: "4 items",
        deliveryStatus: "Exception",
        deliveryMethod: "Custom",
        itemProd:[
            {
              img:"/assets/images/sof1.jpg",
              name:"Table",
              Squ:"GDN-Prod#1",
              quantity:"x 2"   
            },{
              img:"/assets/images/sof.jpg",
              name:"Chair",
              Squ:"GDN-Prod#2",
              quantity:"x 1"   
            },
            {
              img:"/assets/images/tv.jpg",
              name:"TV Table",
              Squ:"GDN-Prod#3",
              quantity:"x 2"   
            },
            {
              img:"/assets/images/al.jpg",
              name:"Almira",
              Squ:"GDN-Prod#4",
              quantity:"x 4"   
            },

        ]
    },
    {
        order: "1225",
        date: "Nov 20, 2023",
        customer: "David Lee",
        channel: "Online Store",
        total: "$456.78",
        PaymentStatus: "paid",
        fulfillmentStatus: "unFulfilled",
        items: "2 items",
        deliveryStatus: "Held at Depot",
        deliveryMethod: "Local Pickup",
        itemProd:[
            {
              img:"/assets/images/sof1.jpg",
              name:"Table",
              Squ:"GDN-Prod#1",
              quantity:"x 1"   
            },
            {
              img:"/assets/images/al.jpg",
              name:"Almira",
              Squ:"GDN-Prod#4",
              quantity:"x 2"   
            },

        ]
    },
    {
        order: "1224",
        date: "Dec 15, 2023",
        customer: "Emma Adams",
        channel: "Online Store",
        total: "$1,234.56",
        PaymentStatus: "paid",
        fulfillmentStatus: "fulfilled",
        items: "1 item",
        deliveryStatus: "Address Issue",
        deliveryMethod: "Local Delivery",
        itemProd:[
            {
              img:"/assets/images/sof1.jpg",
              name:"Table",
              Squ:"GDN-Prod#1",
              quantity:"x 2"   
            }
        ]
    },
    {
        order: "1223",
        date: "Jan 5, 2023",
        customer:"Liam Miller",
        channel:"Online Store",
        total: "$987.65",
        PaymentStatus: "paid",
        fulfillmentStatus: "unFulfilled",
        items: "3 items",
        deliveryStatus: "Return to Sender",
        deliveryMethod: "Local Delivery",
        itemProd:[
            {
              img:"/assets/images/sof1.jpg",
              name:"Table",
              Squ:"GDN-Prod#1",
              quantity:"x 2"   
            },{
              img:"/assets/images/sof.jpg",
              name:"Chair",
              Squ:"GDN-Prod#2",
              quantity:"x 4"   
            },
            {
              img:"/assets/images/tv.jpg",
              name:"TV Table",
              Squ:"GDN-Prod#3",
              quantity:"x 1"   
            }
           

        ]
    },
    {
        order: "1222",
        date: "Feb 18, 2023",
        customer: "Ava Wilson",
        channel: "Online Store",
        total: "$765.43",
        PaymentStatus: "paid",
        fulfillmentStatus: "fulfilled",
        items: "1 item",
        deliveryStatus: "Incomplete Address",
        deliveryMethod:"Local Delivery",
        itemProd:[
        
            {
              img:"/assets/images/al.jpg",
              name:"Almira",
              Squ:"GDN-Prod#4",
              quantity:"x 2"   
            },

        ]
    },
    {
        order: "1221",
        date: "Mar 10, 2023",
        customer: "Noah Taylor",
        channel: "Online Store",
        total: "$2,345.67",
        PaymentStatus: "paid",
        fulfillmentStatus: "fulfilled",
        items: "4 items",
        deliveryStatus: "Incomplete Address",
        deliveryMethod:"Local Delivery",
        itemProd:[
            {
              img:"/assets/images/sof1.jpg",
              name:"Table",
              Squ:"GDN-Prod#1",
              quantity:"x 2"   
            },{
              img:"/assets/images/sof.jpg",
              name:"Chair",
              Squ:"GDN-Prod#2",
              quantity:"x 1"   
            },
            {
              img:"/assets/images/tv.jpg",
              name:"TV Table",
              Squ:"GDN-Prod#3",
              quantity:"x 2"   
            },
            {
              img:"/assets/images/al.jpg",
              name:"Almira",
              Squ:"GDN-Prod#4",
              quantity:"x 3"   
            },

        ]
    },
    {
        order: "1220",
        date: "Apr 22, 2023",
        customer: "Mia Martinez",
        channel: "Online Store",
        total: "$678.90",
        PaymentStatus: "paid",
        fulfillmentStatus: "unFulfilled",
        items: "2 items",
        deliveryStatus: "",
        deliveryMethod: "Local Delivery",
        itemProd:[
          
            {
              img:"/assets/images/tv.jpg",
              name:"TV Table",
              Squ:"GDN-Prod#3",
              quantity:"x 2"   
            },
            {
              img:"/assets/images/al.jpg",
              name:"Almira",
              Squ:"GDN-Prod#4",
              quantity:"x 1"   
            },

        ]
    },
    {
        order: "1219",
        date: "May 15, 2023",
        customer: "Jackson Brown",
        channel: "Online Store",
        total: "$1,234.56",
        PaymentStatus: "paid",
        fulfillmentStatus: "unFulfilled",
        items: "1 item",
        deliveryStatus: "",
        deliveryMethod: "Local Delivery",
        itemProd:[
            
            {
              img:"/assets/images/al.jpg",
              name:"Almira",
              Squ:"GDN-Prod#4",
              quantity:"x 2"   
            },

        ]
    },
    {
        order: "1218",
        date: "Jun 8, 2023",
        customer: "Sophia Johnson",
        channel: "Online Store",
        total: "$1,234.56",
        PaymentStatus: "paid",
        fulfillmentStatus: "fulfilled",
        items: "3 items",
        deliveryStatus: "",
        deliveryMethod: "Local Delivery",
        itemProd:[
            {
              img:"/assets/images/sof1.jpg",
              name:"Table",
              Squ:"GDN-Prod#1",
              quantity:"x 2"   
            },
            {
              img:"/assets/images/tv.jpg",
              name:"TV Table",
              Squ:"GDN-Prod#3",
              quantity:"x 1"   
            },
            {
              img:"/assets/images/al.jpg",
              name:"Almira",
              Squ:"GDN-Prod#4",
              quantity:"x 2"   
            },

        ]
    },
    {
        order: "1217",
        date: "Jul 1, 2023",
        customer: "Ethan Davis",
        channel: "Online Store",
        total: "$987.65",
        PaymentStatus: "pending",
        fulfillmentStatus: "unFulfilled",
        items: "1 item",
        deliveryStatus: "",
        deliveryMethod: "Local Delivery",
        itemProd:[
           
            {
              img:"/assets/images/tv.jpg",
              name:"TV Table",
              Squ:"GDN-Prod#3",
              quantity:"x 1"   
            },
            

        ]
    },
    {
        order: "1216",
        date: "Aug 14, 2023",
        customer: "Isabella Smith",
        channel: "Online Store",
        total: "$1,234.56",
        PaymentStatus: "paid",
        fulfillmentStatus: "fulfilled",
        items: "2 items",
        deliveryStatus: "",
        deliveryMethod: "Pickup",
        itemProd:[
            {
              img:"/assets/images/sof1.jpg",
              name:"Table",
              Squ:"GDN-Prod#1",
              quantity:"x 2"   
            },{
              img:"/assets/images/sof.jpg",
              name:"Chair",
              Squ:"GDN-Prod#2",
              quantity:"x 1"   
            }
           

        ]
    },
    {
        order: "1215",
        date: "Sep 9, 2023",
        customer: "William Johnson",
        channel: "Online Store",
        total: "$567.89",
        PaymentStatus: "paid",
        fulfillmentStatus: "unFulfilled",
        items: "4 items",
        deliveryStatus: "",
        deliveryMethod: "Standard",
        itemProd:[
            {
              img:"/assets/images/sof1.jpg",
              name:"Table",
              Squ:"GDN-Prod#1",
              quantity:"x 2" 

            },{
              img:"/assets/images/sof.jpg",
              name:"Chair",
              Squ:"GDN-Prod#2",
              quantity:"x 1"
                
            },
            {
              img:"/assets/images/tv.jpg",
              name:"TV Table",
              Squ:"GDN-Prod#3",
              quantity:"x 3"  
            },
            {
              img:"/assets/images/al.jpg",
              name:"Almira",
              Squ:"GDN-Prod#4",
              quantity:"x 4"  
            },

        ]
    }
  
  ];
  
  export default orders;