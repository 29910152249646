import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import "./Variations.css";
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Dialog, DialogTitle, DialogContent, Button } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ClearIcon from "@mui/icons-material/Clear";
const { v4: uuidv4 } = require('uuid');
const filter = createFilterOptions();

function Variations({ controlForm, Open, setisOpen,quantity }) {
  console.log("finalVariant",quantity);

  const [value, setValueData] = React.useState(null);
  const { control, reset } = useForm();
  const { handleSubmit } = useForm(); // Add handleSubmit here

  const [friends, setFriends] = useState([]);

  const [data, setData] = useState([]);
  console.log("data", data);
  const [convertData, setConvertData] = useState([]);
  console.log("convertData", convertData);
  const [editIndex, seteditIndex] = useState(-1);
  const [clickIndex, setclickIndex] = useState(-1);
  const [isEdit, setIsEdit] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [isEditInventoryName, setIsEditInventoryName] = useState();

  const [addShopLocation, setAddShopLocation] = useState(false);



  const HendelSaveThisPage = () => {
    setAddShopLocation(false);
    setIsSave(true);
    setValue(null);
  

  
    const updatedOptionValues = convertData.optionValues.map((option, allIndex) => {
      if (allIndex === clickIndex && selectedOption) {
        const inventoryName = selectedOption;
  
        return {
          ...option,
          inventoryName: inventoryName,
        };
      } else {
        // If the condition is not met, return the original object as it is
        return option;
      }
    });
    console.log("updatedOptionValues",updatedOptionValues);
  
    // Update the convertData with the new optionValues array
    setConvertData((prevData) => ({
      ...prevData,
      optionValues: updatedOptionValues,
    }));
  };
  
  const HendelShopLocationDialog = (index) => {
    setAddShopLocation(true);
    setclickIndex(index);
    setValue(convertData.optionValues[index].inventoryName)



   
     
 
  };
  const handleCloseShopLocationDialog = () => {
    // Close the edit dialog without saving
    setAddShopLocation(false);
  };
  

  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionChange = (event, value) => {
    setSelectedOption(value ? value.name : null);
  };
   const editInventoryName =(inventoryName, index)=>{
    setAddShopLocation(true);

    setValue(inventoryName);
    
   }
   
  //  console.log("value ? value.name ",value?.name );

  // const onSubmit = (index) => (formData) => {
  //   if(editIndex !==-1){
  //     const tempData=[...data]
  //     tempData[index] = friends[index];
  //        setFriends([{ firstName: "", optionValues: [""], value:null }]);
  //   reset();
  //     seteditIndex(-1);
  //   }
  //   else{
  //     const newData = [...data, friends];
  //     setData(newData);
  //     setFriends(newData);
  //       //  setFriends([]);
  //   reset();

  //   }

  //   //
  //   controlForm(false);
  // };

  // const onSubmit = (index) => {
  //   // Save the variations data when the form is submitted
  //   if(editIndex !== -1){
  //         const tempData=[...data]
  //         tempData[index] = friends[index];

  //         // console.log("tempData",tempData);
  //         setData(tempData);

  //            setFriends([]);
  //       reset();
  //         seteditIndex(-1);
  //       }
  //       else{
  //         const newData = [...data, ...friends];
  //         console.log("newData",newData);
  //         setData(newData);
  //         setFriends([]);

  //         reset();
  //       }

  //   controlForm(false);
  // };

  // const mergeFormattedData = (formattedData) => {
  //   const mergedData = {
  //     firstName: formattedData.map((item) => item.firstName).join(', '),
  //     optionValues: [],
  //     value: formattedData.reduce(
  //       (acc, item) => {
  //         acc.title += ` "${item.firstName}"`;
  //         return acc;
  //       },
  //       { title: "" }
  //     ),
  //   };

  //   // for (const item of formattedData) {
  //   //   for (const option of item.optionValues) {
  //   //     console.log("option",option);

  //   //       mergedData.optionValues.push(`${item.firstName} ${option}`);

  //   //   }
  //   // }

  //   let variantOptions = [];
  //   let currentIndex = -1;

  //   for(const [index, data] of formattedData.entries()) {
  //     currentIndex = index;
  //     for(const [i, option] of data.optionValues.entries()) {
  //       if(currentIndex === 0) {
  //         variantOptions.push(option)
  //       }
  //       console.log({index, i, data, option, variantOptions})
  //     }
  //   }

  //   return mergedData;
  // };

  function generateCombinations(
    friends,
    currentIndex = 0,
    currentCombination = [],
    allCombinations = []
  ) {
    if (currentIndex === friends.length) {
      const Sku=generateSKU(currentCombination);
      const values = currentCombination.map(optionValue => optionValue.replace(/(\w)([A-Z])/g, '$1 $2')).join(" "); // Add space between color and size values
      // const values = currentCombination.join( "")
      allCombinations.push({
        Sku,
        values,
      })
      
      return;
    }

    const currentFriend = friends[currentIndex];
    for (const optionValue of currentFriend.optionValues) {
      const newCombination = [...currentCombination, optionValue];
      generateCombinations(
        friends,
        currentIndex + 1,
        newCombination,
        allCombinations
      );
    }
  }


  function generateSKU(combination) {
    // Generate a UUIDv4 for each combination to create a unique SKU
    const uuid = uuidv4();
    const Sku ="sku -"+uuid.slice(0,6)
    return Sku
  }



  const mergeFormattedData = (formattedData) => {
    const mergedData = {
      firstName: formattedData.map((item) => item.firstName).join(", "),
      optionValues: [],
      value: formattedData.reduce(
        (acc, item) => {
          acc.title += ` "${item.firstName}"`;
          return acc;
        },
        { title: "" }
      ),
    };

    const allCombinations = [];
    generateCombinations(formattedData, 0, [], allCombinations);

    mergedData.optionValues = allCombinations;

    return mergedData;

  
  };

  // Assuming you have formattedData and friends arrays
  // const mergedData = mergeFormattedData(formattedData);
  // console.log(mergedData);

  const onSubmit = (index) => {
    // Save the variations data when the form is submitted
    if (isEdit) {
      const newData = [...friends];
      const formattedData = newData.map((friend) => ({
        firstName: friend.firstName,
        optionValues: friend.optionValues.map((option) => option),
        value: friend.value,
      }));

      setData(formattedData);
      setConvertData(mergeFormattedData(formattedData));
      // setData(mergeFormattedData(formattedData))

      setFriends([]);
      reset();
      setIsEdit(false);
    } else {
      const newData = [...data, ...friends];
      console.log("formattedData",newData);
      const formattedData = newData.map((friend) => ({
        firstName: friend.firstName,
        optionValues: friend.optionValues.map((option) => option),
        value: friend.value,
      }));

      setData(formattedData);
      setConvertData(mergeFormattedData(formattedData));
      
      // setData(mergeFormattedData(formattedData))

      setFriends([]);
      reset();
    }

    controlForm(false);
  };

  const addFriend = () => {
    const newFriend = {
      firstName: "",
      optionValues: [""], // Start with one empty option value
    };
    setFriends((prevFriends) => [...prevFriends, newFriend]);
    controlForm(true);
  };

  const removeFriend = (index) => {
    if (editIndex !== -1) {
      const tempDatas = (prevFriends) =>
        prevFriends.filter((item, i) => i !== editIndex);
      setFriends(tempDatas);
      setData(tempDatas);
      seteditIndex(-1);
      // controlForm(false);
    } else {
      // const tempDatas=(prevFriends) => prevFriends.filter((_, i) => i !== index)
      const updatedVarient = [...friends];

      updatedVarient.splice(index, 1);

      setFriends(updatedVarient);
    }
    if (friends?.length === 1) {
      setFriends([]);
      reset();
      controlForm(false);
    }

    // setFriends([]);
    // reset();
  };
  const handleFormEdit = () => {
    // seteditIndex(index)

    // const FromEditIndex=index;

    // const EditProd=data.filter((item,index)=>index === FromEditIndex);

    // controlForm(true);
    // setFriends(EditProd)
    setisOpen(true);
    setFriends(data);
    setIsEdit(true);
  };

  const handleRemoveOptionValue = (index, fieldIndex) => {
    const updatedFriends = [...friends];
    updatedFriends[index].optionValues.splice(fieldIndex, 1);
    setFriends(updatedFriends);
  };
  const handleAdditionalFieldChange = (
    index,
    fieldIndex,
    value,
    remove = false
  ) => {
    const updatedFriends = [...friends];

    updatedFriends[index].optionValues[fieldIndex] = value;

    // Add a new option value if the last field has a value
    if (
      fieldIndex === updatedFriends[index].optionValues.length - 1 &&
      value !== ""
    ) {
      updatedFriends[index].optionValues.push("");
    }

    setFriends(updatedFriends);
  };

  const handleFirstNameChange = (index, value) => {
    const updatedFriends = [...friends];
    updatedFriends[index].firstName = value?.title; // Save the selected value in the firstName property
    setFriends(updatedFriends);
  };

  const top100Films = [
    { title: "The Shawshank Redemption", year: 1994 },
    { title: "The Godfather", year: 1972 },
    { title: "The Godfather: Part II", year: 1974 },
    { title: "The Dark Knight", year: 2008 },
  ];
  const ShopName = [
    { name: "Jamuna shop" },
    { name: "Uttora shop" },
    { name: "Gulshan Shop"  },
    { name: "Khilgha Shop" },
  ];
  const [values, setValue] = React.useState(null);
  return (
    <>
      {/* <form   style={{marginTop:"20px"}} onSubmit={handleSubmit(onSubmit)}  > */}
      <form style={{ marginTop: "20px" }}>
        {Open
          ? friends.map((friend, index) => (
              <>
                <fieldset
                  key={index}
                  style={{ padding: "20px 10px", marginBottom: "20px" }}
                >
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "87% 10%",
                      alignItems: "center",
                      columnGap: "20px",
                    }}
                  >
                    <Controller
                      // name={`${friends[index].firstName}`}
                      name={`ggyh`}
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          value={friends[index].value}
                          onChange={(event, newValue) => {
                            if (typeof newValue === "string") {
                              setValueData({
                                title: newValue,
                              });
                              const tempValue = [...friends];
                              tempValue[index].value = { title: newValue };

                              setFriends(tempValue);
                              handleFirstNameChange(index, { title: newValue }); // Save the selected value in firstName
                            } else if (newValue && newValue.inputValue) {
                              setValueData({
                                title: newValue.inputValue,
                              });
                              const tempValue = [...friends];
                              tempValue[index].value = {
                                title: newValue.inputValue,
                              };

                              setFriends(tempValue);
                              handleFirstNameChange(index, {
                                title: newValue.inputValue,
                              }); // Save the selected value in firstName
                            } else {
                              setValueData(newValue);
                              const tempValue = [...friends];
                              tempValue[index].value = newValue;

                              setFriends(tempValue);
                              handleFirstNameChange(index, newValue); // Save the selected value in firstName
                            }
                            field.onChange(newValue?.title); // Set the value in the `field` provided by the Controller
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some(
                              (option) => inputValue === option?.title
                            );
                            if (inputValue !== "" && !isExisting) {
                              filtered.push({
                                inputValue,
                                title: `Add "${inputValue}"`,
                              });
                            }

                            return filtered;
                          }}
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          id="Option Name"
                          options={top100Films}
                          getOptionLabel={(option) => {
                            if (typeof option === "string") {
                              return option;
                            }
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            return option?.title;
                          }}
                          renderOption={(props, option) => (
                            <li {...props}>{option?.title}</li>
                          )}
                          freeSolo
                          // Pass the TextField component directly to renderInput
                          renderInput={(params) => (
                            <TextField {...params} label="Option Name" />
                          )}
                        />
                      )}
                    />
                    <button
                      style={{ background: "none", border: "none",cursor:'pointer' }}
                      type="button"
                      onClick={() => removeFriend(index)}
                    >
                      <DeleteIcon />
                    </button>
                  </div>

                  {friend.optionValues.map((optionValue, fieldIndex) => (
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "87% 10%",
                        columnGap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          margin: "20px 0px",
                        }}
                        key={fieldIndex}
                      >
                        <label style={{ paddingBottom: "10px" }}>
                          Option Value {fieldIndex + 1}:
                        </label>
                        <Controller
                          name={`friends[${index}].optionValues[${fieldIndex}]`}
                          control={control}
                          defaultValue={optionValue}
                          render={({ field }) => (
                            <input
                              style={{ padding: "18px", borderRadius: "10px" }}
                              type="text"
                              value={optionValue}
                              onChange={(e) => {
                                field.onChange(e.target.value); // Set the value in the `field` provided by the Controller
                                handleAdditionalFieldChange(
                                  index,
                                  fieldIndex,
                                  e.target.value
                                );
                              }}
                            />
                          )}
                        />
                      </div>
                      {fieldIndex > 0 && (
                        <button
                          type="button"
                          style={{
                            background: "none",
                            border: "none",
                            marginLeft: "10px",
                            cursor: "pointer",
                          }}
                          //  onClick={() => handleAdditionalFieldChange(index, fieldIndex, "", true)}
                          onClick={() =>
                            handleRemoveOptionValue(index, fieldIndex)
                          }
                        >
                          <DeleteIcon />
                        </button>
                      )}
                    </div>
                  ))}

                  {/* <button className="save" onClick={() => handleFormSave(index)}>Save</button> */}
                </fieldset>
              </>
            ))
          : ""}
        {Open ? (
          <button className="save" type="button" onClick={() => onSubmit()}>
            {isEdit?"Update":"Save"}
           
          
          </button>
        ) : (
          ""
        )}
      </form>

      <p
        onClick={addFriend}
        style={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          columnGap: "10px",
          color: "white",
          backgroundColor:"#1976D2",
          // border:'1px solid black',
          cursor:'pointer',
          width:'110px',
          height:'30px',
          borderRadius:"5px"
        }}
      >
        <AddIcon /> Add more
      </p>
      {convertData.length !== 0 && (
        <div
          style={{ margin: "20px 0px", border: "1px solid rgb(137, 143, 148)" }}
        >
          <div
            style={{
              display:"flex",
              justifyContent:"space-between",
              alignItems:'center',
              margin: "0px",
              padding: "15px 13px",
              borderBottom:'1px solid black'
            }}
          >
           <p style={{
              fontSize: "18px",
              fontWeight: "700",
            
              margin: "0px",
           
            }}>Custom Variant</p> 
            <span style={{fontSize:'14px',fontWeight:"400"}}>In stock : <span style={{fontSize:'17px',fontWeight:"700"}}>{quantity.quantity === undefined ?"N/A" :quantity.quantity === ''? "N/A" : quantity.quantity}</span></span>


          </div>

          <div
            style={{
              borderBottom:
                convertData.length > 1
                  ? "1px solid rgb(137, 143, 148)"
                  : "none",
            }}
          >
            <div style={{ padding: "0px 13px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p style={{ fontSize: "16px", fontWeight: "500" }}>
                  Variant : <span style={{fontSize:'17px',fontWeight:"700"}}>{convertData?.firstName}</span>
                </p>
                {/* onClick={() => handleFormEdit(index)} */}
                <button
                  style={{
                    marginRight: "20px",
                    height: "30px",
                    width: "99px",
                    border:'none',
                    fontSize:'14px',
                    fontWeight:'400',
                    // backgroundColor: "white",
                    borderRadius: "5px",
                    cursor:'pointer'
                  }}
                  type="button"
                  onClick={() => handleFormEdit()}
                >
                  Edit
                </button>
              </div>
              {convertData?.optionValues?.map((item, optionIndex) => (
                <div className="optionQuantity"
                 
                ><span>
                   <p
                    style={{ fontSize: "14px", fontWeight: "400" }}
                    key={optionIndex}
                  >
                    Option :<span style={{fontSize:'17px',fontWeight:"700"}}>{item.values}</span> 
                  </p>
                  <p
                    style={{ fontSize: "14px", fontWeight: "400" }}
                    key={optionIndex}
                  >
                    Sku :<span style={{fontSize:'17px',fontWeight:"700"}}>{item.Sku}</span> 
                  </p>
                </span>
                 
                  <span style={{ display: "flex", flexDirection: "column" }}>
                    <label style={{ fontSize: "14px", fontWeight: "400" }}>Price : </label>
                    <input
                      type="number"
                      style={{
                        height: "30px",
                        width: "90px",
                        paddingLeft: "6px",
                        borderRadius: "5px",
                      }}
                      placeholder="0.00"
                    ></input>
                  </span>
                  <span style={{ display: "flex", flexDirection: "column" }}>
                    <label style={{ fontSize: "14px", fontWeight: "400" }}>Quantity : </label>
                    <input
                      type="number"
                      style={{
                        height: "30px",
                        width: "90px",
                        paddingLeft: "6px",
                        borderRadius: "5px",
                      }}
                      placeholder="0"
                    ></input>
                  </span>
                  {/* {isSave ? ( */}
                  {item.inventoryName ? (
                     <Tooltip title="Edit inventory name" placement="top-start">
                    <div style={{ display: "flex", flexDirection: "column" }} 
                    onClick={()=>HendelShopLocationDialog(optionIndex)}>
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          paddingBottom: "6px",
                        }}
                      >
                        {" "}
                        Inventory name:
                      </span>{" "}
                      <span style={{ fontSize: "17px", fontWeight: "700",lineHeight:'17px'}}>
                        {" "}
                        {item.inventoryName}
                      </span>{" "}
                    </div>
                    </Tooltip>
                  ) : (
                    <button className="manageInventory"
                      onClick={()=>HendelShopLocationDialog(optionIndex)}
                   
                    >
                      Manage Inventory
                    </button>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {/* addShopLocation */}
      <Dialog open={addShopLocation} onClose={handleCloseShopLocationDialog}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "90% 10%",
            columnGap: "10px",
            alignItems: "center",
            borderBottom: "1px solid #898F94",
          }}
        >
          <DialogTitle
            style={{
              width: "500px",
              fontSize: "17px",
              fontWeight: "700",
            }}
          >
            Select Inventory
          </DialogTitle>

          <ClearIcon onClick={handleCloseShopLocationDialog} />
        </div>

        <DialogContent
          style={{
            fontSize: "14px",
            fontWeight: "400",
            marginBottom: "10px",
            borderBottom: "1px solid #898F94",
          }}
        >
          {" "}
          {/* <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={ShopName}
            getOptionLabel={(option) => option.name} // Specify how the options should be displayed
            renderInput={(params) => (
              <TextField {...params} label="The Dark Knight" />
            )}
            onChange={handleOptionChange}
          /> */}



<Autocomplete
      value={values}
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          setValue({
            name: newValue,
          });
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          setValue({
            name: newValue.inputValue,
          });
        } else {
          setValue(newValue);
        }
        handleOptionChange(event,newValue)
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.title);
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            name: `Add "${inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="free-solo-with-text-demo"
      options={ShopName}
      
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.name;
      }}
      
      renderOption={(props, option) => <li {...props}>{option.name}</li>}
    
      freeSolo
      renderInput={(params) => (
        <TextField {...params} label="Free solo with text demo" />
      )}
    
    
    />
        </DialogContent>

        <form
          style={{
            display: "flex",
            justifyContent: "end",
            gap: "20px",
            paddingBottom: "15px",
            paddingRight: "20px",
          }}
        >
          <Button
            style={{
              width: "30px",
              height: "30px",
              fontSize: "14px",
              color: "white",
              borderRadius: "5px",
              border: "none",
              backgroundColor: "red",
              textTransform: "none",
            }}
            variant="contained"
            onClick={handleCloseShopLocationDialog}
          >
            Cancel
          </Button>

          <Button
            style={{
              width: "99px",
              fontSize: "14px",
              height: "30px",
              color: "white",
              backgroundColor: "#4D9A19",
              border: "none",
              borderRadius: "5px",
              textTransform: "none",
            }}
            onClick={HendelSaveThisPage}
          >
            Save
          </Button>
        </form>
      </Dialog>
    </>
  );
}

export default Variations;
